<template>
  <div class="v-form-input-inline vfii" @click="setFocus" :class="mainClass" ref="refWrap">
    <div class="vfii__placeholder">
      {{placeholder}} <span class="vfii__placeholder-required" v-if="required">*</span>
    </div>
    <div class="vfii__input">
      <v-icon-svg-wrapped v-if="iconValue?.name" v-bind="iconValue"/>
      <input type="text" v-model="model" ref="refInput" :disabled="disabledInput">
    </div>
    <div class="vfii__loading-wrap" v-if="loading">
      <div class="vfii__loading-inner">
        <v-loader-ship color-line-one="#1C7EE080" color-line-two="#1C7EE0" class="vfii__loading"/>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, defineProps, onMounted, ref, watch} from 'vue';
import VLoaderShip from "@app-vue/components/Base/VLoaderShip.vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";

const props = defineProps({
  placeholder: {},
  required: {type:Boolean, default:false},
  isError: {type:Boolean,default:false,},
  customManager: {type:Boolean,default:false},
  disabled: {type: Boolean, default:false,},
  loading: {type:Boolean,default:false},
  lockChange: {type:Boolean, default:false,},
  iconValue: {default:null,},
  value: {default: null,},
});

const refWrap = ref();
const refInput = ref();

const model = defineModel();
watch(() => props.value, (value) => {
  if(value !== null){
    model.value = value;
  }
},{immediate:true});

const disabledInput = computed(() => {
  return props.disabled || props.loading || props.lockChange;
});

const mainClass = computed(() => {
  let classes = [];
  if(isOpen.value) classes.push('vfii--is-open');
  if(props.isError) classes.push('vfii--is-error');
  if(props.disabled) classes.push('vfii--disabled');
  if(props.loading) classes.push('vfii--loading');
  if(props.lockChange) classes.push('vfii--lock-change');
  return classes;
});

const isFocus = ref(false);
const isOpen = ref(false);

watch(() => isFocus.value || model.value?.length > 0, (value, oldValue, onCleanup) => {
  isOpen.value = value;
},{immediate:true})


onMounted(() => {
  if(!props.customManager){
    refInput.value.addEventListener('focus', () => {
      if(disabledInput.value){return;}
      isFocus.value = true;
    });
    refInput.value.addEventListener('blur', () => {setTimeout(() => {isFocus.value = false;},60)});
  }
});

const setFocus = () => {
  if(disabledInput.value){return;}
  if(!props.customManager){
    if(!isFocus.value){
      refInput.value.focus();
    }
  }
}

defineExpose({isFocus,isOpen});

</script>

<style scoped lang="scss">
.v-form-input-inline.vfii{

  width:100%;
  height:48px;
  border: 1px solid #D6E3EF;
  border-radius:4px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  padding:6px 10px;
  cursor: text;

  .vfii__placeholder{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #AEB7C7;
    transition-duration: 0.2s;
    transform:scale(1);
    transform-origin: left center;
    display:flex;
    gap:2px;
    .vfii__placeholder-required{
      color: #F2001D;
    }
  }

  .vfii__input{
    @include flex(row,start,center,4px);
    height:0px;
    transition-duration: 0.2s;
    input{
      border:none;
      width:100%;
      color: #384967;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding:0px;
      height:0px;
      transition-duration: 0.2s;
      background:initial;
      &[disabled]{
        -webkit-text-fill-color:#384967;
        opacity:1;
      }
      &:focus{
        outline:0px;
      }
    }
  }

  &.vfii--is-open{
    .vfii__placeholder{
      transform-origin: left center;
      transform:scale(0.85);
    }
    .vfii__input{
      height:18px;
      input{
        height:18px;
      }
    }
  }

  &.vfii--is-error{
    border-color:#F2001D;
  }

  &.vfii--disabled{
    border: 1px solid #D6E3EF;
    background:#F9FAFA;
    > * {opacity:0.5}
    .vfii__loading-inner{
      background-color: #F9FAFA;
    }
  }

  &.vfii--disabled,&.vfii--lock-change,.vfii--loading{
    position:relative;
    cursor:initial;
    &:after{
      content:'';
      position:absolute;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
      display:block;
      z-index:99;
    }
  }

  &.vfii--loading{
    position: relative;
    .vfii__loading-wrap{
      position: absolute;
      width:100%;
      height:100%;
      opacity:1;
      top:0px;
      left:0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .vfii__loading-inner{
        height:100%;
        width:44px;
        padding:6px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .vfii__loading{

        }
      }
    }
  }

}
</style>