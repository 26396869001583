import _, {isString} from 'lodash';
import {toString} from "lodash";

export function generateUUID() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function priceFormatted(price){
    return parseFloat(price).toFixed(2);
}

export function pluralize(word, count, ending = 's'){
    let c = Number(count);
    if (isNaN(c)) return word;
    c = Math.abs(c);
    /**
     * s не пишем, если 1 или заканчивается на 1
     * кроме случая, когда заканчивается на 11
     */
    if((c === 1 || c % 10 === 1) && c % 100 !== 11){
        return word;
    }else{
        return word + ending;
    }
}

export function delay(ms){
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function convertSecondsToTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {hours, minutes, seconds };
}

/**
 * @param {Date} date
 * @return {string}
 */
export function formatDate(date){
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

export function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function formatDateDDMMYYYY(date){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}.${month}.${year}`;
}

export function getObjectDateFromDDMMYYY(dateString){
    const dateArray = dateString.split('.');
    return new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
}

export function initStore(context, data, customKeys = {}){
    context.$patch((state) => {
        Object.entries(data).forEach(([key,value]) => {
            if(customKeys.hasOwnProperty(key)){
                customKeys[key](state, value);
            }else if(state.hasOwnProperty(key)){
                state[key] = value;
            }
        });
    });
}

/**
 * @param {Number} number
 * @param maxDigits
 * @return {string}
 */
export function shortNumber(number, maxDigits = 6){
    let isNegative = number < 0;
    let response;
    number = Math.abs(number);
    if (number >= 1_000_000 && maxDigits >= 6) {
        response = (number / 1_000_000).toFixed(0) + 'M'; // 1,000,000 -> 1.0M
    } else if (number >= 1_000 && maxDigits >= 3) {
        response = (number / 1_000).toFixed(0) + 'K'; // 1,000 -> 1.0K
    } else {
        if(typeof number === 'number' && !isNaN(number)){
            response = number.toString();
        }else{
            response = number;
        }
    }
    return isNegative ? '-' + response : response;
}

export function abbrNumberFormat(value, precision = 1, useFloor = false){
    if (isString(value) || isNaN(value)) {
        if (typeof value.includes === 'function' && (value.includes('K') || value.includes('M') || value.includes('B'))) {
            return value;
        }
    }

    const map = [
        // {suffix: 'T', threshold: 1e12},
        // {suffix: 'B', threshold: 1e9},
        {suffix: 'M', threshold: 1e6},
        {suffix: 'K', threshold: 1e3},
        {suffix: '', threshold: 1},
    ];

    const found = map.find((x) => Math.abs(value) >= x.threshold);
    if (found) {
        let floatNumber = value / found.threshold;
        let formatted = (Math.floor( floatNumber * 10) / 10) + found.suffix;

        if (useFloor) {
            formatted = (floatNumber).toFixed(precision) + found.suffix;
        }

        if (typeof formatted.replaceAll === 'function') {
            formatted = formatted.replaceAll('.0', '');
        }

        return formatted;
    }

    return parseInt(value).toFixed(precision).replaceAll('.0', '');
}

export function getScssVar(name){
    return getComputedStyle(document.documentElement).getPropertyValue(`${name}`).trim();
}

export function isObject(variable){
    return variable && typeof variable === 'object' && variable.constructor === Object;
}

/**
 *
 * @param {Function} func
 * @param {Array} params
 * @return {Promise<*>}
 */
export async function processFunction(func, params) {
    let result = func(...params);
    return result instanceof Promise ? await result : result;
}

export function isValidDate(date){
    return date instanceof Date && !isNaN(date);
}

export function parseDateString(dateString) {
    const dateParts = dateString.split(/[-/.]/); // Разделители могут быть "-", ".", "/"

    let day, month, year;

    if (dateParts.length === 2) {
        // Формат дд-мм или дд/мм
        [day, month] = dateParts;
        year = new Date().getFullYear(); // Используем текущий год, если он не указан
    } else if (dateParts.length === 3) {
        if (dateParts[0].length === 4) {
            // Формат ГГГГ-мм-дд
            [year, month, day] = dateParts;
        } else {
            // Формат дд-мм-ГГГГ
            [day, month, year] = dateParts;
        }
    } else {
        return new Date("Invalid Date"); // Возвращаем невалидную дату, если формат неизвестен
    }

    // Преобразуем день и месяц к двухзначному формату (например, "1" в "01")
    day = day.padStart(2, '0');
    month = month.padStart(2, '0');

    return new Date(`${year}-${month}-${day}`);
}

export function getCurrentUrlParam(param){
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get(param);
}

export function showProject(isActive,isDeleted,showInactive,showDeleted){
    return (!isDeleted && (isActive || showInactive))
        || (isDeleted && showDeleted);
}

export function getDomainFromUrl(url){
    const domainPattern = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;

    const match = url.match(domainPattern);
    if (match && match[1]) {
        return match[1];
    }else{
        return null;
    }
}

export function groupUrlsByDomain(urls){
    let domains = {}
    urls.forEach((url) => {
        let domain = getDomainFromUrl(url) ?? url;
        if(!domains.hasOwnProperty(domain)){
            domains[domain] = [url];
        }else{
            if(!domains[domain].includes(url)){
                domains[domain].push(url);
            }
        }
    });
    return domains;
}

export function smoothScrollTo(element, { duration = 500, offset = 0 } = {}) {
    const start = window.pageYOffset; // Начальная позиция прокрутки
    const startTime = performance.now();

    setTimeout(() => {
        const target = element.getBoundingClientRect().top + start + offset; // Целевая позиция (с учетом текущей прокрутки)

        function scroll() {
            const currentTime = performance.now();
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1); // Ограничиваем прогресс значением от 0 до 1

            // Функция плавности (можно изменить на другую для других типов анимации)
            const easeInOutQuad = progress < 0.5
                ? 2 * progress * progress
                : -1 + (4 - 2 * progress) * progress;

            // Рассчитываем текущую позицию прокрутки
            const currentScroll = start + (target - start) * easeInOutQuad;
            window.scrollTo(0, currentScroll);

            // Продолжаем анимацию, пока не достигнем конца
            if (timeElapsed < duration) {
                requestAnimationFrame(scroll);
            }
        }

        requestAnimationFrame(scroll);
    });
}

export function highlightMatch(str, q){
    q = typeof q === 'string' ? q.trim() : q;
    const input = q.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${input})`, 'gi');
    return str.replace(regex, '<b>$1</b>');
}

export function adjustObjectToSum(object, sum){
    let len = Object.keys(object).length
    let baseValue = Math.floor(sum / len);
    let remainder = sum % len;
    let responseObject = {};
    let keys = Object.keys(object).sort((a,b) => a < b ? 1 : -1);
    keys.slice(0,remainder).forEach((key) => {
        responseObject[key] = baseValue+1;
    });
    keys.slice(remainder,len).forEach((key) => {
        responseObject[key] = baseValue;
    });
    return responseObject;
}

export function sumObject(object){
    return Object.values(object).reduce((s,i) => s+i, 0);
}

export function openAuthPopup(){
    let authPopupWrap = document?.body?.querySelector('.auth-popup-wrap');
    if(authPopupWrap){
        authPopupWrap.classList.add('show');
    }
}

export function formatSup(number){
    let splitNum = number.toString().split('.');
    return splitNum[1] ? '.' + splitNum[1].substr(0,1) : '';
}

export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getRandomString(length) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return Array.from({ length }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
}

export function compareCountries(countryCodeA, countryCodeB){
    let countryCodeAFormatted = !!countryCodeA && countryCodeA!=='no_geo' ? countryCodeA : null;
    let countryCodeBFormatted = !!countryCodeB && countryCodeB!=='no_geo' ? countryCodeB : null;

    if(!!countryCodeAFormatted ^ !!countryCodeBFormatted) return false;
    if(countryCodeAFormatted && countryCodeAFormatted?.toLowerCase() !== countryCodeBFormatted?.toLowerCase()) return false;

    return true;
}

export function compareStates(stateCodeA, stateCodeB){
    let stateCodeAFormatted = !!stateCodeA ? stateCodeA : null;
    let stateCodeBFormatted = !!stateCodeB ? stateCodeB : null;

    if(!!stateCodeAFormatted ^ !!stateCodeBFormatted) return false;
    if(stateCodeAFormatted && stateCodeAFormatted?.toLowerCase() !== stateCodeBFormatted?.toLowerCase()) return false;

    return true;
}

export function compareCountriesAndStates(countryCodeA, stateCodeA, countryCodeB, stateCodeB){

    return compareCountries(countryCodeA, countryCodeB) && compareStates(stateCodeA, stateCodeB);

}

export function compareString(stringA, stringB, userOptions = {}){

    let defaultOptions = {
        caseDependence: false,
        trimming: true,
    }

    let options = Object.assign(defaultOptions,userOptions);

    let localStringA, localStringB;

    if(options.caseDependence){
        localStringA = toString(stringA);
        localStringB = toString(stringB);
    }else{
        localStringA = toString(stringA).toLowerCase();
        localStringB = toString(stringB).toLowerCase();
    }

    if(options.trimming){
        localStringA = localStringA.trim();
        localStringB = localStringB.trim();
    }

    return localStringA === localStringB;


}

export function simpleArrayHash(arr) {
    const str = JSON.stringify(arr);
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0;
    }
    return Math.abs(hash).toString(36);
}


/**
 * @param {Number} number
 * @return {string}
 */
export function formatNumberToString(number){
    let txt = parseInt(number).toLocaleString('en-US').toString();

    return _.replace(txt, /,/g, ' ');
}