<template>
  <v-wic-tooltip-container class="v-wic-tooltip-backlink">
    <v-wic-tooltip-head class="modal-title">
      What is <b>1 backlink</b> ?
    </v-wic-tooltip-head>
    <v-wic-tooltip-arrow/>
    <v-tooltip-overlay v-if="false"/>
    <div class="info">
      <v-form-select-backlink-type v-model="backlinkTypeId" show-selected-credits/>
      <div class="info-text">
        {{useBacklinkOptions().getById(backlinkTypeId)?.description}}
      </div>
    </div>
  </v-wic-tooltip-container>
</template>

<script setup>

import VWicTooltipHead from "@app-vue/components/Base/WhatIsCredit/VWicTooltipHead.vue";
import VWicTooltipContainer from "@app-vue/components/Base/WhatIsCredit/VWicTooltipContainer.vue";
import VWicTooltipArrow from "@app-vue/components/Base/WhatIsCredit/VWicTooltipArrow.vue";
import VTooltipOverlay from "@app-vue/components/Base/VTooltipOverlay.vue";
import VFormSelectBacklinkType from "@app-vue/components/VForm/VFormSelectBacklinkType.vue";
import {ref} from "vue";
import {useBacklinkOptions} from "@app-vue/stores/useStaticData";

const backlinkTypeId = ref();

</script>

<style lang="scss" scoped>
.v-wic-tooltip-backlink{
  width:325px;

  .modal-title{
    font-size:15px;
    font-weight: 400;
    b{
      font-weight: 700;
    }
  }

  .info{
    @include flex(column,start,stretch,20px);
    padding:20px;
    .info-text{
      font:$font-13-regu;
      color:$dark-80;
    }
  }

}
</style>