<template>
  <div class="v-bh-info-payment">
    <v-link v-if="group.payment.dataPayment.orderId" class="info-payment-id" :text="group.payment.dataPayment.orderId" only-style/>
    <span v-if="group.payment.dataPayment.type" v-text="group.payment.dataPayment.type"/>
    <span v-text="'$'+group.payment.dataPayment.amount"/>
    <v-link v-if="group.payment.linkInvoiceCorrect" :href="group.payment.linkInvoiceCorrect" text="Invoice" target="_blank"/>
  </div>
</template>

<script setup>

import VLink from "@app-vue/components/Base/VLink.vue";
import {useBillingHistoryGroup} from "@app-vue/stores/useBillingHistory";

const props = defineProps({
  groupUniquekey:{},
});

let group = useBillingHistoryGroup(props.groupUniquekey);

</script>

<style lang="scss" scoped>
.v-bh-info-payment{
  @include flex(column,start,start,4px);
  color:$dark-40;
  font:$font-mini-12-regu;
  .info-payment-id{
    font:$font-14-regu;
    @media (max-width:767px){
      font-size:13px;
    }
  }
}
</style>