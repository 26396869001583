<template>
  <div class="v-tooltip-question-inner vtqi">
    <div class="vtqi__inner">
      <div class="vtqi__top">
        <div v-if="title && title?.length > 0" class="vtqi__title" v-html="title"/>
        <div v-if="description && description?.length > 0" class="vtqi__description" v-html="description"/>
      </div>
      <div class="vtqi__bottom" v-if="intercomLinkEnabled && intercomLinkTitle?.trim()?.length > 0">
        <v-link class="vtqi__link" @click.prevent="chatSupportShowMessage(messageForSupport)">{{intercomLinkTitle}}</v-link>
      </div>
    </div>
  </div>
</template>

<script setup>

import VLink from "@app-vue/components/Base/VLink.vue";
import {chatSupportShowMessage} from "@app-vue/utils/chatWithSupport";
import {computed} from "vue";

const props = defineProps({
  description: {},
  title: {},
  intercomLinkTitle: {},
  intercomLinkEnabled: {}
});

const messageForSupport = computed(() => {
  return 'Please tell me more about "' + props.title + '" and how to use it better';
});

</script>

<style lang="scss" scoped>
.v-tooltip-question-inner.vtqi{
  display:flex;
  .vtqi__inner{
    min-width: 160px;
    max-width: 260px;
    width: fit-content; /* allows the width to be determined by the content */
    flex: 1;
    padding: 12px 10px 12px 16px;
    @include flex(column,start,start,14px);
  }
  .vtqi__top{
    @include flex(column,start,start,10px);
  }
  .vtqi__bottom{

  }
  .vtqi__title{
    font-size:14px;
    font-weight: 600;
    color: #1f293b;
  }
  .vtqi__description{
    font-size: 12px;
    color: #5E718D;
  }
  .vtqi__link{
    font-size: 10px;
  }
}
</style>