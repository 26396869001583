import {defineStore} from "pinia";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {useRoutes} from "@app-vue/stores/useRoutes";
import {groupBy, isArray, uniq} from "lodash";
import {
    adjustObjectToSum,
    compareCountriesAndStates,
    compareString,
    groupUrlsByDomain,
    isObject,
    isValidDate,
    openAuthPopup,
    simpleArrayHash,
    sumObject
} from "@app-vue/utils/utils";
import {useStaticData, useUserBalance} from "@app-vue/stores/useStaticData";
import {CREDIT_TYPE_SEO} from "@app-vue/stores/useBilling";
import {mergeChartData} from "@app-vue/utils/chartUtils";

export const useSeoClicksProjects = defineStore('/api/v1/seotraffic-projects', {
    state: () => {
        return {
            _projects: [],
            countAllProjects: null,
            filterOptionsUrls: [],
            filterOptionsKeywords: [],
        }
    },
    getters: {
        filterOptionsDomains: (state) => {
            return groupUrlsByDomain(state.filterOptionsUrls);
        },
    },
    actions: {
        /***
         * @param {Object} options
         * @param {Object} options.filters
         * @param {Array} [options.filters.types] "show_deleted" / "show_inactive".
         * @param {Array} [options.filters.websites]
         * @param {Array} [options.filters.keywords]
         * @param {Object} [options.filters.sort]
         * @param {'asc'|'desc'} [options.filters.sort.direction]
         * @param {string} [options.filters.sort.column]
         */
        async download(options ={}){
            let resource = new ApiResource({
                url: '/api/v1/seotraffic-projects',
                params: {filters:options.filters},
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state._projects = [];
                    resource.data.projects.forEach((project) => {
                        const useProject = useSeoClicksProject(project.id);
                        useProject.init(project);
                        state._projects.push(useProject);
                    });
                    state.filterOptionsUrls = [...new Set(resource.data.filterOptionsUrls)];
                    state.filterOptionsKeywords = [...new Set(resource.data.filterOptionsKeywords)];
                    state.countAllProjects = resource.data.countAllProjects;
                });
            }else{
                this.$patch({_projects: []});
            }
        },
        async updateRealtime() {

            let projectsIds = this._projects.map(i => i.id_integer);
            if(projectsIds.length === 0) return [true];

            let resource = new ApiResource({url: '/api/minutes-chart-data-by-group', params: {project_ids: projectsIds}});
            let [status, data] = await resource.downloadAsync();
            if(status){
                Object.entries(data).forEach(([idInteger, chart_data_minutes]) => {

                    this._projects.forEach((project) => {
                        if(project.id_integer == idInteger){
                            project.init({chart_data_minutes});
                        }
                    });
                });
            }
        },
        getFilterSettings() {
            return (window.preloadData["/api/v1/seotraffic-projects"]?.data?.filterSettings ?? []);
        },
    },
});

export const useSeoClicksProject = (projectId) => defineStore('/api/v1/seotraffic-projects/'+projectId, {
    state: () => {
        return {
            _api_params: {url:'/api/v1/seotraffic-projects/'+projectId},
            _first_download: false,
            name: null,
            chart_data: null,
            count_keywords: null,
            count_real_active_keywords: null,
            count_not_deleted_keywords: null,
            count_recommendations: null,
            max_degree_recommendations: null,
            countries: null,
            daily_limit: null,
            devices: null,
            id: projectId,
            id_integer: null,
            is_active: null,
            is_deleted: false,
            search_engines_id: null,
            url: null,
            device_targeting_enabled: null,
            is_demo: null,
            count_clicks: null,
            diff_clicks: null,
            max_count_clicks: 50,
            keywords:null,
            /**
             * @typedef {Object} clicks_by_country_item
             * @property {number} count - Количество элементов.
             * @property {number} diff - Разница между значениями.
             */
            /**
             * @type {Object.<string, clicks_by_country_item>}
             */
            clicks_by_country: {},
            created_at: null,
            errors: {
                create: {
                    message: null,
                    errors: null,
                }
            },
            _insufficientBalance:null,
        }
    },
    getters: {
        searchEngine: (state) => {
            return useStaticData().searchEngines.find((searchEngine) => {
                return state.search_engines_id === searchEngine.id;
            }) ?? null;
        },
        chartLabels: (state) => {
            if(!isArray(state.chart_data?.labels)) return [];
            return state.chart_data.labels;
        },
        chartValues: (state) => {
            if(!isArray(state.chart_data?.data)) return [];
            return state.chart_data.data.map(item => item[1]);
        },
        chartValueName: (state) => {
            if(!state.chart_data.typeTraffic) return null;
            if(!state.chart_data.translations?.hasOwnProperty(state.chart_data.typeTraffic)) return state.typeTraffic;
            return state.chart_data.translations[state.chart_data.typeTraffic];
        },
        chartTotalValues(){
            return this.chartValues.reduce((s,i) => s+i);
        },

        countKeywords: (state) => state.count_keywords,
        countActiveKeywords: (state) => {
          if(useSeoClicksKeywords(state.id)._firstDownload){
              let counter = 0;
              Object.values(useSeoClicksKeywords(state.id)._keywords).forEach((item) => {
                if(item.is_active && !item.is_deleted){
                    counter++;
                }
              });
              return counter;
          }else{
              return state.count_real_active_keywords;
          }
        },
        countRealActiveKeywords: (state) => {
            if(useSeoClicksKeywords(state.id)._firstDownload){
                let counter = 0;
                Object.values(useSeoClicksKeywords(state.id)._keywords).forEach((item) => {
                    if(item.is_active && !item.is_deleted && item.statusRecommendationTop100===null){
                        counter++;
                    }
                });
                return counter;
            }else{
                return state.count_real_active_keywords;
            }
        },
        countNotDeletedKeywords: (state) => {
            if(useSeoClicksKeywords(state.id)._firstDownload){
                let counter = 0;
                Object.values(useSeoClicksKeywords(state.id)._keywords).forEach((item) => {
                    if(!item.is_deleted){
                        counter++;
                    }
                });
                return counter;
            }else{
                return state.count_not_deleted_keywords;
            }
        },
        countryCodes: (state) => state.countryCodes,
        devicesType: (state) => {
            if(!state.device_targeting_enabled){
                return null;
            }else{
                return state.devices;
            }
        },
        deviceTypeLabel: (state) => {
            switch(state.devicesType){
                case 'mobile' : return 'Mobile';
                case 'desktop' : return 'Desktop';
                case null : return 'All';
                default: return useProject.devices;
            }
        },
        status: (state) => Number(state.is_active) === 1,
        recommendations: (state) => {
            if(state.count_recommendations > 0){
                return [{type:state.max_degree_recommendations,title:state.count_recommendations}];
            }else{
                return [];
            }
        },
        linkProject: (state) => {
            return useRoutes().url('seo-clicks.get', {id:state.id});
        },
        shortUrl: (state) => {
            if(!state.url) return '';
            let strippedPrefix = state.url.replace(/^(https?:\/\/)/, '');
            let strippedSuffix = strippedPrefix.replace(/\/+$/, '');

            return strippedSuffix;
        },
        correctUrl: (state) => {
            if(!state.url)return '';
            if (!state.url.startsWith('http://') && !state.url.startsWith('https://')) {
                return 'http://' + state.url;
            }
            return state.url;
        },
        demoCompleted(state){
            return state.is_demo && this.demoCountClicks >= state.max_count_clicks;
        },
        demoCountClicks: (state) => {
            return state.count_clicks > state.max_count_clicks ? state.max_count_clicks : state.count_clicks;
        },
        demoMaxCountClicks: (state) => {
            return state.max_count_clicks;
        },
        /**
         * Баланс недостаточный?
         * @return {boolean} - true, если денег не достаточно
         */
        insufficientBalance: (state) => {

            if(state._insufficientBalance!==null){
                return state._insufficientBalance;
            }

            const balance = useUserBalance().getByTypeConstant(CREDIT_TYPE_SEO)?.balance;

            let hasRegionKeyword = false;
            if(useSeoClicksKeywords(state.id).isDownloaded){
                hasRegionKeyword = useSeoClicksKeywords(state.id).idOfActiveKeywords.some((keywordId) => {
                    return useSeoClicksKeyword(state.id,keywordId).region !== null;
                });
            }else if(state.keywords !== null && isArray(state.keywords)){
                hasRegionKeyword = state.keywords.some((keyword) => isArray(keyword) ? keyword[1]!==null : false);
            }

            if(state.is_demo || balance === undefined){return false;}

            return (
                balance <= 0
                || (hasRegionKeyword && balance <= 1)
                || (hasRegionKeyword && state.device_targeting_enabled && balance <= 2)
            );

        },
        createdDate: (state) => {
            let date = new Date(state.created_at);
            return isValidDate(date) ? date : null;
        },
        createdDateStringISO(state){
            return this.createdDate ? this.createdDate.toISOString().split('T')[0] : null;
        },

        /**
         * @typedef {Object} clicks_by_country_item_extended
         * @property {number} count
         * @property {number} countLast
         * @property {number} diff
         * @property {number} countPercent
         * @property {number} countLastPercent
         * @property {number} diffPercent
         */
        /**
         * @type {Object.<string, clicks_by_country_item_extended>}
         */
        clicksByCountryExtended(state){
            let response = {}
            let sumCount = 0;
            let sumCountLast = 0;
            Object.entries(state.clicks_by_country).forEach(([key,item]) => {
                response[key] = {}
                response[key].diff = item.diff;
                response[key].count = item.count;
                response[key].countLast = item.count - item.diff;
                sumCount+=response[key].count;
                sumCountLast+=response[key].countLast;
            });
            Object.entries(response).forEach(([key,item]) => {
                response[key].countPercent = sumCount > 0 ? item.count / sumCount * 100 : 0;
                response[key].countLastPercent = sumCountLast > 0 ? item.countLast / sumCountLast * 100 : 0;
                response[key].diffPercent = response[key].countPercent - response[key].countLastPercent;
            });
            return response;
        },

        showBarDangerPaused(state){
            return !state.is_active && !state.is_deleted;
        },
        showBarDangerNoActiveKeywords(state){
            return state.is_active && !state.is_deleted && this.countRealActiveKeywords === 0;
        },
        showBarDangerCredits(state){
            return state.is_active && !state.is_deleted && this.insufficientBalance && !state.is_demo;
        },

        isActiveRealtime(state){
            return !state.is_deleted
                && state.is_active
                && (!state.is_demo || (state.is_demo && !this.demoCompleted))
                && !this.showBarDangerPaused
                && !this.showBarDangerNoActiveKeywords
                && !this.showBarDangerCredits
        },

    },
    actions: {
        downloadFirst(){
            if(!this._first_download){
                this.download();
            }
        },
        async download(){
            let resource = new ApiResource(this._api_params);
            let [status, data] = await resource.downloadAsync();
            if(status){
                this.init(data)
                return [status,data];
            }else{
                return [status,resource.errorMessage];
            }
        },
        init(data){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(key === 'clicks_by_country'){
                        state[key] = isObject(value) ? value : {};

                    }else if(key === 'chart_data_minutes'){
                        useRealtimeSeoClicksProject(state.id).init(value);

                    }else if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                });
            });
            return this;
        },
        async saveInfoProject(params, projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/'+ (projectId ?? this.id),
                method:'put',
                params: params
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    Object.keys(params).forEach((key) => state[key] = params[key]);
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },
        async saveName(name, projectId = null){
            return await this.saveInfoProject({name:name},projectId);
        },
        async saveUrl(newUrl, projectId = null){
            let oldUrl = this.url;
            let response = await this.saveInfoProject({url:newUrl},projectId);
            let [status] = response;
            if(status && oldUrl !== newUrl){
                await useSeoClicksKeywords(this.id).restartPositionUpdatingForAllKeyword();
            }
            return response;
        },
        async saveDeviceType(deviceType, projectId = null){

            let params = {}

            if(!deviceType){
                params.device_targeting_enabled = 0;
            }else{
                params.device_targeting_enabled = 1;
                params.device = deviceType;
            }

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/'+ (projectId ?? this.id),
                method:'put',
                params: params
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    if(params.hasOwnProperty('device')){
                        state.devices = params.device;
                    }
                    state.device_targeting_enabled = params.device_targeting_enabled;
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        },
        async saveSearchEnginesId(search_engines_id, projectId = null){
            return await this.saveInfoProject({search_engines_id:search_engines_id},projectId);
        },
        async saveDailyLimit(daily_limit, projectId = null){
            return await this.saveInfoProject({daily_limit:daily_limit},projectId);
        },
        async saveIsActive(isActive, projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/'+(projectId ?? this.id)+'/active',
                method:'put',
                params: {
                    is_active: isActive ? 1 : 0
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({is_active: !!isActive});
                return [true];
            }else{
                this.$patch({is_active: !isActive});
                return [false,resource.errorMessage];
            }
        },
        async saveIsDeleted(isDeleted, projectId = null){
            return await this.saveInfoProject({is_deleted: isDeleted ? 1 : 0},projectId);
        },
        formatDate(date) {
            const year = date.getFullYear();
            // Добавляем 1, потому что месяцы начинаются с 0
            // `padStart(2, '0')` добавляет ведущий ноль, если месяц состоит из одной цифры
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async updateChartData(startDate,endDate,projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/'+(projectId ?? this.id)+'/daily-chart-data',
                params: {
                    from: this.formatDate(startDate),
                    to: this.formatDate(endDate),
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({chart_data: resource.data});
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        },

        /**
         * Создание проекта SeoTraffic
         *
         * @param {Object} fields
         *  @param {string} fields.url
         *
         *  @param {Array.<{keyword: String, geo?: String, is_suggested?: Boolean}>} fields.keywords Массив объектов.
         *
         *  @param {boolean} fields.daily_limit_enabled
         *  @param {number} fields.daily_limit
         *
         *  @param {boolean} fields.device_targeting_enabled
         *  @param {number} fields.search_engines_id
         *
         *  @param {boolean} fields.geo_targeting_enabled
         *
         * @returns {[status:boolean, response?:Object]}
         */
        async create(fields){

            let resource = new ApiResource({
                url: '/api/v1/seotraffic-projects',
                method: 'post',
                data: fields
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                this.errors.create.message = null;
                this.errors.create.errors = null;
                return [true,resource.data];
            }else{
                if(resource.errorAccessDeniedForDemoUser){openAuthPopup();}
                this.errors.create.message = resource.errorMessage;
                this.errors.create.errors = resource.errorErrors;
                return [false];
            }
        },

        async convertDemoToReal(){
            let resource = new ApiResource({
                url: `/api/v1/seotraffic-projects/${this.id}/convert-demo`,
                method: 'PUT',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                await this.download();
                return [true];
            }else{
                return [false, resource.errorMessage];
            }
        }
    },
})();

export const useRealtimeSeoClicksProject = (projectId) => defineStore('/api/v1/seotraffic-projects/'+projectId+'/minutes-chart-data',{
    state: () => {
        return {
            _chartData:null,
            projectId: projectId,
        }
    },
    getters: {
        chartData: function(state){
            return state._chartData;
        },
        values: (state) => {
            if(!isArray(state._chartData?.data)) return [];
            return state._chartData.data.map(item => item[1]);
        },
    },
    actions: {
        async update () {
            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/'+this.projectId+'/minutes-chart-data/',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.init(resource.data);
            }else{
                console.log('error realtime');
            }
        },
        init(data){
            this.$patch({_chartData: data});
        }
    }
})();

export const useSeoClicksKeywords = (projectId) => defineStore('/api/v1/seotraffic-projects/'+projectId+'/keywords', {
    state: () => {
        return {
            _projectId: projectId,
            _keywords: {},
            _downloading: false,
            _firstDownload: false,
            _api_params: {url:'/api/v1/seotraffic-projects/'+projectId+'/keywords'},
            _filter: null,
            _sortBy: null,
            _searchKeyword: null,
            _listChecked: {},
            _checkboxTriState: 0,
            _typesSortBy: [
                {
                    value: 'date_added_decrease',
                    name: 'New first',
                    func: (a,b) => {
                        return (a.id < b.id) ? 1 : -1;
                    },
                },
                {
                    value: 'date_added_increase',
                    name: 'New last',
                    func: (a,b) => {
                        return (a.id > b.id) ? 1 : -1;
                    },
                },
                {
                    value: 'name_increase',
                    name: 'Name, ascending',
                    func: (a,b) => {
                        let textA = a.keyword.toUpperCase();
                        let textB = b.keyword.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    },
                },
                {
                    value: 'name_decrease',
                    name: 'Name, descending',
                    func: (a,b) => {
                        let textA = b.keyword.toUpperCase();
                        let textB = a.keyword.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    },
                },
            ],
            _typesFilter: [
                {
                    value: 'hide_inactive',
                    name: 'Hide inactive',
                    func: (item) => {return !!item.is_active}
                },
                {
                    value: 'show_deleted',
                    name: 'Show deleted',
                    func: (item) => {return true}
                }
            ],
            errors: {
                addKeywords: {
                    message: null,
                    errors: null,
                }
            }
        }
    },
    getters: {
        isDownloaded: function(state){
            return !state._downloading && state._firstDownload;
        },
        keywordsShowedIds: function(state){
            let showedIds = [];
            let funcsFilter = state._filter ? state._filter.map((filter) => {return filter.func;}) : [];
            let searchKeyword = (typeof state._searchKeyword === 'string' && state._searchKeyword.length > 0) ? state._searchKeyword : false
            let hasFilterShowDeleted = state._filter ? state._filter.some((filter) => filter.value==='show_deleted') : false

            Object.keys(state._keywords).forEach((keywordId) => {
                let keyword = state._keywords[keywordId];
                if(!keyword.is_deleted || hasFilterShowDeleted){
                    showedIds.push({is_show:true,id:keyword.id});
                }else{
                    showedIds.push({is_show:false,id:keyword.id});
                }
                let showedId = showedIds.find((item) => item.id === keyword.id);

                funcsFilter.forEach((funcFilter) => {
                    if(showedId.is_show){
                        showedId.is_show = funcFilter(keyword);
                    }
                });

                if(searchKeyword && showedId.is_show) {
                    showedId.is_show = keyword.keyword.includes(searchKeyword);
                }

            });

            if(state._sortBy){
                showedIds = showedIds.sort((a,b) => state._sortBy?.func(state._keywords[a.id],state._keywords[b.id]));
            }
            return showedIds;
        },
        countChecked: function(state){
            let counter = 0;
            for(let [key,showedId] of Object.entries(state.keywordsShowedIds)){
                if(showedId.is_show && state._listChecked[showedId.id]){
                    counter++;
                }
            }
            return counter;
        },
        countShowedIds: function(state){
            let counter = 0;
            for(let [key,showedId] of Object.entries(state.keywordsShowedIds)){
                if(showedId.is_show){
                    counter++;
                }
            }
            return counter;
        },
        /**
         * @return {*[]}
         */
        idOfActiveKeywords: function(state){
            let response = [];
            Object.keys(state._keywords).forEach((keywordId) => {
                let keyword = state._keywords[keywordId];
                if(keyword.is_active && !keyword.is_deleted){
                    response.push(keywordId);
                }
            });
            return response;
        }
    },
    actions: {
        async download(){
            this.$patch({_downloading:true});
            let resource = new ApiResource(this._api_params);
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state._keywords = {}
                    state._listChecked = {}

                    const currentUrl = new URL(window.location.href);
                    const demoKeywords = currentUrl.searchParams.get('demo-keywords');
                    if (demoKeywords === 'true') {
                        let data = '[' +
                            '{"id":94564501,"keyword":"buy organic traffic","is_active":1,"is_deleted":0,"max_clicks_per_day":1,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,10],[7,0],[8,0],[9,8],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564502,"keyword":"buy website traffic searchseo","is_active":1,"is_deleted":0,"max_clicks_per_day":1,"language":"en","region":"af","chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[{"message":"Your website should be in the Top 100 for the keyword","degree":"danger","type":"NOT_TOP_100"}],"is_top100":0,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564503,"keyword":"free traffic bot","is_active":1,"is_deleted":0,"max_clicks_per_day":28,"language":"en","region":"as","chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[{"message":"Your website should be in the Top 100 for the keyword","degree":"danger","type":"NOT_TOP_100"}],"is_top100":0,"check_position_now":0,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564504,"keyword":"buy website traffic searchseo","is_active":1,"is_deleted":0,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,5],[7,0],[8,0],[9,0],[10,4],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564505,"keyword":"buy website traffic searchseo","is_active":0,"is_deleted":0,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564506,"keyword":"buy website traffic searchseo","is_active":0,"is_deleted":1,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":0,"top_3":0,"top_10":0}},' +
                            '{"id":94564507,"keyword":"buy website traffic searchseo","is_active":1,"is_deleted":0,"max_clicks_per_day":29,"language":"en","region":null,"chart_data":{"translations":{"pageViews":"Page Views","hits":"Hits","visits":"Visits","clicks":"Clicks"},"data":[[0,0],[1,0],[2,0],[3,0],[4,0],[5,0],[6,0],[7,0],[8,0],[9,0],[10,0],[11,0],[12,0],[13,0],[14,0],[15,0],[16,0],[17,0],[18,0],[19,0],[20,0],[21,0],[22,0],[23,0],[24,0],[25,0],[26,0],[27,0],[28,0],[29,0],[30,0],[31,0]],"totalHits":0,"maxHits":0,"labels":["16 Aug","17 Aug","18 Aug","19 Aug","20 Aug","21 Aug","22 Aug","23 Aug","24 Aug","25 Aug","26 Aug","27 Aug","28 Aug","29 Aug","30 Aug","31 Aug","01 Sep","02 Sep","03 Sep","04 Sep","05 Sep","06 Sep","07 Sep","08 Sep","09 Sep","10 Sep","11 Sep","12 Sep","13 Sep","14 Sep","15 Sep","16 Sep"],"typeTraffic":"clicks"},"recommendations":[],"is_top100":1,"check_position_now":1,"daily_limit_recommendations":{"top_1":1234,"top_3":931,"top_10":561}}' +
                        ']';
                        resource.dataRaw.data = JSON.parse(data);
                    }

                    resource.data.forEach((keyword) => {
                        state._keywords[keyword.id] = useSeoClicksKeyword(state._projectId,keyword.id).init(keyword);
                        state._listChecked[keyword.id] = false;
                    });
                });
            }else{
                this.$patch({_keywords: this.$patch({_keywords:{}})});
            }
            this.$patch({_downloading:false,_firstDownload:true});
        },

        prepareMaxClicksPerDaysForDemo(keywordsIds, desiredMaxClicksPerDay){

            // ключ - id, значение - max_clicks_per_day
            let activeToUpdate = {}; //список id активных кейвордов, которые хотят изменить
            let inactiveToUpdate = {}; //список id не активных кейворды, которые хотят изменить
            let activeUnmodifiable = {}; //список id активных кейворды, которые не хотят изменить

            keywordsIds.forEach((keywordId) => {
                let keyword = this._keywords[keywordId];
                if(keyword)
                    keyword.is_active && !keyword.is_deleted
                        ? activeToUpdate[keywordId] = desiredMaxClicksPerDay
                        : inactiveToUpdate[keywordId] = desiredMaxClicksPerDay
            });
            Object.values(this._keywords).forEach((keyword) => {
                if(keyword.is_active && !keyword.is_deleted && !Object.values(keywordsIds).some((keywordId) => keywordId==keyword.id))
                    activeUnmodifiable[keyword.id] = keyword.max_clicks_per_day;
            });

            if(sumObject(activeToUpdate) > 50)
                activeToUpdate = adjustObjectToSum(activeToUpdate, 50);

            if(sumObject(activeToUpdate) + sumObject(activeUnmodifiable) !== 50)
                activeUnmodifiable = adjustObjectToSum(activeUnmodifiable, 50 - sumObject(activeToUpdate));

            let response = Object.entries({
                ...activeToUpdate,
                ...inactiveToUpdate,
                ...activeUnmodifiable,
            }).reduce((response, [keywordId, maxClicksPerDay]) => {
                let keyword = this._keywords[keywordId];

                if (keyword && keyword.max_clicks_per_day != maxClicksPerDay) {
                    let existingEntry = response.find((item) => item.max_clicks_per_day == maxClicksPerDay);

                    if (!existingEntry) {
                        response.push({
                            keywords_ids: [keywordId],
                            max_clicks_per_day: maxClicksPerDay,
                        });
                    } else {
                        existingEntry.keywords_ids.push(keywordId);
                    }
                }

                return response;
            }, []);

            return response;

        },

        async updateDailyLimit(keywordsIds, maxClicksPerDay){

            let maxClicksPerDaysGroups;
            if(useSeoClicksProject(this._projectId).is_demo){
                maxClicksPerDaysGroups = this.prepareMaxClicksPerDaysForDemo(keywordsIds, maxClicksPerDay);
            }else{
                maxClicksPerDaysGroups = [{
                    keywords_ids: keywordsIds,
                    max_clicks_per_day: maxClicksPerDay
                }];
            }

            let responses = await Promise.all((() => {
                let response = [];
                maxClicksPerDaysGroups.forEach((maxClicksPerDaysGroup) => {
                    let resource = new ApiResource({
                        url:    '/api/v1/seotraffic-projects-keywords-group',
                        method: 'put',
                        params: {
                            keywords_ids: maxClicksPerDaysGroup.keywords_ids,
                            max_clicks_per_day: maxClicksPerDaysGroup.max_clicks_per_day,
                        }
                    });
                    response.push(resource.downloadAsync());
                });
                return response;
            })());

            let error = null;
            responses.forEach(([status,data]) => {
                if(status) {
                    this.groupUpdated(data.updatedKeywords.map((updatedKeyword) => {
                        return {id: updatedKeyword.id, max_clicks_per_day:updatedKeyword.max_clicks_per_day,}
                    }));
                }else{
                    error = data;
                }
            });
            return error ? [false,error] : [true];

        },
        async normalizeDailyLimitForDemo(){
            await this.updateDailyLimit([],0);
        },
        async updateIsActive(keywordsIds, isActive){

            isActive = isActive ? 1 : 0;

            this.groupUpdated(keywordsIds.map((id) => {return {id: id, is_active:isActive,}}));

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects-keywords-group',
                method:'put',
                params: {
                    keywords_ids: keywordsIds,
                    is_active: isActive,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                if(isArray(resource.data.updatedKeywords)){
                    this.groupUpdated(resource.data.updatedKeywords.map((updatedKeyword) => {
                        return {id: updatedKeyword.id, is_active:updatedKeyword.is_active,}
                    }));
                }
                if(useSeoClicksProject(this._projectId).is_demo){
                    await this.normalizeDailyLimitForDemo();
                }
                return [true];
            }else{
                this.groupUpdated(keywordsIds.map((id) => {return {id: id, is_active:!isActive,}}));
                return [false,resource.errorMessage];
            }

        },
        async updateIsDeleted(keywordsIds, isDeleted){
            isDeleted = isDeleted ? 1 : 0;

            this.groupUpdated(keywordsIds.map((id) => {return {id: id, is_deleted:isDeleted,}}));

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects-keywords-group',
                method:'put',
                params: {
                    keywords_ids: keywordsIds,
                    is_delete: isDeleted,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                if(isArray(resource.data?.updatedKeywords)){
                    this.groupUpdated(resource.data.updatedKeywords.map((updatedKeyword) => {
                        return {id: updatedKeyword.id, is_deleted:updatedKeyword.is_deleted,}
                    }));
                }
                if(useSeoClicksProject(this._projectId).is_demo){
                    await this.normalizeDailyLimitForDemo();
                }
                return [true];
            }else{
                this.groupUpdated(
                    keywordsIds.map((id) => {return {id: id, is_deleted:!isDeleted,}})
                );
                return [false,resource.errorMessage];
            }
        },
        async updateKeywords(keywordsIds, data){

            let params = {keywords_ids: keywordsIds}

            if(data.hasOwnProperty('max_clicks_per_day')) params.max_clicks_per_day = data.max_clicks_per_day;
            if(data.hasOwnProperty('is_active')) params.is_active = data.is_active ? 1 : 0
            if(data.hasOwnProperty('is_delete')) params.is_delete = data.is_delete ? 1 : 0;

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects-keywords-group',
                method:'put',
                params: params,
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                return [true, resource.data];
            }else{
                return [false,resource.errorMessage];
            }

        },

        groupUpdated(updatedKeywords){
            updatedKeywords.forEach((updatedKeyword) => {
                this._keywords[updatedKeyword.id].setData(updatedKeyword);
            });
        },

        setFilters(filters){
            let newFilters = {};
            if(filters.hasOwnProperty('filter')){
                newFilters._filter = filters.filter;
            }
            if(filters.hasOwnProperty('sortBy')){
                newFilters._sortBy = filters.sortBy;
            }
            if(filters.hasOwnProperty('searchKeyword')){
                newFilters._searchKeyword = filters.searchKeyword;
            }
            this.$patch(newFilters);
        },

        hasNoChecked(){
            let hasNoChecked = false;
            for(let [key,showedId] of Object.entries(this.keywordsShowedIds)){
                if(showedId.is_show && !this._listChecked[showedId.id]){
                    hasNoChecked = true;
                }
            }
            return hasNoChecked;
        },

        hasChecked(){
            let hasChecked = false;
            for(let [key,showedId] of Object.entries(this.keywordsShowedIds)){
                if(showedId.is_show && this._listChecked[showedId.id]){
                    hasChecked = true;
                }
            }
            return hasChecked
        },

        setCheckedAll(status = true){
            this.$patch((state) => {
                Object.keys(state._listChecked).forEach((id) => {
                    state._listChecked[id] = status;
                })
            });
        },

        tristateUpdate(){
            if(this.hasNoChecked()){
                this.setCheckedAll();
            }else{
                this.setCheckedAll(false);
            }
        },

        updateTriStateCheckbox(){
            if(this.hasChecked()){
                if(this.hasNoChecked()){
                    this.$patch({_checkboxTriState:1});
                }else{
                    this.$patch({_checkboxTriState:2});
                }
            }else{
                this.$patch({_checkboxTriState:0});
            }
        },

        getSelectedIds(){
            let ids = [];
            for(let [key,showedId] of Object.entries(this.keywordsShowedIds)){
                if(showedId.is_show && this._listChecked[showedId.id]){
                    ids.push(showedId.id);
                }
            }
            return ids;
        },

        /**
         * Запуск проверки top100 у всех кейвордов, которые активны
         * запускает ли эта функция обновление информации у кейвордов или она только запускает сами проверки, если их не было?
         */
        async restartPositionUpdatingForAllKeyword(){
            //console.log('restartPositionUpdatingForAllKeyword');
            let keys = Object.keys(this._keywords);
            keys = keys.filter((key) => this._keywords[key].is_active && !this._keywords[key].is_deleted);
            let batchSize = 5;

            for (let i = 0; i < keys.length; i += batchSize) {
                const batch = keys.slice(i, i + batchSize);
                const results = await Promise.all(batch.map(async (key) => {
                    let response = await this._keywords[key].startPositionUpdating()
                    return {
                        response: response,
                        key: key
                    }
                }));
                // после того как все проверки запущены
                // происходит запуск интервальной проверки у каждого кейворда отдельно
                results.forEach((result) => {
                    if(result !== undefined && result.response[0] !== undefined) {
                        this._keywords[result.key].startIntervalCheckPositionUpdating();
                    }
                });
            }

            return true;
        },

        async addKeywords(keywords){

            let resource = new ApiResource({
                url: '/api/v1/seotraffic-projects/'+this._projectId+'/keywords',
                method: 'post',
                data: {keywords: keywords,}
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                this.errors.addKeywords.message = null;
                this.errors.addKeywords.errors = null;
                return [true,resource.data];
            }else{
                this.errors.addKeywords.message = resource.errorMessage;
                this.errors.addKeywords.errors = resource.errorErrors;
                return [false];
            }

        }

    },
})();

export const useSeoClicksKeywordsGroups = (projectId) => defineStore('group-keywords-'+projectId, {
    state: () => {
        return {
            projectId: projectId,
        }
    },
    getters: {
        /**
         * Отсортированный список групп
         *
         * @return Array.<Object>
         *   @property {Array} ids Массив id keywords (группа)
         *   @property {boolean} is_show
         *   @property {number} sortIndex Основной индекс сортировки
         *   @property {number} sortIndexRecommendations Дополнительный индекс сортировки
         */
        listFiltered(state){

            //базовая сортировка берется на основе useSeoClicksKeywords

            let keywordsShowedIds = useSeoClicksKeywords(state.projectId).keywordsShowedIds;

            let groups = this.list.map((groupIds) => {

                // группа должна отображаться, если отображаются все кейворды из группы
                let isShowGroup = groupIds.every((keywordId) => {
                    return keywordsShowedIds.some((keywordShowedId) => {
                        return keywordShowedId.is_show && keywordShowedId.id == keywordId
                    });
                });

                // индекс сортировки берется у кейворда с самым большим id
                let groupMaxId = Math.max(...groupIds.map(Number));
                let sortIndex = keywordsShowedIds.findIndex((keywordShowedId) => keywordShowedId.id == groupMaxId);

                let useKeyword = useSeoClicksKeyword(state.projectId, groupIds[0]);

                return {
                    ids: groupIds,
                    is_show: isShowGroup,
                    sortIndex: sortIndex,
                    groupKeyWithoutTop100: useKeyword.groupKeyWithoutTop100,
                    sortIndexRecommendations: useKeyword.statusRecommendationTop100ForGroupIndexSort,
                }
            });

            // у групп с одинаковым groupKeyWithoutTop100 ставим sortIndex который меньше всех
            const minSortIndexByGroup = groups.reduce((acc, obj) => {
                const key = obj.groupKeyWithoutTop100;
                if (acc[key] === undefined || obj.sortIndex < acc[key]) {
                    acc[key] = obj.sortIndex;
                }
                return acc;
            }, {});
            groups = groups.map(obj => ({...obj, sortIndex: minSortIndexByGroup[obj.groupKeyWithoutTop100]}));

            //сортируем группа сначала по sortIndex, затем по sortIndexRecommendations
            return groups.sort((a, b) => {
                if (a.sortIndex === b.sortIndex) {
                    return a.sortIndexRecommendations - b.sortIndexRecommendations;
                }
                return a.sortIndex - b.sortIndex;
            });

        },

        /**
         * Не отсортированный список групп id кейвордов (все, которые есть)
         */
        list(state){

            let keywordsIds = Object.keys(useSeoClicksKeywords(state.projectId)._keywords);

            return Object.values(
                groupBy(keywordsIds, (keywordId) => {
                    return useSeoClicksKeyword(state.projectId, keywordId).groupKey;
                })
            );

        },
    }
})();

export const useSeoClicksKeywordsGroup = (projectId, keywordsIds) => defineStore('group-keywords-'+projectId+'-'+simpleArrayHash(keywordsIds.sort()), {
    state: () => {
        return {
            projectId: projectId,
            keywordsIds: keywordsIds,
        }
    },
    getters: {
        groupId: (state) => {
            return state.projectId+'-'+simpleArrayHash(state.keywordsIds.sort());
        },
        keyword: (state) => {
            return useSeoClicksKeyword(state.projectId,state.keywordsIds[0]).keyword;
        },
        is_active: (state) => {
            return useSeoClicksKeyword(state.projectId,state.keywordsIds[0]).is_active;
        },
        is_deleted: (state) => {
            return useSeoClicksKeyword(state.projectId,state.keywordsIds[0]).is_deleted;
        },
        is_top100: (state) => {
            return state.keywordsIds.every((keywordId) => !!useSeoClicksKeyword(state.projectId,keywordId).is_top100);
        },
        max_clicks_per_day: (state) => {
            return useSeoClicksKeyword(state.projectId,state.keywordsIds[0]).max_clicks_per_day;
            //return state.keywordsIds.reduce((s,keywordId) => {
            //    return s+useSeoClicksKeyword(state.projectId,keywordId).max_clicks_per_day
            //},0);
        },
        chart_data: (state) => {
            return mergeChartData(state.keywordsIds.map((keywordId) => {
                return useSeoClicksKeyword(state.projectId,keywordId).chart_data
            }));
        },
        countries: (state) => {
            return state.keywordsIds.map((keywordId) => {
                return {
                    region: useSeoClicksKeyword(state.projectId, keywordId).region,
                    state: useSeoClicksKeyword(state.projectId, keywordId).state,
                    keywordId: keywordId,
                }
            }).filter((country) => country.region || country.state);
        },
        hasRecomDaily: (state) => {
            return state.keywordsIds.some((keywordId) => useSeoClicksKeyword(state.projectId,keywordId).hasRecomDaily);
        },
        hasRegion: (state) => {
            return useSeoClicksKeyword(state.projectId,state.keywordsIds[0]).hasRegion;
        },
        recom_daily_top1: (state) => {
            let arr = state.keywordsIds.map((keywordId) =>{
                let recomDaily = useSeoClicksKeyword(state.projectId,keywordId).recom_daily_top1
                return recomDaily !== null ? recomDaily : 0;
            });
            return Math.max(...arr);
        },
        recom_daily_top3: (state) => {
            let arr = state.keywordsIds.map((keywordId) =>{
                let recomDaily = useSeoClicksKeyword(state.projectId,keywordId).recom_daily_top3
                return recomDaily !== null ? recomDaily : 0;
            });
            return Math.max(...arr);
        },
        recom_daily_top10: (state) => {
            let arr = state.keywordsIds.map((keywordId) =>{
                let recomDaily = useSeoClicksKeyword(state.projectId,keywordId).recom_daily_top10
                return recomDaily !== null ? recomDaily : 0;
            });
            return Math.max(...arr);
        },
        hasGeoVariant: (state) => {
            if(state.hasRegion) return null;
            return Object.keys(useSeoClicksKeywords(state.projectId)._keywords).some((keywordId) => {
                let keyword = useSeoClicksKeyword(state.projectId, keywordId);
                if(keyword.is_deleted || !keyword.is_active || !keyword.hasRegion) return false;
                return compareString(keyword.keyword, state.keyword);
            });
        },
        groupKeyWithoutTop100: (state) => {
            return useSeoClicksKeyword(state.projectId,state.keywordsIds[0]).groupKeyWithoutTop100;
        },
        statusRecommendationTop100Index: (state) => {
            return useSeoClicksKeyword(state.projectId,state.keywordsIds[0]).statusRecommendationTop100ForGroupIndexSort;
        },
        relatedGroups: function(state){
            return useSeoClicksKeywordsGroups(state.projectId).list.filter((groupIds) => {
                let useGroup = useSeoClicksKeywordsGroup(state.projectId, groupIds);
                if(useGroup.groupId === state.groupId) return false;
                return useGroup.groupKeyWithoutTop100 === this.groupKeyWithoutTop100;
            });
        },
        keywordsIdsWithRelated: function(state){
            return state.keywordsIds.concat(...this.relatedGroups);
        },
        isMainGroup: function(state) {
            if(this.statusRecommendationTop100Index === 1) return true;

            // текущая группа главная, если в relatedGroups у всех statusRecommendationTop100Index больше чем у текущей
            return this.relatedGroups.every((keywordIds) => {
                let useGroup = useSeoClicksKeywordsGroup(state.projectId, keywordIds);
                return useGroup.statusRecommendationTop100Index > this.statusRecommendationTop100Index;
            });

        }
    },
    actions: {
        async updateDailyLimit(maxClicksPerDay, withRelated = false){
            let keywordsIds = withRelated ? this.keywordsIdsWithRelated : this.keywordsIds;
            return await useSeoClicksKeywords(this.projectId).updateDailyLimit(keywordsIds, maxClicksPerDay);
        },
        async updateIsActive(isActive, withRelated = false){
            let keywordsIds = withRelated ? this.keywordsIdsWithRelated : this.keywordsIds;
            return await useSeoClicksKeywords(this.projectId).updateIsActive(keywordsIds, isActive);
        },
        async updateIsDeleted(isDeleted, withRelated = false){
            let keywordsIds = withRelated ? this.keywordsIdsWithRelated : this.keywordsIds;
            return await useSeoClicksKeywords(this.projectId).updateIsDeleted(keywordsIds, isDeleted);
        },

        /**
         * keyword - имя кейворда, которое нужно заменить у текущей группы
         * listGeo - список гео, который нужно актуализировать для текущей группы
         */
        async update(keyword,listGeo){

            //список кейвордов и их настройки, которые нужны в этой группе
            let keywordsNeed = [];

            //список id кейвордов которые нужно удалить
            let keywordsIdForDelete = [];

            //добавляем все кейворды, которые были переданы в listGeo
            keywordsNeed.push(...listGeo.map((geo) => {
                let region = geo.countryCode;
                let state = geo?.stateCode ? geo.stateCode : null;
                return {
                    region: region,
                    state: state,
                    keyword: keyword,
                }
            }));

            //если был передан пустой список, то нам нужен кейворд без гео
            if(listGeo.length === 0){
                keywordsNeed.push({
                    region:null,
                    state:null,
                    keyword:keyword,
                });
            }

            //если сейчас стран нет, но нам будут нужны страны, то тогда мы должны удалить текущий кейворд без гео
            if(this.countries.length === 0 && listGeo.length > 0){
                keywordsIdForDelete.push(...this.keywordsIds);
            }

            //все страны, которые сейчас есть, но их нет в listGeo (keywordsNeed) - мы их удаляем
            this.countries.filter((country) => {
                return !keywordsNeed.some((geo) => {
                    return compareCountriesAndStates(country.region, country?.state, geo.region, geo?.state);
                });
            }).map((country) => keywordsIdForDelete.push(country.keywordId));

            //если был изменен кейворд, то все текущие keywordsIds попадают под удаление
            if(keyword !== this.keyword){
                keywordsIdForDelete.push(...this.keywordsIds);
            }

            keywordsIdForDelete = uniq(keywordsIdForDelete);

            //теперь нужно найти id кейвордов в keywordsNeed, если таковые имеются

            keywordsNeed.forEach((keywordNeed) => {
                let foundKeywordId = Object.keys(useSeoClicksKeywords(this.projectId)._keywords).find((keywordId) => {
                    let keyword = useSeoClicksKeyword(this.projectId, keywordId);

                    if(!compareString(keyword.keyword, keywordNeed.keyword)) return false;

                    return compareCountriesAndStates(keyword.region, keyword?.state, keywordNeed.region, keywordNeed?.state);
                });
                if(foundKeywordId){
                    keywordNeed.id = foundKeywordId;
                }else{
                    keywordNeed.id = null;
                }
            });

            let packagesUpdatedKeywords = [];

            //удалить кейворды, которые нужно удалить
            if(keywordsIdForDelete.length > 0){
                let [status,response] = await useSeoClicksKeywords(this.projectId).updateKeywords(keywordsIdForDelete, {is_delete: 1});
                if(!status) return [false,response];
                packagesUpdatedKeywords.push(response.updatedKeywords);
            }

            //кейворды которых еще нет - создать
            let idCreatedKeywords = [];
            let keywordsForCreate = keywordsNeed.filter(keywordNeed => !keywordNeed.id).map((keywordNeed) => {
                let keywordForCreate =  {keyword:keywordNeed.keyword,}
                if(keywordNeed?.region){
                    keywordForCreate.geo = keywordNeed.region.toLowerCase();
                    let state_id = keywordNeed?.state ? useStaticData().getStateByCode(keywordNeed.region, keywordNeed.state)?.id : null;
                    if(state_id){
                        keywordForCreate.state_id = state_id;
                    }
                }
                return keywordForCreate;
            });
            if(keywordsForCreate.length > 0){
                let [status, response] = await useSeoClicksKeywords(this.projectId).addKeywords(keywordsForCreate);
                if(!status) return [false,response];
                idCreatedKeywords = response.id_created_keywords;
            }

            //кейворды которые уже есть + которые были созданы - обновить информацию
            let idKeywordsForUpdate = keywordsNeed.filter(keywordNeed => !!keywordNeed.id).map(keywordNeed => keywordNeed.id)
            idKeywordsForUpdate = idKeywordsForUpdate.concat(idCreatedKeywords);
            if(idKeywordsForUpdate.length > 0){
                let [status, response] = await useSeoClicksKeywords(this.projectId).updateKeywords(idKeywordsForUpdate, {
                    is_active: this.is_active,
                    is_delete: this.is_delete,
                    max_clicks_per_day: this.max_clicks_per_day,
                });
                if(!status) return [false,response];
                packagesUpdatedKeywords.push(response.updatedKeywords);
            }

            return [true,{
                idCreatedKeywords: idCreatedKeywords,
                packagesUpdatedKeywords: packagesUpdatedKeywords,
            }];

        }
    },
})();

export const useSeoClicksKeyword = (projectId,keywordId) => defineStore('/api/v1/seotraffic-projects/'+projectId+'/keywords/'+keywordId, {
    state: () => {
        return {
            project_id: projectId,
            chart_data: null,
            id: keywordId,
            is_active: null,
            is_deleted: null,
            keyword: null,
            language: null,
            max_clicks_per_day: null,
            /**
             * @type {string|null}
             */
            region: null,
            state: null,
            is_top100: null,
            check_position_now: null,
            check_position_now_ended: false,
            daily_limit_recommendations: {
                top_1: null,
                top_3: null,
                top_10: null,
            },
            _startIntervalCheckPositionUpdatingTrigger: false,
        }
    },
    getters: {
        hasRegion: function(state){
            return state.region !== null;
        },
        hasRecomDaily: function(state){
            return this.recom_daily_top1!==null || this.recom_daily_top3!==null || this.recom_daily_top10!==null;
        },
        recom_daily_top1: function (state) {
            if(
                state.hasOwnProperty('daily_limit_recommendations')
                && state.daily_limit_recommendations.hasOwnProperty('top_1')
                && state.daily_limit_recommendations.top_1 !== null
                && state.daily_limit_recommendations.top_1 > 0
                && state.daily_limit_recommendations.top_1 < 100000
            ){
                return state.daily_limit_recommendations.top_1;
            }else{
                return null;
            }
        },
        recom_daily_top3: function (state) {
            if(
                state.hasOwnProperty('daily_limit_recommendations')
                && state.daily_limit_recommendations.hasOwnProperty('top_3')
                && state.daily_limit_recommendations.top_3 !== null
                && state.daily_limit_recommendations.top_3 > 0
                && state.daily_limit_recommendations.top_3 < 100000
            ){
                return state.daily_limit_recommendations.top_3;
            }else{
                return null;
            }
        },
        recom_daily_top10: function (state) {
            if(
                state.hasOwnProperty('daily_limit_recommendations')
                && state.daily_limit_recommendations.hasOwnProperty('top_10')
                && state.daily_limit_recommendations.top_10 !== null
                && state.daily_limit_recommendations.top_10 > 0
                && state.daily_limit_recommendations.top_10 < 100000
            ){
                return state.daily_limit_recommendations.top_10;
            }else{
                return null;
            }
        },
        /**
         * @return {'non-top-100'|'loading'|'support'|null}
         */
        statusRecommendationTop100 : function(state){
            if(!state.check_position_now_ended && !state.check_position_now && !state.is_top100){
                return 'non-top-100';
            }else if(state.check_position_now && !state.is_top100){
                return 'loading';
            }else if(state.check_position_now_ended && !state.check_position_now && !state.is_top100){
                return 'support';
            }else{
                return null;
            }
        },

        statusRecommendationTop100ForGroup: function(state){
            if(this.statusRecommendationTop100 === 'non-top-100' || this.statusRecommendationTop100 === 'support'){
                return 'non-top-100';
            }else if(this.statusRecommendationTop100 === 'loading'){
                return 'loading-top-100';
            }else{
                return 'top100';
            }
        },

        statusRecommendationTop100ForGroupIndexSort: function() {
            switch(this.statusRecommendationTop100ForGroup){
                case 'top100' : return 1;
                case 'non-top-100' : return 2;
                case 'loading-top-100' : return 3;
            }
        },

        groupKey: (state) => {
            return `${state.is_active ? 'active': 'non-active'}`
                +`||${state.is_deleted ? 'deleted' : 'non-deleted'}`
                +`||${state.max_clicks_per_day}`
                +`||${state.hasRegion ? 'geo' : 'no-geo'}`
                +`||${state.statusRecommendationTop100ForGroup}`
                +`||${state.keyword.toLowerCase().trim()}`;
        },

        groupKeyWithoutTop100: (state) => {
            return `${state.is_active ? 'active': 'non-active'}`
                +`||${state.is_deleted ? 'deleted' : 'non-deleted'}`
                +`||${state.max_clicks_per_day}`
                +`||${state.hasRegion ? 'geo' : 'no-geo'}`
                +`||${state.keyword.toLowerCase().trim()}`;
        },

    },
    actions: {
        init(data){
            this.$patch((state) => {
                state.chart_data = data?.chart_data ?? null;
                state.is_active = data?.is_active ?? null;
                state.is_deleted = data?.is_deleted ?? null;
                state.keyword = data?.keyword ?? null;
                state.language = data?.language ?? null;
                state.max_clicks_per_day = data?.max_clicks_per_day ?? null;
                state.region = data?.region ?? null;
                state.state = data?.state ?? null;
                state.is_top100 = data?.is_top100 ?? null;
                state.check_position_now = data?.check_position_now ?? null;
                state.check_position_now_ended = data?.check_position_now_ended ?? null;
                state.daily_limit_recommendations.top_1 = data?.daily_limit_recommendations?.top_1 ?? null;
                state.daily_limit_recommendations.top_3 = data?.daily_limit_recommendations?.top_3 ?? null;
                state.daily_limit_recommendations.top_10 = data?.daily_limit_recommendations?.top_10 ?? null;
            });
            this.updateRecommendations(data.recommendations);
            return this;
        },
        updateRecommendations(recommendations) {
            useSeoClicksKeywordsRecommendations(keywordId).init(recommendations);
        },
        setData(data){
            this.$patch((state) => {
                Object.keys(data).forEach((key) => {
                    if(key === 'is_active' || key === 'is_deleted'){
                        state[key] = Boolean(data[key]);
                    }else if(key === 'recommendations'){
                        this.updateRecommendations(data[key]);
                    }else {
                        state[key] = data[key];
                    }
                });
            });
        },

        async updateData(){
            let resource = new ApiResource({url:'/api/v1/seotraffic-projects/keywords/'+this.id});
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.setData(resource.data);
                return [true,resource.data];
            }else{
                return [false, resource.errorMessage];
            }
        },

        async updateDailyLimit(maxClicksPerDay){
            return useSeoClicksKeywords(this.project_id).updateDailyLimit([this.id], maxClicksPerDay);
        },
        async updateIsActive(isActive){
            return useSeoClicksKeywords(this.project_id).updateIsActive([this.id], isActive);
        },
        async updateIsDeleted(isDeleted){
            return useSeoClicksKeywords(this.project_id).updateIsDeleted([this.id], isDeleted);
        },

        /**
         * Запускаю проверку top100 у кейворда на сервере
         */
        async startPositionUpdating(){
            //console.log('startPositionUpdating', this.id);
            this.$patch({check_position_now: 1});
            this.$patch({is_top100: 0});

            let resource = new ApiResource({
                url:'/api/seo-clicks/project/start-position-updating',
                method:'post',
                params: {
                    id: this.id,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                return [true];
            }else{
                this.$patch({check_position_now: 0});
                return [false];
            }
        },

        /**
         * Запуск интервальной проверки о том, что кейворд top100 или не top100 (сервер уведомляет, что проверка окончена)
         */
        startIntervalCheckPositionUpdating(recursive = false){
            // если вызов не рекурсивный и проверка уже была начата, то останавливаем проверку
            if(!recursive && this._startIntervalCheckPositionUpdatingTrigger) return;
            //console.log('startIntervalCheckPositionUpdating', this.id);

            this.$patch({_startIntervalCheckPositionUpdatingTrigger: true});

            setTimeout(() => {
                this.checkPositionUpdating().then(([status,response]) => {
                    if(!status || !response.isDone){
                        this.startIntervalCheckPositionUpdating(true);
                    }else{
                        // если проверка закончена, то обновляем информацию о кейворде
                        this.$patch({_startIntervalCheckPositionUpdatingTrigger: false});
                        this.updateData();
                    }
                });
            },2000);

        },

        /**
         * Проверяем на сервере, закончена ли проверка top100
         */
        async checkPositionUpdating() {
            //console.log('checkPositionUpdating', this.id);
            let resource = new ApiResource({
                url:'/api/seo-clicks/project/check-position-updating',
                method:'get',
                params: {
                    id: this.id,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                return [true,{isDone:resource.data.isDone, isTop100: resource.data.isTop100}];
            }else{
                return [false];
            }

        }
    }
})()

export const useSeoClicksKeywordsRecommendations = (keywordId) => defineStore('seotraffic-keyword-'+keywordId+'-recommendations',{
    state: () => {
        return {
            keywordId: keywordId,
            list: [],
        }
    },
    getters: {
        hasRecommendation: (state) => {
            return state.list.length > 0;
        },
        mainRecommendation: (state) => {
            return state.list.find((recommendation) => recommendation.type==='NOT_TOP_100') ??
                state.list.find((recommendation) => recommendation.degree==='danger') ??
                state.list.find((recommendation) => recommendation.degree==='warning') ??
                state.list.at(0) ?? null;
        }
    },
    actions: {
        init(recommendations){
            this.$patch((state) => {
                state.list = [];
                recommendations.forEach((recommendation, index) => {
                    state.list.push(useSeoClicksKeywordRecommendation(state.keywordId, index).init(recommendation));
                });
            })
        }
    }
})();

export const useSeoClicksKeywordRecommendation = (keywordId, recommendationId) => defineStore('seotraffic-keyword-'+keywordId+'-recommendation-'+recommendationId,{
    state: () => {
        return {
            keywordId: keywordId,
            recommendationId: recommendationId,
            /**
             * @type {string|null}
             */
            message: null,
            /**
             * @type {'warning'|'danger'}
             */
            degree: 'warning',
            /**
             * @type {'SIMPLE'|'NOT_TOP_100'}
             */
            type: 'SIMPLE',
        }
    },
    actions: {
        init(recommendation){
            this.$patch((state) => {
                state.message = recommendation?.message || null;
                state.degree = recommendation?.degree || 'warning';
                state.type = recommendation?.type || 'SIMPLE';
            });
            return this;
        }
    }
})()

export const useSeoClicksProjectsDailyChart = defineStore('/api/v1/seotraffic-projects/daily-chart-data',{
    state: () => {
        return {
            _chartData:null,
        }
    },
    getters: {
        chartData: function(state){
            return state._chartData;
        }
    },
    actions: {
        async update () {
            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/daily-chart-data',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({_chartData: resource.data});
            }else{
                console.log('error chart data daily for projects');
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            // Добавляем 1, потому что месяцы начинаются с 0
            // `padStart(2, '0')` добавляет ведущий ноль, если месяц состоит из одной цифры
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async updateFromTo(startDate,endDate,projectId = null){

            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/daily-chart-data',
                params: {
                    from: this.formatDate(startDate),
                    to: this.formatDate(endDate),
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({_chartData: resource.data});
                return [true];
            }else{
                return [false,resource.errorMessage];
            }

        }
    }
})

export const useRealtimeSeoClicksProjects = defineStore('/api/v1/seotraffic-projects/minutes-chart-data',{
    state: () => {
        return {
            _chartData:null
        }
    },
    getters: {
        chartData: function(state){
            return state._chartData;
        },
        values: function (state){
            if(!isArray(state._chartData?.data)) return [];
            return state._chartData.data.map(item => item[1]);
        }
    },
    actions: {
        async update () {
            let resource = new ApiResource({
                url:'/api/v1/seotraffic-projects/minutes-chart-data',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({_chartData: resource.data});
            }else{
                console.log('error realtime projects');
            }
        },

    }
});
