<template>
  <div class="v-form-select-backlink-type-dropdown">
    <div v-for="option in useBacklinkOptions().options" class="option" :key="option.id" @click="emit('select',option.id)">
      <v-form-select-backlink-type-item class="option-inner" :id="option.id"/>
    </div>
  </div>
</template>

<script setup>

import VFormSelectBacklinkTypeItem from "@app-vue/components/VForm/VFormSelectBacklinkTypeItem.vue";
import {useBacklinkOptions} from "@app-vue/stores/useStaticData";

const emit = defineEmits(['select']);

</script>

<style lang="scss" scoped>
.v-form-select-backlink-type-dropdown{
  background:#fff;
  box-shadow: $box-shadow-tooltip;
  border-radius:4px;

  .option{
    @include flex(row,start,center);
    border-bottom:1px solid $dark-10;
    height:48px;
    cursor:pointer;

    &:last-child{
      border-bottom:0px;
    }

    &:hover{
      background-color:#F4F9FD;
    }

    .option-inner{
    }

  }

}
</style>