<template>
  <div class="v-tabs" :class="mainClass">
    <slot/>
  </div>
</template>

<script setup>

import {computed, provide} from 'vue'

const model = defineModel();

const props = defineProps({
  /**
   * @type {'default'|'container'}
   */
  type: {default:'default',},
});

const mainClass = computed(() => {
  return 'is-'+props.type;
});

provide('vTabsModel', model);
provide('vTabsType', props.type);

</script>

<style lang="scss" scoped>
.v-tabs{

  &.is-default{
    display:flex;
    border-bottom: 1px solid #D6E3EF80;
  }

  &.is-container{
    display:flex;
  }

}
</style>