import {isObject} from "@app-vue/utils/utils";

export const storeErrors = {

    getDefaultStateErrors(actions){
        let errors = {}
        actions.forEach((action) => {
            errors[action] = {
                message: null,
                errors: null,
            }
        });
        return errors;
    },

    setErrorField(store,action,field,message){
        store.$patch((state) => {

            if(!isObject(state.errors[action].errors)){
                state.errors[action].errors = {}
            }

            state.errors[action].errors[field] = [message];
        });
    },

    setErrorMessage(store,action,message){
        store.$patch((state) => {
            state.errors[action].message = message;
        });
    },

    removeErrorField(store,action,field){

        store.$patch((state) => {
            if(isObject(state.errors[action].errors) && state.errors[action].errors.hasOwnProperty(field)){
                delete state.errors.create.errors.keyword;
            }
        });

    },

    removeErrorMessage(store,action){
        this.setErrorMessage(store, action, null);
    },

    clean(store,action){
        store.$patch((state) => {
            state.errors[action].errors = null;
            state.errors[action].message = null;
        });
    },

    resourceErrors(store,action,resource){
        store.$patch((state) => {
            state.errors[action].errors = resource.errorErrors;
            state.errors[action].message = resource.errorMessage;
        });
    },

}