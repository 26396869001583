<template>
  <v-tooltip
      placement="bottom"
      ref="tooltip"
      :style="{
          width:'100%',
          maxWidth:'200px',
          padding:'0px 0px',
          borderRadius:'4px',
          border: '1px solid #D6E3EF',
      }"
      :btn-close-on="false"
      class="v-tooltip-select vts"
      :get-linked-block="props.getLinkedBlock"
      :max-height-visible-area="true"
      reset-tooltip
  >
    <template v-slot:content>
      <v-tooltip-select-content v-model="model"
                                :options="options"
                                :multi-select="multiSelect"
                                @change="(option) => {props.closeAfterSelection ? hide() : null; emit('change', option)}"
                                :display-as-input-checked="displayAsInputChecked"
                                :tooltip-title="tooltipTitle"
                                :search-enable="searchEnable"
                                :search-title="searchTitle"/>
    </template>
  </v-tooltip>
</template>

<script setup>

import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import {computed, ref} from "vue";
import VTooltipSelectContent from "@app-vue/components/Base/VTooltipSelectContent.vue";

const props = defineProps({
  options: {},
  getLinkedBlock: {type:Function, default:{}},
  multiSelect: {type:Boolean,default:false},
  closeAfterSelection: {type: Boolean, default:false},
  displayAsInputChecked: {type:Boolean, default:false},
  tooltipTitle: {default:null,},
  searchEnable: {default:false,},
  searchTitle: {default:null,},
});

const model = defineModel();
const emit = defineEmits(['change']);

const tooltip = ref();

const toggle = () => tooltip.value.toggle();

const hide = () => tooltip.value.hide();

const tooltipOpened = computed(() => tooltip.value.dataShow);

defineExpose({toggle,hide,tooltipOpened});

</script>
<style lang="scss">
.v-tooltip-select.vts{
  background:#fff;
  box-shadow: $box-shadow-tooltip!important;
  border-radius: 5px;
}
</style>
<style scoped lang="scss">
.vts__tooltip{
  .vts__item{
    padding:0px 10px;
    height:40px;
    transition-property: background-color;
    transition-duration: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .vts__item-name{
      display: flex;
      align-items: center;

      .vts__item-label{
        font-size:12px;
        color:#596C8F;
        margin-left:4px;
      }
    }

    &:hover{
      background-color: #F4F9FD;
    }

    .vts__item-svg{
      width:20px;
      height:20px;
      opacity:0;
    }

    &.vts__item--selected{
      .vts__item-svg{
        opacity:1;
      }
    }

  }

  &.vts__tooltip--as-input-checked{
    .vts__item{
      padding:0px 16px;
      min-height:44px;
      height:initial;
      justify-content:flex-start;
      gap:16px;
      .vts__item-name{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color:#384967;
      }
      &.vts__tooltip-title{
        font-size: 12px;
        font-weight: 400;
        color:#798CAE;
        min-height:initial;
        height:initial;
        padding-top:8px;
        padding-bottom:8px;
        &:hover{
          background-color:#fff;
          cursor:initial;
        }
      }
      &.vts__tooltip-search{
        padding:0px;
        border-bottom: 1px solid #D6E3EF80;
        &:hover{
          background-color:#fff;
          cursor:initial;
        }
      }
    }
  }
}
</style>