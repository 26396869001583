<template>
  <div class="v-tooltip-question-icon vtqi" :class="refTooltip?.dataShow ? 'vtqi--show' : ''">
    <div
        class="vtqi__icon-wrap"
        ref="refIconWrap"
        @click="iconTrigger"
        @mouseenter="iconTrigger"
        @mouseleave="iconTrigger"
    >
      <v-icon-svg name="icon-question" class="vtqi__icon"/>
    </div>
    <v-tooltip
        @mouseenter="tooltipTrigger"
        @mouseleave="tooltipTrigger"
        :placement="_tooltipData.placement"
        ref="refTooltip"
        :style="{width:'initial', maxWidth:'100%', padding:'0px', borderRadius:'6px', boxShadow:'none'}"
        background="#F1F5F9"
        boxShadow="none"
        :btn-close-on="false"
        class="v-tooltip-period vtp"
        :get-linked-block="() => refIconWrap"
        :has-arrow="true"
    >
      <template #content>
        <v-tooltip-question-inner
            :title="titleTooltip"
            :description="descriptionTooltip"
            :intercom-link-title="_tooltipData.intercom_button_title"
            :intercom-link-enabled="_tooltipData.intercom_button_enabled"
        />
      </template>
    </v-tooltip>
  </div>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import {computed, getCurrentInstance, ref, watch} from "vue";
import VTooltipQuestionInner from "@app-vue/components/Base/VTooltipQuestionInner.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  text: {},
  /**
   * @type {tooltipObjectRow|null}
   */
  tooltipData: {default:null,},
})

const tooltipDataDefault = {
  description: '',
  icon_filename: '',
  id: '',
  intercom_button_enabled: 0,
  intercom_button_title: 'tell me more about it',
  is_active: 1,
  placement: 'auto',
  selector: '',
  template: 'dark',
  title: '',
  trigger: 'hover focus',
  type: '',
  url_to_use: '',
}

const _tooltipData = computed(() => {
  if(props.tooltipData){
    return Object.assign({}, tooltipDataDefault, props.tooltipData);
  }else{
    return Object.assign({}, tooltipDataDefault, {description: props.text});
  }
});

const typeTrigger = computed(() => {
  if(isMobile.value){
    return 'click';
  }else{
    if(_tooltipData?.value?.trigger?.includes('hover')){
      return 'hover';
    }else{
      return 'click';
    }
  }
});

const refTooltip = ref();
const refIconWrap = ref();

const localStatusShow = ref(false);
const lastElem = ref(null);

/**
 * так как между иконкой и тултипом, есть расстояние, то мы не закрываем сразу, мы ждем 150ms и только потом закрываем
 * если пользователь не навел курсор опять, за этот функционал отвечает localStatusShow
 *
 * но тогда, при резком отведении и повторном наведении на tooltip мы можем столкнуться с неприятным эффектом,
 * когда сложно скрыть tooltip
 * при этом резкое наведение и отведение на иконку - вполне обычное действие
 *
 * чтобы скорректировать этот баг, мы фиксируем каждый раз элемент с которого пользователь ушел или зашел
 * и при закрытии, если это другой элемент, то мы закрываем все равно, и после полного закрытия lastElem обнуляется
 *
 * а при открытии, если это тот же самый элемент и это не refIconWrap, то мы все равно открываем
 *
 */

let timerLastElem = null;

const hideTooltipHover = (elem) => {

  if(elem !== lastElem.value){return;}

  localStatusShow.value = false;
  lastElem.value = elem;

  setTimeout(() => {
    if(!localStatusShow.value){
      refTooltip.value.hide();
      timerLastElem = setTimeout(() => {
        lastElem.value = null;
      },250);
    }
  },150);
}

const showTooltipHover = (elem) => {

  if(elem === lastElem.value && lastElem.value !== 'refIconWrap'){return;}
  clearTimeout(timerLastElem);

  lastElem.value = elem;
  localStatusShow.value = true;
  refTooltip.value.show();
}

watch(() => refTooltip.value?.dataShow, (dataShow) => {
  if(typeTrigger.value === 'click'){
    localStatusShow.value = dataShow;
  }
});

const iconTrigger = (e) => {

  if(typeTrigger.value === 'click' && e.type === 'click'){

    if(!localStatusShow.value){
      localStatusShow.value = true;
      refTooltip.value.show();
    }else{
      localStatusShow.value = false;
      refTooltip.value.hide();
    }

  }else if(typeTrigger.value === 'hover'){

    if(e.type === 'mouseenter'){
      showTooltipHover('refIconWrap');
    }else if(e.type === 'mouseleave'){
      hideTooltipHover('refIconWrap');
    }

  }

}

const tooltipTrigger = (e) => {

  if(typeTrigger.value === 'hover'){

    if(e.type === 'mouseenter'){
      showTooltipHover('refTooltip');
    }else if(e.type === 'mouseleave'){
      hideTooltipHover('refTooltip');
    }

  }

}

const titleTooltip = computed(() => {
  if(_tooltipData.value?.title?.length > 0 && _tooltipData.value?.description?.length > 0){
    return _tooltipData?.value?.title;
  }else{
    return false;
  }
});

const descriptionTooltip = computed(() => {
  if(_tooltipData.value?.title?.length > 0 && _tooltipData.value?.description?.length > 0){
    return _tooltipData?.value?.description;
  }else if(_tooltipData.value?.title?.length > 0) {
    return _tooltipData?.value?.title;
  }else{
    return false;
  }
});

</script>

<style lang="scss" scoped>
.v-tooltip-question-icon.vtqi{
  flex-shrink: 0;
  .vtqi__icon-wrap{
    display:flex;
    align-items:center;
    justify-content:center;
    width:18px;
    height:18px;
    cursor:pointer;
    .vtqi__icon{
      flex-shrink: 0;
      display:block;
      width:15px;
      height:15px;
      &:deep(rect){
        transition-duration:0.2s;
      }
    }

  }
  &.vtqi--show{
    .vtqi__icon{
      &:deep(rect){
        fill-opacity:0.2;
      }
    }
  }
}
</style>