<template>
  <div class="v-project-preview" :class="mainClass">

    <div class="content">
      <slot name="default"></slot>
    </div>

    <div class="modal-blackout" v-if="modalBlackout" :class="modalBlackoutClass">
      <slot name="blackout"></slot>
    </div>

    <slot name="content-after"></slot>

    <span class="shadow-sign" v-if="props.isDemo">
      <span class="shadow-sign__text">DEMO</span>
    </span>

  </div>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  /**
   * @type {'seotraffic'|'link-building'|'website-traffic'}
   */
  type: {type:String},
  isDemo: {type:Boolean, default:false,},
  isExpired: {type:Boolean, default:false,},
  /**
   * @type {'black'|'red'}
   */
  modalBlackout: {type:String},
});

const mainClass = computed(() => {
  let response = [];
  response.push(`is-${props.type}`)
  if(props.isDemo) response.push('is-demo');
  if(props.isExpired) response.push('is-expired');
  if(props.modalBlackout) response.push('is-modal-blackout');
  return response;
});

const modalBlackoutClass = computed(() => {
  let response = [];
  if(props.modalBlackout){
    response.push(`is-${props.modalBlackout}`);
  }
  return response;
})

</script>

<style lang="scss" scoped>
.v-project-preview{

  &.is-seotraffic{
    &:not(.is-demo){@include project-shadow(seotraffic);}
    &.is-demo{@include project-shadow(seotraffic, $is-demo: true);}
  }
  &.is-link-building{
    &:not(.is-demo){@include project-shadow(link-building);}
    &.is-demo{@include project-shadow(link-building, $is-demo: true);}
  }
  &.is-website-traffic{
    &:not(.is-demo){@include project-shadow(website-traffic);}
    &.is-demo{@include project-shadow(website-traffic, $is-demo: true);}
  }

  .content{
    width:calc(100% - 4px);
  }

  .modal-blackout{
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    z-index:2;
    padding-left:4px;
    &.is-black{
      background: $overlay-black;
    }
    &.is-red {
      background: $overlay-red;
    }
  }

  &.is-demo{
    .modal-blackout{
      padding-left:24px;
    }
    .content{
      width:calc(100% - 24px);
    }
  }

  &.is-modal-blackout{
    overflow:hidden;
  }
}
</style>