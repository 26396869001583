import {defineStore} from 'pinia';
import {ApiResource} from '../plugins/ApiExtends';
import {useRoutes} from './useRoutes';
import {isArray} from 'lodash';
import {groupUrlsByDomain, isObject, isValidDate} from "../utils/utils";
import {useStaticData, useUserBalance} from "./useStaticData";
import {CREDIT_TYPE_WEBSITE} from './useBilling';
import {useRealtimeSeoClicksProject} from './useSeoClicks.js';

export const useWebsiteTrafficProjects = defineStore('/api/v1/website-traffic/projects', {
        state: () => {
            return {
                _projects: [],
                countAllProjects: null,
                filterOptionsUrls: [],
            };
        },
        getters: {
            filterOptionsDomains: (state) => {
                return groupUrlsByDomain(state.filterOptionsUrls);
            },
            filterSettings() {
                return this.getFilterSettings();
            },
        },
        actions: {
            /***
             * @param {Object} options
             * @param {Object} options.filters
             * @param {Array} [options.filters.types] "show_deleted" / "show_inactive".
             * @param {Array} [options.filters.websites]
             * @param {Array} [options.filters.keywords]
             * @param {Object} [options.filters.sort]
             * @param {'asc'|'desc'} [options.filters.sort.direction]
             * @param {string} [options.filters.sort.column]
             */
            async download(options ={}){
                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects',
                    params: {filters:options.filters},
                });
                await resource.downloadAsync();
                if(resource.isSuccess()){
                    this.$patch((state) => {
                        state._projects = [];
                        resource.data.projects.forEach((project) => {
                            const useProject = useWebsiteTrafficProject(project.id);
                            useProject.init(project);
                            state._projects.push(useProject);
                        });
                        state.filterOptionsUrls = [...new Set(resource.data.filterOptionsUrls)];
                        state.countAllProjects = resource.data.countAllProjects;
                    });
                }else{
                    this.$patch({_projects: []});
                }
            },
            async updateRealtime() {
                let projectsIds = this._projects.map(i => i.id_integer);
                if (projectsIds.length === 0) return [true];

                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects/minutes-chart-data-by-group',
                    method: 'post',
                    data: {project_ids: projectsIds},
                });
                let [status, data] = await resource.downloadAsync();
                if (status) {
                    Object.entries(data).
                        forEach(([idInteger, chart_data_minutes]) => {

                            this._projects.forEach((project) => {
                                if (project.id_integer === idInteger) {
                                    project.init({chart_data_minutes});
                                }
                            });
                        });
                }
            },
            getFilterSettings() {
                return (window.preloadData["/api/v1/website-traffic/projects"].data.filterSettings ?? []);
            },
        },
    });

export const useWebsiteTrafficProject = (projectId) => defineStore(
    '/api/v1/website-traffic/projects/' + projectId, {
        state: () => {
            return {
                _api_params: {
                    url: '/api/v1/website-traffic/projects/' + projectId,
                },
                _first_download: false,
                name: null,
                chart_data: null,
                count_keywords: null,
                count_recommendations: null,
                max_degree_recommendations: null,
                countries: null,
                daily_limit: null,
                devices: null,
                id: projectId,
                id_integer: null,
                is_active: null,
                is_deleted: false,
                search_engine_id: null,
                url: null,
                device_targeting_enabled: null,
                is_demo: null,
                is_unlim: null,
                is_expired: null,
                count_clicks: null,
                diff_clicks: null,
                max_count_clicks: 6000,
                keywords: null,
                size: null,
                type_size: null,
                credit_name: null,
                /**
                 * @typedef {Object} clicks_by_country_item
                 * @property {number} count - Количество элементов.
                 * @property {number} diff - Разница между значениями.
                 */
                /**
                 * @type {Object.<string, clicks_by_country_item>}
                 */
                clicks_by_country: {},
                created_at: null,
                expires: null,
                errors: {
                    create: {
                        message: null,
                        errors: null,
                    },
                },
                _insufficientBalance: null,
                is_reseller: null,
            };
        },
        getters: {
            searchEngine: (state) => {
                return useStaticData().projectsSearchEngines.find((searchEngine) => {
                    return state.search_engine_id === searchEngine.id;
                }) ?? null;
            },
            chartLabels: (state) => {
                if (!isArray(state.chart_data?.labels)) return [];
                return state.chart_data.labels;
            },
            chartValues: (state) => {
                if (!isArray(state.chart_data?.data)) return [];
                return state.chart_data.data.map(item => item[1]);
            },
            chartValueName: (state) => {
                if (!state.chart_data.typeTraffic) return null;
                if (!state.chart_data.translations?.hasOwnProperty(state.chart_data.typeTraffic)) return state.typeTraffic;
                return state.chart_data.translations[state.chart_data.typeTraffic];
            },
            chartTotalValues() {
                return (this.chartValues.length > 0 ? this.chartValues.reduce((s, i) => s + i) : 0);
            },

            countryCodes: (state) => state.countryCodes,
            devicesType: (state) => {
                if (!state.device_targeting_enabled) {
                    return null;
                } else {
                    return state.devices;
                }
            },
            deviceTypeLabel: (state) => {
                switch (state.devicesType) {
                    case 'mobile' :
                        return 'Mobile';
                    case 'desktop' :
                        return 'Desktop';
                    case null :
                        return 'All';
                    default:
                        return useProject.devices;
                }
            },
            status: (state) => Number(state.is_active) === 1,
            recommendations: (state) => {
                if (state.count_recommendations > 0) {
                    return [
                        {
                            type: state.max_degree_recommendations,
                            title: state.count_recommendations,
                        }];
                } else {
                    return [];
                }
            },
            linkProject: (state) => {
                return useRoutes().url('website-traffic.get', {id: state.id});
            },
            shortUrl: (state) => {
                if (!state.url) {
                    return '';
                }

                let strippedPrefix = state.url.replace(/^(https?:\/\/)/, '');

                return strippedPrefix.replace(/\/+$/, '');
            },
            correctUrl: (state) => {
                if (!state.url) {
                    return '';
                }

                if (!state.url.startsWith('http://') &&
                    !state.url.startsWith('https://')) {
                    return 'http://' + state.url;
                }

                return state.url;
            },
            demoCompleted(state) {
                return (state.is_demo && this.demoCountClicks >= state.max_count_clicks);
            },
            demoExpired(state) {
                return (state.is_demo && state.is_expired);
            },
            demoCountClicks: (state) => {
                return state.count_clicks > state.max_count_clicks
                    ? state.max_count_clicks
                    : state.count_clicks;
            },
            demoMaxCountClicks: (state) => {
                return state.max_count_clicks;
            },
            /**
             * Баланс недостаточный?
             * @return {boolean} - true, если денег не достаточно
             */
            insufficientBalance: (state) => {
                if (state.is_reseller) {
                    return false;
                }

                if (state._insufficientBalance !== null) {
                    return state._insufficientBalance;
                }

                const balance = useUserBalance().getByTypeConstant(state.credit_name)?.balance;

                if (state.is_demo || balance === undefined) {
                    return false;
                }

                return (balance <= 0);
            },
            insufficientBalanceUnlim: (state) => {
                if (state.is_reseller) {
                    return false;
                }

                return !(useUserBalance().getByTypeConstant('credits_unlim_expert')?.balance > 0
                    || useUserBalance().getByTypeConstant('credits_unlim_pro')?.balance > 0
                    || useUserBalance().getByTypeConstant('credits_unlim')?.balance > 0);
            },
            createdDate: (state) => {
                let date = new Date(state.created_at);
                return isValidDate(date) ? date : null;
            },
            createdDateStringISO(state) {
                return this.createdDate
                    ? this.createdDate.toISOString().split('T')[0]
                    : null;
            },
            /**
             * @typedef {Object} clicks_by_country_item_extended
             * @property {number} count
             * @property {number} countLast
             * @property {number} diff
             * @property {number} countPercent
             * @property {number} countLastPercent
             * @property {number} diffPercent
             */
            /**
             * @type {Object.<string, clicks_by_country_item_extended>}
             */
            clicksByCountryExtended(state) {
                let response = {};
                let sumCount = 0;
                let sumCountLast = 0;
                Object.entries(state.clicks_by_country).forEach(([key, item]) => {
                    response[key] = {};
                    response[key].diff = item.diff;
                    response[key].count = item.count;
                    response[key].countLast = item.count - item.diff;
                    sumCount += response[key].count;
                    sumCountLast += response[key].countLast;
                });
                Object.entries(response).forEach(([key, item]) => {
                    response[key].countPercent = sumCount > 0 ? item.count / sumCount * 100 : 0;
                    response[key].countLastPercent = sumCountLast > 0 ? item.countLast / sumCountLast * 100 : 0;
                    response[key].diffPercent = response[key].countPercent - response[key].countLastPercent;
                });
                return response;
            },
            showBarDangerPaused(state){
                return !state.is_active && !state.is_deleted && !state.is_expired;
            },
            showBarDangerCredits(state){
                return state.is_active && !state.is_deleted && this.insufficientBalance && !state.is_demo && state.is_unlim;
            },
            showBarDangerExpired(state){
                return state.is_expired && !state.is_deleted && !state.is_unlim && !state.is_demo;
            },
            isActiveRealtime(state){
                if (state.id === '1Z9192B8223E') {
                    return true;
                }
                return !state.is_deleted
                    && state.is_active
                    && (!state.is_demo || (state.is_demo && !this.demoCompleted))
                    && !this.showBarDangerPaused
                    && !this.showBarDangerCredits
                    && !this.showBarDangerExpired
            },
        },
        actions: {
            downloadFirst() {
                if (!this._first_download) {
                    this.download();
                }
            },
            async download() {
                let resource = new ApiResource(this._api_params);
                let [status, data] = await resource.downloadAsync();
                if (status) {
                    this.init(data);
                    return [status, data];
                } else {
                    return [status, resource.errorMessage];
                }
            },
            init(data) {
                this.$patch((state) => {
                    if (data) {
                        Object.entries(data).forEach(([key, value]) => {
                            if (key === 'clicks_by_country') {
                                state[key] = isObject(value) ? value : {};
                            } else if (key === 'chart_data_minutes') {
                                useRealtimeWebsiteTrafficProject(state.id).init(value);
                            } else if (state.hasOwnProperty(key)) {
                                state[key] = value;
                            }
                        });
                    }
                });
                return this;
            },
            async saveInfoProject(params, projectId = null) {

                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects/' +
                        (projectId ?? this.id),
                    method: 'put',
                    params: params,
                });
                await resource.downloadAsync();
                if (resource.isSuccess()) {
                    this.$patch((state) => {
                        Object.keys(params).
                            forEach((key) => state[key] = params[key]);
                    });
                    return [true];
                } else {
                    return [false, resource.errorMessage];
                }
            },
            async saveName(name, projectId = null) {
                return await this.saveInfoProject({name: name}, projectId);
            },
            async saveUrl(url, projectId = null) {
                let response = await this.saveInfoProject({url: url},
                    projectId);
                let [status] = response;

                return response;
            },
            async saveDeviceType(deviceType, projectId = null) {

                let params = {};

                if (!deviceType) {
                    params.device_targeting_enabled = 0;
                } else {
                    params.device_targeting_enabled = 1;
                    params.device = deviceType;
                }

                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects/' +
                        (projectId ?? this.id),
                    method: 'put',
                    params: params,
                });
                await resource.downloadAsync();
                if (resource.isSuccess()) {
                    this.$patch((state) => {
                        if (params.hasOwnProperty('device')) {
                            state.devices = params.device;
                        }
                        state.device_targeting_enabled = params.device_targeting_enabled;
                    });
                    return [true];
                } else {
                    return [false, resource.errorMessage];
                }

            },
            async saveSearchEnginesId(search_engine_id, projectId = null) {
                return await this.saveInfoProject(
                    {search_engine_id: search_engine_id}, projectId);
            },
            async saveDailyLimit(daily_limit, projectId = null) {
                return await this.saveInfoProject({daily_limit: daily_limit},
                    projectId);
            },
            async saveIsActive(isActive, projectId = null) {
                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects/' +
                        (projectId ?? this.id) +
                        '/active',
                    method: 'put',
                    params: {
                        is_active: isActive ? 1 : 0,
                    },
                });
                await resource.downloadAsync();
                if (resource.isSuccess()) {
                    this.$patch({is_active: !!isActive});
                    return [true];
                } else {
                    this.$patch({is_active: !isActive});
                    return [false, resource.errorMessage];
                }
            },
            async saveIsDeleted(isDeleted, projectId = null) {
                return await this.saveInfoProject(
                    {is_deleted: isDeleted ? 1 : 0},
                    projectId);
            },
            formatDate(date) {
                const year = date.getFullYear();
                // Добавляем 1, потому что месяцы начинаются с 0
                // `padStart(2, '0')` добавляет ведущий ноль, если месяц состоит из одной цифры
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');

                return `${year}-${month}-${day}`;
            },
            async updateChartData(startDate, endDate, projectId = null) {

                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects/' + (projectId ?? this.id) + '/daily-chart-data',
                    params: {
                        from: this.formatDate(startDate),
                        to: this.formatDate(endDate),
                    },
                });
                await resource.downloadAsync();
                if (resource.isSuccess()) {
                    this.$patch({chart_data: resource.data});
                    return [true];
                } else {
                    return [false, resource.errorMessage];
                }

            },

            /**
             * Создание проекта WebsiteTraffic
             *
             * @param {Object} fields
             *  @param {string} fields.url
             *
             *  @param {boolean} fields.daily_limit_enabled
             *  @param {number} fields.daily_limit
             *
             *  @param {boolean} fields.device_targeting_enabled
             *  @param {number} fields.search_engine_id
             *
             *  @param {boolean} fields.geo_targeting_enabled
             *
             * @returns {[status:boolean, response?:Object]}
             */
            async create(fields) {

                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects',
                    method: 'post',
                    data: fields,
                });

                await resource.downloadAsync();

                if (resource.isSuccess()) {
                    this.errors.create.message = null;
                    this.errors.create.errors = null;
                    return [true, resource.data];
                } else {
                    this.errors.create.message = resource.errorMessage;
                    this.errors.create.errors = resource.errorErrors;
                    return [false];
                }
            },

            async convertDemoToReal() {
                let resource = new ApiResource({
                    url: `/api/v1/website-traffic/projects/${this.id}/convert-demo`,
                    method: 'PUT',
                });
                await resource.downloadAsync();
                if (resource.isSuccess()) {
                    await this.download();
                    return [true];
                } else {
                    return [false, resource.errorMessage];
                }
            },
        },
    })();

export const useRealtimeWebsiteTrafficProject = (projectId) => defineStore(
    '/api/v1/website-traffic/projects/' + projectId + '/minutes-chart-data', {
        state: () => {
            return {
                _chartData: null,
                projectId: projectId,
            };
        },
        getters: {
            chartData: function(state) {
                return state._chartData;
            },
            values: (state) => {
                if (!isArray(state._chartData?.data)) return [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
                return state._chartData.data.map(item => item[1]);
            },
        },
        actions: {
            async update() {
                let resource = new ApiResource({
                    url: '/api/v1/website-traffic/projects/' + this.projectId +
                        '/minutes-chart-data/',
                });
                await resource.downloadAsync();
                if (resource.isSuccess()) {
                    this.init(resource.data);
                } else {
                    console.log('error realtime');
                }
            },
            init(data) {
                this.$patch({_chartData: data});
            },
        },
    })();

export const useWebsiteTrafficType = defineStore(
    'website-traffic-type', {
        actions: {
            getConstant(typeName) {
                return typeName?.toLowerCase();
            },
            getTypeCredit(typeConstant) {
                if (typeConstant.indexOf('backlinks') === -1 && typeConstant.indexOf('search') === -1) {
                    return CREDIT_TYPE_WEBSITE;
                } else {
                    return null;
                }
            },
            getTitleShort(typeConstant) {
                return typeConstant;
            },
            getTitleUsual(typeConstant) {
                const creditsMap = {
                    'credits_mini': '60K Economy',
                    'credits_medium': '300K Economy',
                    'credits_large': '600K Economy',
                    'credits_ultimate': '1 000K Economy',
                    'credits_max': '10 000K Economy',
                    'credits_mini_alexa': '60K Professional',
                    'credits_medium_alexa': '300K Professional',
                    'credits_large_alexa': '600K Professional',
                    'credits_ultimate_alexa': '1 000K Professional',
                    'credits_max_alexa': '10 000K Professional',
                    'credits_mini_expert': '60K Expert',
                    'credits_medium_expert': '300K Expert',
                    'credits_large_expert': '600K Expert',
                    'credits_ultimate_expert': '1 000K Expert',
                    'credits_max_expert': '10 000K Expert',
                    'credits_unlim': 'Economy',
                    'credits_unlim_pro': 'Professional',
                    'credits_unlim_expert': 'Expert',
                };

                return creditsMap[typeConstant];
            },
            getColor(typeConstant) {
                return '#224CB9';
            },
        },
    });

export const useWebsiteTrafficProjectsDailyChart = defineStore('/api/v1/website-traffic/projects/daily-chart-data',{
    state: () => {
        return {
            _chartData:null,
        }
    },
    getters: {
        chartData: function(state){
            return state._chartData;
        }
    },
    actions: {
        async update () {
            let resource = new ApiResource({
                url:'/api/v1/website-traffic/projects/daily-chart-data',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({_chartData: resource.data});
            }else{
                console.log('error chart data daily for projects');
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            // Добавляем 1, потому что месяцы начинаются с 0
            // `padStart(2, '0')` добавляет ведущий ноль, если месяц состоит из одной цифры
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async updateFromTo(startDate, endDate, projectId = null, typeTraffic = 'hits') {

            let resource = new ApiResource({
                url: '/api/v1/website-traffic/projects/daily-chart-data',
                params: {
                    from: this.formatDate(startDate),
                    to: this.formatDate(endDate),
                    typeTraffic: typeTraffic,
                },
            });
            await resource.downloadAsync();
            if (resource.isSuccess()) {
                this.$patch({_chartData: resource.data});
                return [true];
            } else {
                return [false, resource.errorMessage];
            }

        },
    }
})

export const useRealtimeWebsiteTrafficProjects = defineStore('/api/v1/website-traffic/projects/minutes-chart-data', {
    state: () => {
        return {
            _chartData: null,
        };
    },
    getters: {
        chartData: function(state) {
            return state._chartData;
        },
        values: function (state){
            if(!isArray(state._chartData?.data)) return [];
            return state._chartData.data.map(item => item[1]);
        }
    },
    actions: {
        async update(params = {}) {
            let resource = new ApiResource({
                url: '/api/v1/website-traffic/projects/minutes-chart-data',
                params: params,
            });
            await resource.downloadAsync();
            if (resource.isSuccess()) {
                this.$patch({_chartData: resource.data});
            } else {
                console.log('error realtime projects');
            }
        },
    },
});