<template>
  <div class="v-sc-keyword-title">
    <div class="keyword-name">{{useKeywordsGroup.keyword}}</div>

    <div class="countries" v-if="useKeywordsGroup.countries.length > 0" @click="emit('openModal')">
      <div class="countries__list">
        <div class="countries__country" v-for="country in useKeywordsGroup.countries.slice(0,countShowCountries)">
          <v-icon-flag :code="country.region" :state-code="country?.state" :has-tooltip="!isMobile"/>
        </div>
      </div>
      <div v-if="useKeywordsGroup.countries.length > countShowCountries"
           class="countries__more"
           ref="refCountriesMore"
           @mouseenter="!isMobile ? refTooltipOtherCountries.show() : null"
           @mouseleave="!isMobile ? refTooltipOtherCountries.hide() : null">
        +{{useKeywordsGroup.countries.length - countShowCountries}}
      </div>
      <div class="countries__edit" v-if="!useKeywordsGroup.is_deleted">
        <v-icon-svg-wrapped :outer-size="16" :inner-size="[12.17,12.15]" name="icon_pencil_v2" class="edit-icon"/>
      </div>
    </div>

    <div class="tag-add-countries" v-if="useKeywordsGroup.countries.length === 0" @click="emit('openModal')">
      All countries <v-icon-svg-wrapped :outer-size="16" :inner-size="[12.17,12.15]" name="icon_pencil_v2" class="edit-icon"/>
    </div>
    <v-tooltip
        v-if="useKeywordsGroup.countries.length > countShowCountries && !isMobile"
        placement="top"
        ref="refTooltipOtherCountries"
        :btn-close-on="false"
        :get-linked-block="() => refCountriesMore"
        reset-tooltip
        :offset="[0,4]"
    >
      <template #content><v-icon-flag-tooltip :regions="otherCountries"/></template>
    </v-tooltip>

  </div>
</template>

<script setup>

import VIconFlag from "@app-vue/components/Base/VIconFlag.vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";
import {useSeoClicksKeywordsGroup} from "@app-vue/stores/useSeoClicks";
import VIconFlagTooltip from "@app-vue/components/Base/VIconFlagTooltip.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import {computed, getCurrentInstance, ref} from "vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const countShowCountries = 4;

const props = defineProps({
  projectId:{},
  keywordsIds:{},
});

const refCountriesMore = ref();
const refTooltipOtherCountries = ref();

const emit = defineEmits(['openModal']);

const useKeywordsGroup = useSeoClicksKeywordsGroup(props.projectId,props.keywordsIds);

const otherCountries = computed(() => {
  return useKeywordsGroup.countries.slice(countShowCountries).map((i) => {return {code:i.region,stateCode:i.state}});
});

</script>

<style lang="scss" scoped>
.v-sc-keyword-title{
  @include flex(column,center,start,3px);
  color:$dark-80;

  .keyword-name{
    @include ellipsis();
  }

  .tag-no-countries{
    font:$font-mini-12-regu;
    color:#9FA5B0;
    cursor:pointer;
  }

  .tag-add-countries{
    @include flex(row,start,center,5px);
    color:#9FA5B0;
    font:$font-mini-12-regu;
    cursor:pointer;
  }

  .countries{
    @include flex(row,start,center,6px);
    cursor:pointer;
    .countries__list{
      @include flex(row,start,center,6px);
    }
    .countries__country{
      @include flex(row,start,center,2px);
    }
    .countries__more{
      font:$font-mini-12-regu;
      color:$secondary-blue-100;
    }
    .countries__edit{
    }
  }
  .edit-icon{
    width:16px;
    height:16px;
    :deep(path){
      transition-duration: 0.2s;
      fill:$secondary-blue-30;
    }
    &:hover{
      :deep(path){
        fill:$secondary-blue-100;
      }
    }
  }
}
</style>