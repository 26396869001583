import {defineStore} from "pinia";
import {UrlManipulator} from "@app-vue/utils/UrlManipulator";

export const useRoutes = defineStore('routes', {
    state: () => {
        return {
            routes: {
                'seo-clicks.list' : '/seo-traffic/projects',
                'seo-clicks.add' : '/seo-traffic/projects/add',
                'seo-clicks.get' : '/seo-traffic/projects/{id}',
                'purchase-clicks.get' : '/cart#tab_seo_traffic_credits',
                'billing-balance.get' : '/billing-balance',
                'buy-credits' : '/cart',
                'backlinks-project.list' : '/backlinks/projects',
                'backlinks-pay.get' : '/cart#tab_backlinks_credits',
                'backlinks-project.add' : '/backlinks/projects/add',
                'backlinks-project.get' : '/backlinks/projects/{uniquekey}',
                'subscriptions' : '/billing/subscriptions',
                'subscription' : '/billing/subscriptions/{id}',
                'website-traffic.list' : '/website-traffic/projects',
                'website-traffic.add' : '/website-traffic/projects/add',
                'website-traffic.get' : '/project?uid={id}',
                'website-traffic-pay.get' : '/cart#tab_web_traffic_credits',
                'website-traffic.renew' : '/renew?uid={id}',
            }
        };
    },
    actions: {
        url(name, params = {}, getParams = {}) {
            if(this.routes.hasOwnProperty(name)){
                let url = this.routes[name];
                Object.keys(params).forEach((key) => {
                    let value = params[key];
                    url = url.replace('{'+key+'}',value);
                });
                url = (new UrlManipulator(window.location.origin + url)).addGetParams(getParams).getUrl();
                return url;
            }else{
                return null;
            }
        },
        redirect(name,params, getParams){
            window.location.href = this.url(name,params,getParams);
        },
        getProjectKey(){
            const url = window.location.pathname; // Получаем текущий URL из адресной строки
            const segments = url.split('/'); // Разделяем URL на сегменты
            return segments.pop();
        },
        getUrlSections(){
            const url = window.location.pathname; // Получаем текущий URL из адресной строки
            return url.split('/');
        },
        getUrlLastSection(){
            return this.getUrlSections().at(-1);
        },
        currentUrlHasParam(param){
            const currentUrl = window.location.href;
            const urlParams = new URL(currentUrl);
            return urlParams.searchParams.has(param);
        }
    }
});