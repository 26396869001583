<template>
  <div class="v-coupon-apply-edit">
    <v-icon-svg-wrapped name="icon-close-dark-100" class="v-coupon-apply-edit__close" :outer-size="14" :inner-size="8.17" @click="close"/>

    <div class="v-coupon-apply-edit__input">

      <div class="v-coupon-apply-edit__input-edit input-edit" :class="{'is-error': !!useCouponDiscount().errors.save.message}">
        <input :placeholder="placeholder"
               class="input-edit__input"
               :class="{'is-lock':loading}"
               @keyup.enter="!loading ? saveCoupon(couponModel) : null"
               v-model="couponModel">
        <v-button class="input-edit__button"
                  text="Apply"
                  color="link2"
                  @click="!loading ? saveCoupon(couponModel) : null"
                  :loading="loading"/>
      </div>

      <v-form-field-list-errors no-margin :errors="useCouponDiscount().errors.save.message"/>
    </div>
  </div>
</template>

<script setup>

import VFormFieldListErrors from "@app-vue/components/VForm/VFormFieldListErrors.vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";
import {ref} from "vue";
import {useCouponDiscount} from "@app-vue/stores/useBilling";
import VButton from "@app-vue/components/Base/VButton.vue";

const props = defineProps({
  placeholder: {default:'Coupon'},
});

const loading = ref(false);

const emit = defineEmits(['close','saved-success', 'saved-with-error']);

const couponModel = ref();

async function saveCoupon(coupon){
  loading.value = true;
  let [status,errors] = await useCouponDiscount().save(coupon);
  loading.value = false;

  if(status){
    emit('saved-success', [status]);
  }else{
    emit('saved-with-error', [status, errors]);
  }

}

function close(){
  useCouponDiscount().errorsSaveClean();
  emit('close');
}

</script>

<style lang="scss" scoped>
.v-coupon-apply-edit{
  font-size: 13px;

  @include flex(column,start,start,2px);
  position:relative;

  .v-coupon-apply-edit__close{
    cursor:pointer;
    position:absolute;
    top:12px;
    left:-24px;
  }
  .v-coupon-apply-edit__input{
    @include flex(column,start,start,2px);
    width:100%;
  }

}
.input-edit{
  @include flex(row,between,center,6px);
  background-color: #fff;
  border:1px solid $dark-10;
  border-radius: 4px;
  padding:0px 10px;
  width:100%;

  &.is-error{
    border-color:$optional-red_100;
  }

  .input-edit__button{
    font-size:inherit;
    padding:5px 0px;
    flex-shrink: 0;
  }

  .input-edit__input{
    border:0px;
    padding:0px;
    align-self:stretch;
    height:36px;
    flex-grow: 2;
    &.is-lock{
      @include state-block();
    }
    &:focus{
      outline:0px;
    }
  }

  .input-edit__loader{
    width:16px;
    height:16px;
  }

}
</style>