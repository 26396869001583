<template>
  <teleport to="body">
    <transition name="sheet-modal">
      <div class="v-sheet-modal" v-if="isOpened">
        <div class="background" @click="close()"></div>
        <div class="modal-container" :style="{height: height ? height : 'initial'}">

          <div class="content">
            <slot name="content"/>
          </div>

          <v-sticky-section-buttons v-if="hasGroupButtons" class="group-buttons">
            <template #content>
              <slot name="group-buttons"></slot>
            </template>
          </v-sticky-section-buttons>

          <div class="modal-close" @click="close()" :class="'is-'+typeModalClose">
            <v-icon-svg name="close-white" class="modal-close__icon"/>
          </div>

        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {provide, ref} from "vue";
import VStickySectionButtons from "@app-vue/components/Base/VStickySectionButtons.vue";

const isOpened = ref(false);

const props = defineProps({
  hasGroupButtons: {default:false,type:Boolean},
  height: {default: '70%'},
  typeModalClose: {default:'outer',}
})

function open(){
  disablePullToRefresh();
  document.body.classList.add('no-scroll');
  isOpened.value = true;
}

function close(){
  enablePullToRefresh();
  document.body.classList.remove('no-scroll');
  isOpened.value = false;
}

function disablePullToRefresh() {
  document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
}

function enablePullToRefresh() {
  document.removeEventListener('touchmove', preventPullToRefresh, { passive: false });
}

function preventPullToRefresh(e) {
  if (e.touches.length > 1 || e.touches[0].pageY < 0) {
    e.preventDefault();
  }
}

defineExpose({open,close,isOpened});
provide('closeModal', close);

</script>

<style lang="scss" scoped>
.v-sheet-modal{
  $sheetModalDuration: 0.2s;
  transition-duration: $sheetModalDuration;

  position:fixed;
  width:100%;
  height:100%;
  z-index:99998;
  top:0px;
  left:0px;

  .background{
    background: rgba(0, 0, 0, 0.5);
    position:absolute;
    width:100%;
    height:100%;
    z-index:1;
  }

  .modal-container{
    @include flex(column,start,stretch);
    width:100%;
    height:70%;
    background:#fff;
    position:absolute;
    bottom:0px;
    z-index:2;
    border-radius: 16px 16px 0px 0px;
  }

  .content{
    width:100%;
    flex-grow:10;
    overflow:hidden;
  }

  .modal-close{
    &.is-outer{
      @include flex(row,center,center);
      width:32px;
      height:32px;
      background:#FFFFFF26;
      border-radius:50%;
      position:absolute;
      top:calc(-32px - 12px);
      right:14px;
      .modal-close__icon{
        opacity:0.5;
      }
    }
    &.is-inner{
      @include flex(row,center,center);
      width:40px;
      height:40px;
      position:absolute;
      top:0px;
      right:0px;
      .modal-close__icon{
        opacity:1;
        fill:#9FA5B0;
      }
    }

  }

  .group-buttons{
    flex-shrink:0;
  }

  &.sheet-modal-enter-from{
    .background{opacity:0;}
    .modal-container{transform: translate(0px, calc(100% + 32px + 12px))}
  }
  &.sheet-modal-enter-active {
    .background {transition: opacity $sheetModalDuration ease;}
    .modal-container {transition: transform $sheetModalDuration ease;}
  }
  &.sheet-modal-enter-to{
    .background{opacity:1;}
    .modal-container{transform: translate(0px, 0px)}
  }
  &.sheet-modal-leave-from{
    .background{opacity:1;}
    .modal-container{transform: translate(0px, 0px)}
  }
  &.sheet-modal-leave-active{
    .background{transition: opacity $sheetModalDuration ease;}
    .modal-container {transition: transform $sheetModalDuration ease;}
  }
  &.sheet-modal-leave-to{
    .background{opacity:0;}
    .modal-container{transform: translate(0px, calc(100% + 32px + 12px))}
  }

}
</style>