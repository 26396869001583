<template>
  <div class="v-wic-tooltip-arrow" data-popper-arrow/>
</template>

<style lang="scss" scoped>
.v-wic-tooltip-arrow{
  &:before{
    background:#fff!important;
  }
}
</style>
<style lang="scss">
.v-tooltip[data-popper-placement^=bottom]{
  .v-wic-tooltip-arrow:before{
    background: $secondary-blue-100!important;
  }
}
</style>