<template>
  <div class="v-search-bar-v3"
       :class="mainClasses"
       @mousedown="handleWrapMousedown">

    <div class="search-bar-icon">
      <transition name="fade">
        <v-icon-svg name="magnifier-v3"
                    class="icon-magnifier"
                    v-if="modelLength === 0 && !inputIsFocus"
                    as-block
                    as-img/>
      </transition>
      <transition name="fade">
        <v-icon-svg name="icon-close-search-v2"
                    as-block
                    as-img
                    v-if="modelLength > 0 || inputIsFocus"
                    @click.prevent="model = ''; refInput.blur()"
                    class="icon-close"/>
      </transition>
    </div>



    <input class="search-bar-input"
           :placeholder="placeholder"
           @focusin="inputIsFocus = true;"
           @focusout="inputIsFocus = false"
           v-model="model"
           ref="refInput"/>

  </div>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed, ref} from "vue";

const model = defineModel();

const props = defineProps({
  placeholder: {type: String},
});

const refInput = ref();
const inputIsFocus = ref(false);
const modelLength = computed(() => {
  if (typeof model.value !== 'string') {
    return 0;
  } else {
    return model.value.length
  }
});

const mainClasses = computed(() => {
  let classes = [];
  if(inputIsFocus.value) classes.push('is-focus');
  return classes;
})

function handleWrapMousedown(e){
  if(e.target !== refInput.value){
    if (document.activeElement === refInput.value) {
      e.preventDefault();
    }else{
      setTimeout(() => {
        refInput.value.focus();
      },10);
    }
  }
}

</script>

<style scoped lang="scss">
.v-search-bar-v3{
  @include flex(row,between,center,10px);
  padding:0px 12px;
  height:46px;
  background:$dark-5;
  border-radius: 4px;
  border:1px solid transparent;
  transition: border 0.2s;

  &.is-focus{
    border-color:$secondary-blue-100
  }

  .search-bar-input{
    border:none;
    outline:0px;
    flex-grow:2;
    background:transparent;
    padding:0px;
    &::placeholder{
      color:$dark-30;
    }
  }

  .search-bar-icon{
    width:24px;
    position:relative;
    flex-shrink:0;
    @include flex(row,center,center);
  }

  .icon-magnifier{
    position:absolute;
  }

  .icon-close{
    position:absolute;
    cursor:pointer;
    fill:$dark-30;
    width:20px;
    height:20px;
  }

}
.fade-enter-from{
  display:block;
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.2s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:block;
}
.fade-leave-active{
  transition: opacity 0.2s;
}
.fade-leave-to{
  opacity:0;
}
</style>