<template>
  <teleport to="body">
    <transition name="fade" mode="out-in">
      <div class="v-modal" v-if="show" :class="resetDefaultParams ? 'v-modal--reset-default-params' : ''">
        <div class="v-modal__backdrop" @click="closeModal()"/>

        <div class="v-modal__dialog" :style="!resetDefaultParams ? {maxWidth: maxWidth} : {}">
          <div class="v-modal__content" :style="!resetDefaultParams ? {padding:padding} : {}">
            <slot/>
            <button type="button" class="v-modal__close" @click="closeModal()">
              <v-icon-svg name="icon-tooltip-close"/>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {provide, ref} from "vue";

const props = defineProps({
  maxWidth:{default:'100%',},
  padding:{default:'19px 14px',},
  resetDefaultParams: {default:false,type:Boolean,},
});

const show = ref(false);

function closeModal(){
  show.value = false;
  setTimeout(() => {
    document.querySelector("body").classList.remove("overflow-hidden");
  },200);
}

function openModal(){
  show.value = true;
  document.querySelector("body").classList.add("overflow-hidden");
}

defineExpose({closeModal, openModal});
provide('closeModal', closeModal);

</script>


<style lang="scss" scoped>
.v-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;

  &:after{
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: '';
  }

  .v-modal__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0f1f3acc;
    z-index: 1;
  }

  .v-modal__dialog {
    position: relative;
    width: calc(100% - 40px);
    background-color: #ffffff;
    border-radius: 5px;
    margin: 20px auto;
    z-index: 2;
    display: inline-block;
    vertical-align: middle;
  }

  .v-modal__content{
    padding:19px 14px;
    text-align: initial;
  }

  .v-modal__close{
    position:absolute;
    top:10px;
    right:10px;
    opacity:0.5;
    cursor:pointer;
    display: block;
    svg{
      display: block;
      width:12px;
      height:12px;
    }
    &:hover{
      opacity:0.7;
    }
  }

  &.v-modal--reset-default-params{
    .v-modal__dialog{
      background-color:initial;
      width:initial;
    }
    .v-modal__content{
      padding:initial;
    }
  }

}

.fade-enter-from{
  opacity:0;
  display:block;
}
.fade-enter-active{
  transition: opacity 0.2s;
  display:block;
}
.fade-enter-to{
  opacity:1;
  display:block
}
.fade-leave-from{
  display:block;
}
.fade-leave-active{
  transition: opacity 0.2s;
  display:block;
}
.fade-leave-to{
  opacity:0;
  display:block;
}
</style>