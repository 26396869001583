import {defineStore} from "pinia";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {useWebsiteTrafficType} from "@app-vue/stores/useWebsiteTraffic";
import {useLinkBuildingType} from "@app-vue/stores/useLinkBuilding";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_BACKLINK_V2, CREDIT_TYPE_SEO, CREDIT_TYPE_WEBSITE, useCreditTypes} from "@app-vue/stores/useBilling";
import {isArray} from "lodash";
import {initStore} from "@app-vue/utils/utils";

export const useStaticData = defineStore('staticData', {
    state: () => {
        return {
            _regions: null,
            _languages: null,
            _searchEngines: null,
            _backlinksCategories: null,
        }
    },
    getters: {

        regions: function(state){
            if(state._regions === null){
                new ApiResource({url: '/api/v1/regions'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        state._regions = data;
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._regions;
            }
        },

        regionsForSelect: function(state){
            let response = [];
            state.regions.forEach((region) => {
                response.push({
                    value: region.code,
                    name: region.name,
                });
            });
            return response.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                // Сравнение строк
                if (nameA < nameB) {
                    return -1;  // a идёт первым
                }
                if (nameA > nameB) {
                    return 1;   // b идёт первым
                }
                return 0;       // порядок не изменяется
            });
        },

        languages: function(state){
            if(state._languages === null){
                new ApiResource({url: '/api/v1/languages'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        state._languages = data.languages;
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._languages;
            }
        },

        languagesForSelect: function(state){
            let response = [];
            state.languages.forEach((language) => {
                response.push({
                    value: language.code,
                    name: language.name,
                });
            });
            return response;
        },

        searchEngines: function(state){
            if(state._searchEngines === null || !isArray(state._searchEngines)){
                new ApiResource({url: '/api/v1/search-engines'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        this.$patch({
                            _searchEngines: data.searchEngines.map((searchEngine) =>  {
                                switch(searchEngine.code){
                                    case 'google_search' :
                                        searchEngine.icon = 'search-engine-google';
                                        searchEngine.shortName = 'Google';
                                        searchEngine.showSuggestedKeywords = true;
                                        break;
                                    case 'google_images' :
                                        searchEngine.icon = 'search-engine-google-images';
                                        searchEngine.shortName = 'Google';
                                        searchEngine.showSuggestedKeywords = false;
                                        break;
                                    case 'google_video' :
                                        searchEngine.icon = 'search-engine-google-video';
                                        searchEngine.shortName = 'Google';
                                        searchEngine.showSuggestedKeywords = false;
                                        break;
                                    case 'bing' :
                                        searchEngine.icon = 'search-engine-bing';
                                        searchEngine.shortName = 'Bing';
                                        searchEngine.showSuggestedKeywords = false;
                                        break;
                                    case 'duckduckgo' :
                                        searchEngine.icon = 'search-engine-duckduckgo';
                                        searchEngine.shortName = 'DuckDuckGo';
                                        searchEngine.showSuggestedKeywords = false;
                                        break;
                                    case 'youtube' :
                                        searchEngine.icon = 'search-engine-youtube';
                                        searchEngine.shortName = 'Youtube';
                                        searchEngine.showSuggestedKeywords = false;
                                        break;
                                    case 'google_news' :
                                        searchEngine.icon = 'search-engine-google-news';
                                        searchEngine.shortName = 'Google';
                                        searchEngine.showSuggestedKeywords = false;
                                        break;
                                    default: searchEngine.icon = null;
                                }
                                return searchEngine;
                            })
                        });
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._searchEngines;
            }
        },

        searchEnginesForSelect: function(state){
            let response = [];
            state.searchEngines.forEach((searchEngine) => {
                response.push({
                    value: searchEngine.id,
                    name: searchEngine.name,
                    iconData:{
                        name:searchEngine.icon,
                        outerSize:24,
                        innerSize:[null,18],
                        asImg:true,
                        asBlock:true,
                    }
                });
            });
            return response;
        },

        projectsSearchEngines: function(state){
            if(state._searchEngines === null || !isArray(state._searchEngines)){
                new ApiResource({url: '/api/v1/projects-search-engines'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        this.$patch({
                            _searchEngines: data.searchEngines.map((searchEngine) =>  {
                                switch(searchEngine.code){
                                    case 'google_search' :
                                        searchEngine.icon = 'search-engine-google';
                                        searchEngine.shortName = 'Google';
                                        break;
                                    case 'google_news' :
                                        searchEngine.icon = 'search-engine-google-news';
                                        searchEngine.shortName = 'Google';
                                        break;
                                    case 'google_images' :
                                        searchEngine.icon = 'search-engine-google-images';
                                        searchEngine.shortName = 'Google';
                                        break;
                                    case 'google_video' :
                                        searchEngine.icon = 'search-engine-google-video';
                                        searchEngine.shortName = 'Google';
                                        break;
                                    case 'bing' :
                                        searchEngine.icon = 'search-engine-bing';
                                        searchEngine.shortName = 'Bing';
                                        break;
                                    case 'duckduckgo' :
                                        searchEngine.icon = 'search-engine-duckduckgo';
                                        searchEngine.shortName = 'DuckDuckGo';
                                        break;
                                    case 'youtube' :
                                        searchEngine.icon = 'search-engine-youtube';
                                        searchEngine.shortName = 'Youtube';
                                        break;
                                    default: searchEngine.icon = null;
                                }
                                return searchEngine;
                            })
                        });
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._searchEngines;
            }
        },

        backlinksCategories(state){
            if(state._backlinksCategories === null){
                new ApiResource({url: '/api/v1/backlinks-category'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        state._backlinksCategories = data;
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._backlinksCategories;
            }
        },

        backlinksCategoriesForSelect: function(state){
            let response = [];
            state.backlinksCategories.forEach((backlinksCategory) => {
                response.push({
                    value: backlinksCategory.id,
                    name: backlinksCategory.name,
                });
            });
            return response;
        },

    },
    actions: {
        getRegionByCode(code){
            return this.regions.find(region => code.toLowerCase() === region.code.toLowerCase());
        },
        getStateByCode(regionCode, stateCode){
            let region = this.getRegionByCode(regionCode);
            if(region){
                return region.states.find(state => state.code.toLowerCase() === stateCode.toLowerCase());
            }else{
                return null;
            }
        },
    }
})

export const useBacklinkOptions = defineStore('useBacklinkOptions', {
    state: () => {
        return {
            downloaded: false,
            options: [],
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                url: '/api/v1/backlinks-project-options',
                method: 'get'
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state.downloaded = true;
                    state.options = [];
                    resource.data.options.forEach((option) => {
                        state.options.push(useBacklinkOption(option.id).init(option));
                    });
                });
                return [true,resource.data];
            }else{
                console.log('error', resource.errorMessage,resource.errorErrors);
                return [false];
            }

        },
        getById(id){
            return this.options.find((option) => option.id === id) ?? null;
        },
        getTitleShortById(id){
            let option = this.getById(id);
            if(option){
                return useLinkBuildingType().getTitleShort(option.typeConstant);
            }else{
                return null;
            }
        }
    }
});

export const useBacklinkOption = (id) => defineStore(`useBacklinkOption-${id}`,{
    state: () => {
        return {
            id: id,
            name: null,
            value: null,
            credits: null,
        }
    },
    getters: {
        typeConstant: (state) => state.value?.replace(/_/g, ''),
        titleShort(state){
            return useLinkBuildingType().getTitleShort(this.typeConstant);
        },
        description(state){
            return useCreditTypes().creditTypesBacklink.find((creditType) => {
                return creditType.firstPlanOptions.typeConstantBacklink === this.typeConstant;
            })?.description;
        },
    },
    actions: {
        init(data){
            initStore(this, data);
            return this;
        }
    },
})();

export const useUserBalance = defineStore('user-balance',{
    state: () => {
        return {
            items: [],
        }
    },
    getters: {
        websiteCreditBalances: (state) => {
            // return state.items.filter((item) => item.creditType === CREDIT_TYPE_WEBSITE);
            return state.items.filter((item) => {
                return (item.creditType === CREDIT_TYPE_WEBSITE && (item.typeConstant === 'credits_unlim' || item.typeConstant === 'credits_unlim_pro' || item.typeConstant === 'credits_unlim_expert'));
            });
        },
        seoCreditBalances: (state) => {
            return state.items.filter((item) => item.creditType === CREDIT_TYPE_SEO);
        },
        backlinkBalances: (state) => {
            return state.items.filter((item) => item.creditType === CREDIT_TYPE_BACKLINK);
        },
        backlinkV2Balances: (state) => {
            return state.items.filter((item) => item.creditType === CREDIT_TYPE_BACKLINK_V2);
        },
        backlinkV2BalancesAmount(state){
            return this.backlinkV2Balances.reduce((amount,item) => amount + item.balance, 0);
        },
        backlinkV2BalancesStatus(state){
            return this.backlinkV2Balances[0]?.status;
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                'url' : '/api/v1/user/balance',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){

                Object.entries(resource.data).forEach(([type,balanceData]) => {

                    let typeConstant = useLinkBuildingType().getConstant(type);
                    if(!typeConstant){return}

                    useUserBalanceItem(typeConstant).init(balanceData);

                    if(!this.getByTypeConstant(typeConstant)){
                        this.$patch((state) => {
                            state.items.push(useUserBalanceItem(typeConstant));
                        })
                    }

                });

                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },
        getByTypeConstant(typeConstant){
            let response = this.items.find((item) => item.typeConstant === typeConstant);
            if(response && response?.creditType === CREDIT_TYPE_BACKLINK_V2){
                return this.items.find((item) => item.creditType === CREDIT_TYPE_BACKLINK_V2);
            }else{
                return response;
            }
        }
    }

});

export const useUserBalanceItem = (typeConstant) => defineStore('user-balance-'+typeConstant,{
    state: () => {
        return {
            typeConstant: typeConstant,
            /**
             * @type {'red'|'green'|'yellow'}
             */
            status: null,
            balance: null,
        }
    },
    getters: {
        creditType: (state) => {
            if (state.typeConstant.indexOf('seo_credit') === -1 && state.typeConstant.indexOf('da') === -1 && state.typeConstant.indexOf('backlinks') === -1) {
                return useWebsiteTrafficType().getTypeCredit(state.typeConstant);
            }

            return useLinkBuildingType().getTypeCredit(state.typeConstant);
        },
    },
    actions: {
        init(data){
            this.$patch({
                status: data.status,
                balance: data.balance,
            });
        }
    }
})();

export const useUserInfo = defineStore('/api/userinfo',{
    state: () => {
        return {
            _downloaded: false,
            id:null,
            login: null,
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                'url' : '/api/userinfo'
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({
                    id: resource.data.id,
                    login: resource.data.login
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        }
    }

});

export const useFaq = (type) => defineStore('/api/v1/faq/'+type,{
    state: () => {
        return {
            /**
             * @type {{type: string,question: string, answer: string, sort_order:numeric}[]}
             */
            type: type,
            items: [],
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                'url' : '/api/v1/faq/',
                'method' : 'get',
                'params' : {
                    type: this.type
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({items: resource.data});
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        }
    }
})()

export const useCheckWebsite = defineStore('api/v1/check-website', {
    state: () => {
        return {
            loading: false,
            errorMessage: null
        }
    },
    actions: {
        async check(url, isDemo){
            let resource = new ApiResource({
                'url' : '/api/v1/check-website',
                'method' : 'get',
                'params' : {
                    url: url,
                    is_demo: isDemo,
                    secret: useSecretCodes().apiSecretAuth,
                }
            });

            this.$patch({loading: true});
            await resource.downloadAsync();
            this.$patch({loading: false});

            if(resource.isSuccess()){
                this.$patch({errorMessage: null});
                return [true];
            }else{
                this.$patch({errorMessage: resource.errorMessage});
                return [false,resource.errorMessage];
            }
        }
    }
});

export const useSecretCodes = defineStore('secretCodes', {
    state: () => {
        return {
            apiSecretAuth: 'sj1ms71nal2maj2odytypmwn23',
        }
    }
});