<template>
  <div class="v-wic-tooltip-head">
    <span>
      <slot>What is 1 credit ?</slot>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.v-wic-tooltip-head{
  @include flex(row,center,center);
  width:100%;
  height:44px;
  font:$font-h2-18-semi;
  color:#fff;
  background:$secondary-blue-100;
  border-radius:8px 8px 0px 0px;
  @media(max-width:767px){
    font: $font-13-regu;
    height:40px;
  }
}
</style>