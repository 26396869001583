<template>
  <div class="v-type-traffic-by-simple vsbs" ref="wrap">
    <div class="vsbs__text">
      <span v-if="hasText">Delivered&nbsp;</span>
      <v-link href="#" ref="link" @click.prevent="refTooltipSelect.toggle()">{{model.name}}</v-link>
      <div class="vsbs__icon" @click.prevent="refTooltipSelect.toggle()">
        <v-icon-svg class="vsbs__icon-svg" name="arrow-short-v2" as-block/>
      </div>
    </div>
    <v-tooltip-select
        ref="refTooltipSelect"
        v-model="model"
        :get-linked-block="() => link.$el"
        :options="options"
        :style="{maxWidth: '216px'}"
        :close-after-selection="true"
    />
  </div>
</template>

<script setup lang="ts">
import VIconSvg from "./VIconSvg.vue";
import VLink from "./VLink.vue";
import VTooltip from "./VTooltip.vue";
import VTooltipSelect from "./VTooltipSelect.vue";
import {ref} from "vue";

const props = defineProps({
  options: {type: Array, default: []},
  arrowSortingDirect: {type: Boolean, default: false},
  hasText: {type: Boolean, default: true},
});

const model = defineModel();
const wrap = ref();
const refTooltipSelect = ref();
const link = ref();
</script>

<style scoped>
.v-type-traffic-by-simple.vsbs {
  .vsbs__text {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
</style>