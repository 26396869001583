<template>
  <div class="v-form-select-backlink-type-item">
    <div class="name">
      {{useBacklinkOption(props.id)?.titleShort}}
    </div>
    <div class="price">
      {{useBacklinkOption(props.id)?.credits}} {{pluralize('credit',useBacklinkOption(props.id)?.credits)}}
    </div>
  </div>
</template>

<script setup>
import {useBacklinkOption} from "@app-vue/stores/useStaticData";
import {pluralize} from "@app-vue/utils/utils";

const props = defineProps({
  id: {},
});

</script>

<style lang="scss" scoped>
.v-form-select-backlink-type-item{
  padding:6px 10px;
  .name{
    color:$dark-80;
  }
  .price{
    font:$font-mini-12-regu;
    color:$dark-30;
  }
}
</style>