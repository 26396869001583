<template>
  <div class="v-lb-optimization-short-list" :class="{'as-row': asRow}">

    <div class="item" v-if="useProject.recommendations_danger_count > 0">
      <v-icon-svg-wrapped :outer-size="[24,16]" :inner-size="16" class="icon" name="smiley-bad" as-img/>
      <v-link :href="useProject.link" v-text="`Critical ${useProject.recommendations_danger_count}`"/>
    </div>

    <div class="item" v-if="useProject.recommendations_warning_count > 0">
      <v-icon-svg-wrapped :outer-size="[24,16]" :inner-size="16" class="icon" name="smiley-normal" as-img/>
      <v-link :href="useProject.link" v-text="`Warning ${useProject.recommendations_warning_count}`"/>
    </div>

    <div class="item" v-if="useProject.recommendations_succss_count > 0">
      <v-icon-svg-wrapped :outer-size="[24,16]" :inner-size="16" class="icon" name="smiley-good" as-img/>
      <v-link :href="useProject.link" v-text="`Normal ${useProject.recommendations_succss_count}`"/>
    </div>

    <div class="item" :class="{'w-100': asRow}">
      <v-circle-state :size="24"
                      :value="useProject.optimizationRatingPercent"
                      :color="useProject.optimizationRatingType"/>
      <span class="text" @click="redirectIntoProject" v-text="'Optimisation score'"/>
    </div>

  </div>
</template>

<script setup>

import VLink from "@app-vue/components/Base/VLink.vue";
import VCircleState from "@app-vue/components/Base/VCircleState.vue";
import {useLinkBuildProject} from "@app-vue/stores/useLinkBuilding";
import {computed, getCurrentInstance} from "vue";
import {useRoutes} from "@app-vue/stores/useRoutes";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey: {},
  asRow: {type:Boolean, default:false,}
});

const useProject = useLinkBuildProject(props.projectUniquekey);

function redirectIntoProject(){
  window.location.href=useRoutes().url('backlinks-project.get',{uniquekey: useProject.uniquekey})
}

</script>

<style lang="scss" scoped>
.v-lb-optimization-short-list{
  @include flex(column,start,start,5px);
  &.as-row{
    @include flex(row,start,center,5px);
    flex-wrap:wrap;
  }
  .item{
    @include flex(row,start,center,5px);
    font:$font-13-regu;
    @media (max-width: 767px) {
      font:$font-mini-12-regu;
    }
  }
  .text{
    color:$dark-40;
    @media (max-width: 767px) {
      font:$font-mini-11-regu;
    }
  }
}
</style>