<template>
  <div class="v-sticky-section-buttons">
    <slot name="content"/>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.v-sticky-section-buttons{
  @include flex(row,between,stretch,10px);
  padding:12px 10px;
  box-shadow: $box-shadow-tooltip;
  width:100%;
  background:#fff;
}
</style>