<template>
  <a class="v-link" :rel="rel" :class="mainClasses"><slot>{{text}}</slot></a>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  isOuter: {type:Boolean, default:false},
  text: {default:null},
  isUnderline: {default:false,type:Boolean,},
  onlyStyle: {type:Boolean, default:false,},
  isEndsEllipsis: {type:Boolean,default:false,},
  isInheritStyle: {default:false,type:Boolean,},
});

const rel = computed(() => props.isOuter ? 'noopener noreferrer' : null);

const mainClasses = computed(() => {
  return {
    'is-underline': props.isUnderline,
    'is-only-style': props.onlyStyle,
    'is-ends-ellipsis': props.isEndsEllipsis,
    'is-inherit-style': props.isInheritStyle,
  }
})

</script>

<style scoped lang="scss">
.v-link{
  color:$color-link;
  text-decoration: none;
  cursor: pointer;
  &.is-underline{
    text-decoration:underline;
  }
  & :deep(svg){
    fill:$color-link;
  }
  &:hover{
    color:$color-link-hover;
  }
  &:hover :deep(svg){
    fill:$color-link-hover;
  }
  &.is-only-style{
    cursor:default;
    &:hover{
      color:$color-link;
    }
  }
  &.is-inherit-style{
    color:inherit;
    &:hover{
      color:inherit;
    }
  }
  &.is-ends-ellipsis{
    @include ellipsis();
  }
}
</style>