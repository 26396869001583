<template>
  <div v-if="showSvg"
       class="v-icon-flag"
       :class="{'has-state':!!showCodeState,}"
       ref="refMain"
       @mouseenter="showTooltip()"
       @mouseleave="hideTooltip()">
    <div class="wrap-country-icon">
      <svg v-bind="attributesSvg" v-html="innerHtmlSvg" class="country-icon"></svg>
    </div>
    <div class="state-code" v-if="!!showCodeState">
      {{showCodeState}}
    </div>
    <v-tooltip
        v-if="hasTooltip"
        placement="top"
        ref="refTooltip"
        :btn-close-on="false"
        :get-linked-block="() => refMain"
        reset-tooltip
        :offset="[0,4]"
    >
      <template #content><v-icon-flag-tooltip :code="code" :stateCode="stateCode"/></template>
    </v-tooltip>
  </div>
</template>

<script setup>
import {computed, ref, watchEffect} from 'vue'
import * as iconsArr from 'country-flag-icons/string/3x2'
import {hasFlag} from 'country-flag-icons'
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import VIconFlagTooltip from "@app-vue/components/Base/VIconFlagTooltip.vue";
import {isString} from "lodash";

const props = defineProps({
  code: {},
  stateCode: {default:false,},
  hasTooltip: {type:Boolean,default:true},
});

const refTooltip = ref();
const refMain = ref();

const innerCode = computed(() => {
  if(typeof props.code === 'string'){
    if(props.code.toUpperCase() === 'UK'){
      return 'GB';
    }
  }
  return props.code;
});

const innerHtmlSvg = ref(''); //html внутри svg
const attributesSvg = ref({}); //html внутри svg
const showSvg = computed(() => {
  if(typeof innerCode.value === 'string'){
    return hasFlag(innerCode.value.toUpperCase());
  }else{
    return false;
  }
});

const showCodeState = computed(() => {
  if(isString(props.stateCode)){
    return props.stateCode.toUpperCase();
  }else{
    return null;
  }
});

watchEffect(() => {
  if(showSvg.value){
    let svgHtml = iconsArr[innerCode.value.toUpperCase()];
    let svgElemOuter = document.createElement('div');
    svgElemOuter.innerHTML = svgHtml;
    let svgElem = svgElemOuter.firstChild;

    innerHtmlSvg.value = svgElem.innerHTML;
    attributesSvg.value.xmlns = svgElem.getAttribute('xmlns')
    attributesSvg.value.viewBox = svgElem.getAttribute('viewBox');
  }
});

function showTooltip(){
  if(props.hasTooltip){
    refTooltip.value?.show();
  }
}
function hideTooltip() {
  if(props.hasTooltip){
    refTooltip.value?.hide();
  }
}

</script>

<style scoped lang="scss">
.v-icon-flag{
  @include flex(row,center,center,4px);

  .wrap-country-icon{
    flex-shrink: 0;
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid $dark-20;
    border-radius: 2px;

    .country-icon{
      display:block;
      width:18px;
      height:12px;
    }
  }

  .state-code{
    font-weight: 500;
    font-size: 10px;
    color:$dark-80;
  }

  &.has-state{
    border: 1px solid $dark-20;
    border-radius: 2px;
    padding:2px;

    .wrap-country-icon{
      border:none;
    }

  }
}
</style>