export function normalizeSegmentAndPoints(start, end, points = []){

    if (start > end) {
        points = points.map((point) => {
            return end + (start - point);
        });
        [start, end] = [end, start];
    }

    return [start, end, points];

}

/**
 * Расчитывает процент нахождения point между start и end.
 *
 * @param {number} point - Точка, для которой рассчитывается процент.
 * @param {number} start - Начальная точка диапазона.
 * @param {number} end - Конечная точка диапазона.
 * @returns {number} Процент нахождения point между start и end.
 */
export function calculatePercentage(point, start, end) {

    // Нормализуем порядок start, end и point
    [start, end, [point]] = normalizeSegmentAndPoints(start, end, [point]);

    // Вычисляем диапазон
    const range = end - start;

    // Если диапазон равен 0, возвращаем 0, чтобы избежать деления на 0
    if (range === 0) {
        return 0;
    }

    // Если point находится вне диапазона, возвращаем 0 или 100
    if (point <= start) {
        return 0;
    } else if (point >= end) {
        return 100;
    }

    // Вычисляем и возвращаем процентное соотношение
    return Math.round((point - start) / range * 100 ) * 100 / 100;

}

/**
 * Рассчитывает процент длины отрезка между pointFirst и pointEnd относительно диапазона между start и end.
 *
 * @param {number} pointFirst - Начальная точка отрезка.
 * @param {number} pointEnd - Конечная точка отрезка.
 * @param {number} start - Начальная точка диапазона.
 * @param {number} end - Конечная точка диапазона.
 * @returns {number} Процент длины отрезка относительно диапазона.
 */
export function calculateSegmentPercentage(pointFirst, pointEnd, start, end) {
    // Нормализуем порядок start и end
    [start, end, [pointFirst, pointEnd]] = normalizeSegmentAndPoints(start, end, [pointFirst, pointEnd]);

    // Нормализуем порядок pointFirst и pointEnd
    if (pointFirst > pointEnd) {
        [pointFirst, pointEnd] = [pointEnd, pointFirst];
    }

    // Вычисляем длину диапазона и отрезка
    const rangeLength = end - start;
    const segmentLength = Math.min(Math.max(pointEnd, start), end) - Math.max(Math.min(pointFirst, end), start);

    // Если диапазон равен 0, возвращаем 0, чтобы избежать деления на 0
    if (rangeLength === 0) {
        return 0;
    }

    // Вычисляем процент длины отрезка относительно диапазона
    const percentage = (segmentLength / rangeLength) * 100;

    // Ограничиваем процент до диапазона от 0 до 100
    return Math.min(Math.max(percentage, 0), 100);
}

/**
 * Преобразует шестнадцатеричный цвет в объект RGB.
 * @param {string} hex - Шестнадцатеричный цвет.
 * @returns {Array} Массив с числами [r,g,b]
 */
function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return [
        (bigint >> 16) & 255,
        (bigint >> 8) & 255,
        bigint & 255,
    ];
}

/**
 * Преобразует объект RGB в шестнадцатеричный цвет.
 * @param {Number} r - red
 * @param {Number} g - green
 * @param {Number} b - blue
 * @returns {string} Шестнадцатеричный цвет.
 */
function rgbToHex(r, g, b) {
    return (
        '#' +
        ((1 << 24) + (r << 16) + (g << 8) + b)
            .toString(16)
            .slice(1)
            .toUpperCase()
    );
}

/**
 * Вычисляет промежуточный цвет градиента
 * @param {string} startColor - Начальный цвет в шестнадцатеричном формате.
 * @param {string} endColor - Конечный цвет в шестнадцатеричном формате.
 * @param {number} percent - Процент (0-100).
 * @returns {string} Промежуточный цвет в шестнадцатеричном формате.
 */
export function getIntermediateGradientColor(startColor, endColor, percent) {
    return getGradientColor([[startColor,0],[endColor,100]], percent);
}
export function getGradientColor(gradient, value) {

    if(value < 0){
        value = 0;
    }else if(value > 100){
        value = 100;
    }else if(!(value >= 0 && value <= 100)){
        return '#000000';
    }

    // Находим два цвета, между которыми находится значение
    let startColor, endColor, startValue, endValue;

    for (let i = 0; i < gradient.length - 1; i++) {
        startColor = gradient[i][0];
        startValue = gradient[i][1];
        endColor = gradient[i + 1][0];
        endValue = gradient[i + 1][1];

        if (value >= startValue && value <= endValue) {
            break;
        }
    }

    // Преобразуем цвета из шестнадцатеричной системы в RGB
    const startRgb = hexToRgb(startColor);
    const endRgb = hexToRgb(endColor);

    const ratio = (value - startValue) / (endValue - startValue);
    const interpolatedRgb = [
        Math.round(startRgb[0] + ratio * (endRgb[0] - startRgb[0])),
        Math.round(startRgb[1] + ratio * (endRgb[1] - startRgb[1])),
        Math.round(startRgb[2] + ratio * (endRgb[2] - startRgb[2])),
    ];

    return rgbToHex(...interpolatedRgb);

}

export function calculateScaleYMaxForBar(maxHits){
    let maxHitsD = parseInt(maxHits + maxHits / 10);
    let maxY = 10;

    if (maxHitsD <= 10) maxY = 10;
    else if (maxHitsD > 10 && maxHitsD <= 50) maxY = 50;
    else if (maxHitsD > 50 && maxHitsD <= 100) maxY = 100;
    else if (maxHitsD > 100 && maxHitsD <= 150) maxY = 150;
    else if (maxHitsD > 150 && maxHitsD <= 200) maxY = 200
    else if (maxHitsD > 200 && maxHitsD <= 250) maxY = 250
    else if (maxHitsD > 250 && maxHitsD <= 300) maxY = 300
    else if (maxHitsD > 300 && maxHitsD <= 350) maxY = 350
    else if (maxHitsD > 350 && maxHitsD <= 400) maxY = 400
    else if (maxHitsD > 400 && maxHitsD <= 450) maxY = 450
    else if (maxHitsD > 450 && maxHitsD <= 500) maxY = 500
    else if (maxHitsD > 500 && maxHitsD <= 550) maxY = 550
    else if (maxHitsD > 550 && maxHitsD <= 600) maxY = 600
    else if (maxHitsD > 600 && maxHitsD <= 650) maxY = 650
    else if (maxHitsD > 650 && maxHitsD <= 700) maxY = 700
    else if (maxHitsD > 700 && maxHitsD <= 750) maxY = 750
    else if (maxHitsD > 750 && maxHitsD <= 800) maxY = 800
    else if (maxHitsD > 800 && maxHitsD <= 850) maxY = 850
    else if (maxHitsD > 850 && maxHitsD <= 900) maxY = 900
    else if (maxHitsD > 900 && maxHitsD <= 950) maxY = 950
    else if (maxHitsD > 950 && maxHitsD <= 1000) maxY = 1000
    else if (maxHitsD > 1000 && maxHitsD <= 1500) maxY = 1500
    else if (maxHitsD > 1500 && maxHitsD <= 2000) maxY = 2000
    else if (maxHitsD > 2000 && maxHitsD <= 2500) maxY = 2500
    else if (maxHitsD > 2500 && maxHitsD <= 3000) maxY = 3000
    else if (maxHitsD > 3000 && maxHitsD <= 3500) maxY = 3500
    else if (maxHitsD > 3500 && maxHitsD <= 4000) maxY = 4000
    else if (maxHitsD > 4000 && maxHitsD <= 4500) maxY = 4500
    else if (maxHitsD > 4500 && maxHitsD <= 5000) maxY = 5000
    else if (maxHitsD > 5000 && maxHitsD <= 5500) maxY = 5500
    else if (maxHitsD > 5500 && maxHitsD <= 6000) maxY = 6000
    else if (maxHitsD > 6000 && maxHitsD <= 6500) maxY = 6500
    else if (maxHitsD > 6500 && maxHitsD <= 7000) maxY = 7000
    else if (maxHitsD > 7000 && maxHitsD <= 7500) maxY = 7500
    else if (maxHitsD > 7500 && maxHitsD <= 8000) maxY = 8000
    else if (maxHitsD > 8000 && maxHitsD <= 8500) maxY = 8500
    else if (maxHitsD > 8500 && maxHitsD <= 9000) maxY = 9000
    else if (maxHitsD > 9000 && maxHitsD <= 9500) maxY = 9500
    else if (maxHitsD > 9500 && maxHitsD <= 10000) maxY = 10000
    else if (maxHitsD > 10000) {
        let maxHits1 = parseInt(maxHitsD / 1000);
        maxHitsD = parseInt(String(maxHits1) + '000');
        maxY = maxHitsD + 2000
    }
    return maxY;
}

export function calculateScaleYMaxForLine(maxHits){
    let maxHitsD = parseInt(maxHits + ((maxHits / 3) * 2));

    let maxY = 10;

    if (maxHitsD <= 10) {
        maxY = 10
    } else if (maxHitsD > 10 && maxHitsD <= 30) {
        maxY = 30
    } else if (maxHitsD > 30 && maxHitsD <= 50) {
        maxY = 50
    } else if (maxHitsD > 50 && maxHitsD <= 100) {
        maxY = 100
    } else if (maxHitsD > 100 && maxHitsD <= 300) {
        maxY = 300
    } else if (maxHitsD > 300 && maxHitsD <= 500) {
        maxY = 500
    } else if (maxHitsD > 500 && maxHitsD <= 1000) {
        maxY = 1000
    } else if (maxHitsD > 1000 && maxHitsD <= 3000) {
        maxY = 3000
    } else if (maxHitsD > 3000 && maxHitsD <= 5000) {
        maxY = 5000
    } else if (maxHitsD > 5000 && maxHitsD <= 10000) {
        maxY = 10000
    } else if (maxHitsD > 10000) {
        let maxHits1 = parseInt(maxHitsD / 1000);
        // let maxHits2 = parseInt(maxHitsD % 1000);

        maxHitsD = parseInt(String(maxHits1) + '000');
        maxY = maxHitsD + 3000;
    }
    return maxY
}

export function mergeChartData(charts){
    const merged = {
        translations: charts[0].translations,
        data: charts[0].data.map(item => [item[0], 0]),
        totalHits: 0,
        maxHits: 0,
        labels: charts[0].labels,
        typeTraffic: charts[0].typeTraffic
    };

    charts.forEach(obj => {
        merged.totalHits += obj.totalHits;

        obj.data.forEach((item, index) => {
            merged.data[index][1] += item[1];
            if (merged.data[index][1] > merged.maxHits) {
                merged.maxHits = merged.data[index][1];
            }
        });
    });

    return merged;
}

/**
 * Плагин, который после отрисовки столбцов перерисовывает последний и делает анимацию подпрыгивания
 */
export const jumpingBarPlugin = {
    id: 'jumpingBarPlugin',
    getJumpOffset: function(time) {
        const period = 1600;         // полный цикл
        const aMax = 5;             // максимальная амплитуда (10 px вверх)
        const upTime = 400;          // 0..500 ms — подъём
        const holdUpTime = 400;      // 500..1000 ms — пауза вверху
        const downTime = 400;        // 1000..1500 ms — опускание
        const holdDownTime = 400;    // 1500..2000 ms — пауза внизу

        // Берём текущие миллисекунды по модулю периода
        const t = time % period;

        if (t < upTime) {
            // Линейно растём от 0 до aMax за upTime
            return aMax * (t / upTime);
        } else if (t < upTime + holdUpTime) {
            // Держим максимальную высоту
            return aMax;
        } else if (t < upTime + holdUpTime + downTime) {
            // Линейно опускаемся с aMax до 0
            const dt = t - (upTime + holdUpTime);
            return aMax - aMax * (dt / downTime);
        } else {
            // Оставшуюся часть цикла (holdDownTime) мы внизу = 0
            return 0;
        }
    },
    /**
     * Аналогично тому, как Chart.js рисует BarElement, но мы применяем
     * это вручную только к последнему бару с "подпрыгиванием".
     *
     * @param {CanvasRenderingContext2D} ctx
     * @param {object} barOpts  // backgroundColor, borderColor, borderWidth, borderAlign
     * @param {number} x        // центр столбца
     * @param {number} y        // верхняя координата столбца
     * @param {number} w        // ширина столбца
     * @param {number} h        // высота столбца (base - y)
     */
    drawBarLikeChartJS: function (ctx, barOpts, x, y, w, h){
        const { backgroundColor, borderColor, borderWidth, borderAlign = 'center' } = barOpts;

        // 1) Заливаем тело столбца
        ctx.save();
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(x, y, w, h);
        ctx.restore();

        // 2) Рисуем границы. Chart.js рисует их, как правило, stroke'ом,
        //    но с учётом borderWidth для каждой стороны. Причём если
        //    borderAlign = 'center', то линию располагает «по центру»
        //    границы, т.е. половина толщины «внутри», половина «снаружи».

        // Преобразуем borderWidth в объект (top/left/right/bottom)
        let bw;
        if (typeof borderWidth === 'number') {
            bw = { top: borderWidth, left: borderWidth, right: borderWidth, bottom: borderWidth };
        } else {
            bw = {
                top: borderWidth.top || 0,
                left: borderWidth.left || 0,
                right: borderWidth.right || 0,
                bottom: borderWidth.bottom || 0
            };
        }

        // Если все стороны = 0, то делать stroke не нужно
        if (!bw.top && !bw.left && !bw.right && !bw.bottom) {
            return;
        }

        ctx.save();
        ctx.strokeStyle = borderColor;
        // Для stroke нам нужно «пройтись» по периметру столбца, учитывая смещения.

        // Chart.js при borderAlign='center' рисует контур в координатах,
        // смещённых на halfThickness внутрь и наружу.
        // Но поскольку каждая сторона может иметь свою толщину,
        // Chart.js фактически рисует 4 «отрезка» (или 1 path с дырками) —
        // здесь для простоты нарисуем 4 отрезка по отдельности.

        // TOP side
        if (bw.top > 0) {
            // При center — фактическая видимая линия идёт на 0.5*top «внутрь» и 0.5 «наружу».
            // Упростим и будем считать, что мы двигаемся «вниз» на half от top,
            // чтобы середина линии шла по y.
            const half = bw.top / 2;
            ctx.lineWidth = bw.top;
            ctx.beginPath();
            ctx.moveTo(x, y + half);
            ctx.lineTo(x + w, y + half);
            ctx.stroke();
        }

        // BOTTOM side
        if (bw.bottom > 0) {
            const half = bw.bottom / 2;
            ctx.lineWidth = bw.bottom;
            ctx.beginPath();
            ctx.moveTo(x, y + h - half);
            ctx.lineTo(x + w, y + h - half);
            ctx.stroke();
        }

        // LEFT side
        if (bw.left > 0) {
            const half = bw.left / 2;
            ctx.lineWidth = bw.left;
            ctx.beginPath();
            ctx.moveTo(x + half, y);
            ctx.lineTo(x + half, y + h);
            ctx.stroke();
        }

        // RIGHT side
        if (bw.right > 0) {
            const half = bw.right / 2;
            ctx.lineWidth = bw.right;
            ctx.beginPath();
            ctx.moveTo(x + w - half, y);
            ctx.lineTo(x + w - half, y + h);
            ctx.stroke();
        }

        ctx.restore();
    },
    afterDatasetDraw(chart, args, options) {
        const {active} = options;

        // 4.1) Получаем контекст, метаданные
        const { ctx } = chart;
        const meta = chart.getDatasetMeta(args.index);
        const bar = meta.data[meta.data.length - 1]; // последний столбец
        if (!bar) return;

        // 4.2) Считаем "подпрыгивание"
        const offset = active ? this.getJumpOffset(Date.now()) : 0;

        // --- ВАЖНОЕ УСЛОВИЕ ---
        // Если offset = 0, значит бар "ушел вниз",
        // тогда мы НЕ дорисовываем пульсацию, а оставляем "как есть"
        if (offset === 0) {
            return;
        }

        // 4.3) Иначе (offset > 0) дорисовываем "поднятый" бар
        const { x, y, base, width } = bar.getProps(['x','y','base','width'], true);
        const newY = y - offset;         // сместим вверх
        const newHeight = base - newY;
        const drawX = x - width / 2;

        // 4.4) Собираем опции для заливки/границы
        const barOpts = {
            backgroundColor: bar.options.backgroundColor,
            borderColor: bar.options.borderColor,
            borderWidth: bar.options.borderWidth,
            borderAlign: bar.options.borderAlign || 'center'
        };

        // 4.5) Рисуем так, как делает Chart.js
        ctx.save();
        this.drawBarLikeChartJS(ctx, barOpts, drawX, newY, width, newHeight);
        ctx.restore();
    },
}