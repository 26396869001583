<template>
  <div class="v-bh-overlay-pending">
    Pending ...
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.v-bh-overlay-pending{
  @include flex(column,center,center);
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  background:$overlay-white;
  color:$dark-50;
  font:$font-14-semi;
}
</style>