<template>
  <div
      v-if="listErrors.length > 0"
      v-for="error in listErrors"
      class="invalid invalid-feedback"
      :class="mainClass"
      v-html="error"
  />
</template>

<script setup lang="js">
import {computed} from "vue";

const props = defineProps({
  errors: {},
  noMargin: {type:Boolean,default:false,},
});

const mainClass = computed(() => {
  return props.noMargin ? 'no-margin' : '';
});

const listErrors = computed(() => {
  if(!props.errors){
    return [];
  }else if(props.errors.constructor === String){
    return [props.errors];
  }else if(props.errors.constructor === Array){
    return props.errors;
  }
});
</script>

<style lang="scss" scoped>
.invalid.invalid-feedback{
  font-size:12px;
  color:#F2001D;
  line-height: 15px;
  margin-top:6px;
  &.no-margin{
    margin:0px;
  }
}
</style>