<template>
  <div class="v-sc-select-geo">
    <v-form-select-inline
        placeholder="Country"
        v-model="selectedCountry"
        :options="optionsCountries"
        @change="changeCountry"
        :has-empty-option="false"
        :disabled="disabled"
    />

    <v-form-select-inline
        v-if="showStateOptions"
        :placeholder="selectedState ? 'State Targeting' : 'Add State Targeting'"
        v-model="selectedState"
        :options="optionsStates"
        :has-empty-option="!defaultStateNoGeo"
        :disabled="disabled"
        name-not-selected="No State Targeting"
    />
  </div>
</template>

<script setup>

import VFormSelectInline from "@app-vue/components/VForm/VFormSelectInline.vue";
import {computed, watch} from "vue";
import {useStaticData} from "@app-vue/stores/useStaticData";
import {isObject, isString} from "lodash";
import {compareCountries, compareCountriesAndStates} from "@app-vue/utils/utils";

const props = defineProps({
  disabled:{type:Boolean,default:false,},
  defaultCountryNoGeo: {type:Boolean,default:false,},
  defaultCountryNotSelected:{default: {name: 'No Geo-targeting', value: 'no_geo'}},
  defaultStateNoGeo: {type:Boolean,default:false,},
  defaultStatesNotSelected:{default: {name: 'Not selected', value: 'no_state'}},
  /**
   * @type {Array<{ countryCode: string, stateCode?: string }>}
   */
  excludedGeo: {type:Array, default:[],},
});

const emit = defineEmits(['updateCountry']);

const selectedCountry = defineModel('country');
const selectedState = defineModel('state');

const optionsCountries = computed(() => {
  let countries;

  if(props.excludedGeo.length > 0){
    countries = useStaticData().regionsForSelect.filter((country) => {
      return !props.excludedGeo.some((excludedGeoItem) => {
        if(!!excludedGeoItem?.stateCode) return false;
        return compareCountries(excludedGeoItem?.countryCode, country.value);
      });
    });
  }else{
    countries = useStaticData().regionsForSelect;
  }

  if(props.defaultCountryNoGeo){
    return [props.defaultCountryNotSelected].concat(countries);
  }else{
    return countries;
  }
});
const optionsStates = computed(() => {
  let regionCode = selectedCountry.value?.value;
  if(!isString(regionCode)) return [];
  regionCode = regionCode.toLowerCase();

  let region = useStaticData().regions.find((region) => region.code.toLowerCase() === regionCode);
  if(!region || !isObject(region)) return [];

  let states = region?.states;
  if(!Array.isArray(states) || states.length === 0) return [];

  let statesResponse = states.sort((a, b) => {
    if (a.sort_order !== b.sort_order) {
      return a.sort_order - b.sort_order;
    }else{
      return a.name.localeCompare(b.name);
    }
  }).map((item) => {
    return {
      name: item.name,
      value: item.code,
    }
  });

  if(props.defaultStateNoGeo){
    statesResponse = [props.defaultStatesNotSelected].concat(statesResponse);
  }

  if(props.excludedGeo.length > 0){
    return statesResponse.filter((stateItem) => {
      return !props.excludedGeo.some((excludedGeoItem) => {
        return compareCountriesAndStates(excludedGeoItem?.countryCode, stateItem.value, region.code, excludedGeoItem?.stateCode);
      });
    })
  }else{
    return statesResponse;
  }

})

const showStateOptions = computed(() => optionsStates.value?.length > 0);

watch(() => [showStateOptions.value, selectedCountry.value], () => {
  if(props.defaultStateNoGeo){
    selectedState.value = props.defaultStatesNotSelected;
  }else{
    selectedState.value = null
  }
}, {immediate: true,});

function changeCountry(option){
  emit('updateCountry',option);
}

</script>

<style lang="scss" scoped>
.v-sc-select-geo{
  @include flex(column,start,stretch,6px);
}
</style>