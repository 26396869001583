<template>
  <teleport to="body">
    <transition name="fade" mode="out-in">
      <div class="v-tooltip" ref="tooltip" :style="styleComputed" v-bind="$attrs" :class="mainClass" v-show="dataShow">

        <slot name="content"/>

        <div class="v-tooltip__close"
             ref="close"
             @click="hide()"
             v-if="btnCloseStatus">
          <v-icon-svg name="icon-tooltip-close" class="v-tooltip__close-icon"/>
        </div>

        <div v-if="hasArrow"
             data-popper-arrow
             :style="styleArrowComputed"
             :data-box-shadow-none="boxShadow === 'none' || resetTooltip"/>

      </div>
    </transition>
  </teleport>
</template>

<script>
import VIconSvg from '@app-vue/components/Base/VIconSvg.vue';
import {createPopper} from "@popperjs/core";
import maxSize from "popper-max-size-modifier";
import {computed} from "vue";

export default {
  data: () => {
    return {
      dataShow:false,
      mutationObserver: null,
      blockTrigger: null,
    }
  },
  components:{VIconSvg},
  props:{
    /**
     * @type {function(): HTMLElement|Ref<HTMLElement>}
     */
    getLinkedBlock: {type:Function, default:{}},
    /**
     *  @type {'auto'|'auto-start'|'auto-end'|'top'|'top-start'|'top-end'|'bottom'|'bottom-start'|'bottom-end'|'right'|'right-start'|'right-end'|'left'|'left-start'|'left-end'}
     */
    placement: {type:String, default: 'left'},
    btnCloseOn: {type: Boolean, default:null},
    btnCloseOff: {type: Boolean, default:null},
    hasArrow: {type: Boolean, default:false},
    hideClickOutside: {type: Boolean, default:true},
    hideClickInnerTooltip: {type: Boolean, default:true,},
    offset: {default: [0,8,]},
    maxHeightVisibleArea: {type:Boolean,default:false},

    /**
     * Обнуляет стили контейнера для tooltip, перекладывая всю стилистику на внутреннюю компоненту
     */
    resetTooltip: {type:Boolean,default:false},

    style:{type:Object, default:{}},
    background: {default: '#fff',},
    boxShadow: {default: '#fff',},
    followLinkedBlock: {type: Boolean, default: false},
    flipEnabled: {type: Boolean, default:true,},
  },
  computed: {
    mainClass: (state) => {
      let classes = [];

      if(state.hasArrow){classes.push('has-arrow')}
      if(state.maxHeightVisibleArea){classes.push('has-overflow')}
      if(state.resetTooltip){classes.push('is-reset-tooltip')}

      return classes;
    },
    styleComputed: (state) => {
      if(state.resetTooltip) return {};
      return {...state.style, ...{background:state.background}};
    },
    styleArrowComputed: (state) => {
      if(state.resetTooltip) return {}
      return {background:state.background}
    },
    btnCloseStatus: (state) => {
      if(state.btnCloseOn === null && state.btnCloseOff === null){
        return true;
      }else if(state.btnCloseOn !== null){
        return state.btnCloseOn;
      }else if(state.btnCloseOff !== null){
        return !state.btnCloseOff;
      }
    },
  },
  methods: {
    initTooltip(){

      const modifiers = [
        {
          name: 'offset',
          options: {
            offset: this.offset,
          },
        },
      ];

      if(!this.flipEnabled){
        modifiers.push({
          name: 'flip',
          options: {
            fallbackPlacements: [],
            enabled: false,
          },
        });
      }

      if(this.maxHeightVisibleArea){
        const applyMaxSize = {
          name: "applyMaxSize",
          enabled: true,
          phase: "beforeWrite",
          requires: ["maxSize"],
          fn({ state }) {
            let { width, height } = state.modifiersData.maxSize;
            height = height-20;
            state.styles.popper.maxHeight = `${height}px`;
          }
        }
        modifiers.push(maxSize);
        modifiers.push(applyMaxSize);
      }

      this.popperInstance = createPopper(
          this.getLinkedBlock()?.$el || this.getLinkedBlock(),
          this.$refs.tooltip,
          {
            placement: this.placement,
            modifiers: modifiers,
          }
      );
      this.$store.commit('popperInstances/add',this.popperInstance);

      if(this.followLinkedBlock){
        this.mutationObserver = new MutationObserver(mutations => {
          mutations.forEach(() => this.popperInstance?.update());
        });
        this.mutationObserver.observe(this.getLinkedBlock()?.$el || this.getLinkedBlock(),{attributes:true});
      }

    },
    handleOutsideClick(event){
      if (
          this.$refs.tooltip
          && !this.$refs.tooltip.contains(event.target)
          && this.hideClickOutside
          //если hideClickInnerTooltip = true, и текущий элемент лежит в v-tooltip, то не закрывать
          && !(event.target.closest('.v-tooltip') && !this.hideClickInnerTooltip)
      ) {
        this.hide();
      }
    },
    show(){

      this.hideAll(this.popperInstance);
      if(!this.popperInstance.state.elements.reference || !this.popperInstance.state.elements?.popper){
        return;
      }
      this.dataShow = true;
      //this.popperInstance.state.elements.popper.setAttribute('data-show', '');
      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          {name: 'eventListeners', enabled: true},
        ],
      }));

      this.popperInstance.update();
      setTimeout(() => {
        this.popperInstance.update();
      },20);
      setTimeout(() => {
        document.addEventListener('click', this.handleOutsideClick);
      },20);

      this.$emit('eventShow', []);

    },
    hideAll(exceptPopperInstance = null){
      this.$store.state.popperInstances.list.forEach((popperInstance) => {
        if(exceptPopperInstance === popperInstance){
          this.hide(popperInstance);
        }
      });
    },
    hide(popperInstance = null){

      if(this.blockTrigger){return}
      if(!popperInstance) popperInstance = this.popperInstance;
      if(!popperInstance.state.elements.reference || !popperInstance.state.elements.popper) return;

      this.dataShow = false;
      //popperInstance.state.elements.popper.removeAttribute('data-show');
      // Disable the event listeners
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: false },
        ],
      }));

      document.removeEventListener('click', this.handleOutsideClick);

      this.$emit('eventHide', []);

    },
    toggle(){
      if(this.dataShow){
        this.hide();
      }else{
        this.show();
      }
    },
    showAndBlock(){
      this.blockTrigger = true;
      this.show();
    },
    removeBlock(){
      this.blockTrigger = false;
    }
  },
  mounted() {
    this.initTooltip();
  },
  unmounted() {
    this.mutationObserver?.disconnect();
  },
  provide(){
    return {
      tooltipShow: this.show,
      tooltipHideAll: this.hideAll,
      tooltipHide: this.hide,
      tooltipToggle: this.toggle,
      tooltipShowAndBlock: this.showAndBlock,
      tooltipRemoveBlock: this.removeBlock,
      tooltipDataShow: computed(() => this.dataShow),
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tooltip {
  color: #1f293b;
  padding: 14px;
  width: 100%;
  box-shadow: $box-shadow-tooltip;
  z-index:99999;

  &.is-reset-tooltip{
    padding:initial;
    width:initial;
    box-shadow:initial;
    color:initial;
  }

  &.has-overflow{
    overflow-y: auto;
  }

  .v-tooltip__close{
    position:absolute;
    top:10px;
    right:10px;
    opacity:0.5;
    cursor:pointer;
    .v-tooltip__close-icon{
      display:block;
      width:12px;
      height:12px;
    }
    &:hover{
      opacity:0.7;
    }
  }

  :deep(div[data-popper-arrow]){

    z-index:-1;

    &:before{
      content: '';
      position: absolute;
      width:10px;
      height:10px;
      background:inherit;
      transform:rotate(45deg);
      box-shadow: $box-shadow-tooltip;
    }

    &[data-box-shadow-none="true"]:before{
      box-shadow: none;
    }

    &:after{
      content:'';
      position: absolute;
      background:inherit;
    }

  }

  &[data-popper-placement^="top"]{
    :deep(div[data-popper-arrow]){
      bottom:0px;
      &:before{
        bottom:-4px;
        left:-4.4px;
      }
      &:after{
        bottom:0px;
        left:-13px;
        width:26px;
        height:20px;
      }
    }
  }
  &[data-popper-placement^="bottom"]{
    :deep(div[data-popper-arrow]){
      top:0px;
      left: 0px;
      &:before{
        top:-4px;
        left:-4.4px;
      }
      &:after{
        top:0px;
        left:-13px;
        width:26px;
        height:20px;
      }
    }
  }
  &[data-popper-placement^="left"]{
    :deep(div[data-popper-arrow]){
      right:0px;
      &:before{
        right:-4px;
        top:-5px;
      }
      &:after{
        top:-13px;
        right:0px;
        width:20px;
        height:26px;
      }
    }
  }
  &[data-popper-placement^="right"]{
    :deep(div[data-popper-arrow]){
      left:0px;
      &:before{
        left:-4px;
        top:-5px;
      }
      &:after{
        top:-13px;
        left:0px;
        width:20px;
        height:26px;
      }
    }
  }

  &[data-show] {
  }

}
.fade-enter-from{
  opacity:0;
  display:block;
}
.fade-enter-active{
  transition: opacity 0.2s;
  display:block;
}
.fade-enter-to{
  opacity:1;
  display:block
}
.fade-leave-from{
  display:block;
}
.fade-leave-active{
  transition: opacity 0.2s;
  display:block;
}
.fade-leave-to{
  opacity:0;
  display:block;
}
</style>