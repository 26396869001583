<template>
  <div class="v-bh-overlay-declined">

    <template v-if="typeTemplate === 'default'">
      <div class="title">Payment Declined</div>
      <v-button class="button"
                text="Retry"
                icon="icon-retry"
                :loading="loading"
                @click="handlerClickRetry"
                color="green"/>
    </template>

    <template v-else-if="typeTemplate === 'declinedAfterRetry'">
      <div class="error-message">
        <div class="title">Payment Declined <v-link :href="payment.linkForRenew">Submit Again</v-link></div>
      </div>
    </template>

    <template v-else-if="typeTemplate === 'succeedAfterRetry'">
      <div class="success-message">Payment Succeeded</div>
    </template>

  </div>
</template>

<script setup>

import VButton from "@app-vue/components/Base/VButton.vue";
import {useBillingHistory, useBillingHistoryPayment} from "@app-vue/stores/useBillingHistory";
import {ref} from "vue";
import {delay} from "@app-vue/utils/utils";
import VLink from "@app-vue/components/Base/VLink.vue";

const props = defineProps({
  paymentId: {},
});

const loading = ref(false)

let payment = useBillingHistoryPayment(props.paymentId);

/** @type {import('vue').Ref<'default'|'declinedAfterRetry'|'succeedAfterRetry'>} */
const typeTemplate = ref('default');

function setDeclined(){
  typeTemplate.value = 'declinedAfterRetry';
}

function setSucceed(){
  typeTemplate.value = 'succeedAfterRetry';
}

const checkingStatusNumberAttempts = ref(0);
async function checkStatusOrder(orderId){
  checkingStatusNumberAttempts.value++;
  if(checkingStatusNumberAttempts.value > 12) return ['declined', null];

  let order = await useBillingHistory().getHistoryByOrderId(orderId);

  if(order && order.paymentStatus !== 'pending'){
    return [order.paymentStatus, order];
  }else{
    await delay(2000);
    return checkStatusOrder(orderId);
  }

}

async function handlerClickRetry(){

  loading.value = true;
  if(payment.isAjaxRetryable){

    let orderId = await payment.retryPayment();

    if(!orderId){
      setDeclined();
      loading.value = false;
      return;
    }
    checkingStatusNumberAttempts.value = 0;
    let [statusOrder, order] = await checkStatusOrder(orderId);

    if(statusOrder === 'paid'){
      setSucceed();
      useBillingHistory().addPayment(order);
      loading.value = false;
    }else{
      setDeclined();
      loading.value = false;
    }

  }else{
    await payment.collectShoppingCartAndRedirect();
    loading.value = false;
  }
}

</script>

<style lang="scss" scoped>
.v-bh-overlay-declined{
  @include flex(column,center,center,7px);
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  background:$overlay-red;
  .title{
    font:$font-14-semi;
    color:#fff;
  }
  .button{
    gap:8px;
    padding:9px 17px;
  }
}

.error-message{
  background:$white-100;
  border-radius:4px;
  padding:15px;
  .title{
    color:$dark-100;
  }
}
.success-message{
  background:#219E26;
  color:#fff;
  padding:15px;
  border-radius:4px;
  font-weight: 600;
}
</style>