import {defineStore} from "pinia";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {useRecsStore, useRecsStoreRating} from "@app-vue/stores/useRecsStore";
import {useBacklinkOptions, useStaticData, useUserBalance} from "@app-vue/stores/useStaticData";
import {useRoutes} from "@app-vue/stores/useRoutes";
import {
    CREDIT_TYPE_BACKLINK,
    CREDIT_TYPE_BACKLINK_V2,
    CREDIT_TYPE_SEO,
    useBillingCart,
    useCouponDiscount,
    useCreditTypes,
} from '@app-vue/stores/useBilling';
import {UrlManipulator} from "@app-vue/utils/UrlManipulator";
import {getGradientColor} from "@app-vue/utils/chartUtils";
import {useServerTime} from "@app-vue/stores/useUtils";
import {capitalize, forEachRight, groupBy} from "lodash";
import {
    formatDateToYYYYMMDD,
    getRandomString,
    groupUrlsByDomain,
    initStore,
    isValidDate,
    openAuthPopup,
    pluralize
} from "@app-vue/utils/utils";
import {storeErrors} from "@app-vue/utils/storeErros";

export const useLinkBuildProjects = defineStore('lb-projects', {
    state: () => {
        return {
            list: [],
            filterOptionsUrls: [],
            filterOptionsKeywords: [],
            countAllProjects: null,
        }
    },
    getters: {
        filterOptionsDomains: (state) => {
            return groupUrlsByDomain(state.filterOptionsUrls);
        },
    },
    actions:{
        async download(options ={}){

            let resource = new ApiResource({
                url: '/api/v1/backlinks-project',
                params: {filters:options.filters},
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state.list = [];
                    resource.data.projects.forEach((item) => {
                        let linkBuildProject = useLinkBuildProject(item.uniquekey);
                        linkBuildProject.init(item);
                        state.list.push(linkBuildProject);
                    });
                    state.filterOptionsUrls = [...new Set(resource.data.filterOptionsUrls)];
                    state.filterOptionsKeywords = [...new Set(resource.data.filterOptionsKeywords)];
                    state.countAllProjects = resource.data.countAllProjects;
                })
            }else {
                this.$patch({list: []});
            }

        },
        findProjectById(id){

            return this.list.find(item => item.id === id) ?? null;

        },
        async downloadProjectsOrders(dateFrom,dateTo){
            let dateToForServer = new Date(dateTo);
            dateToForServer.setDate(dateToForServer.getDate() + 1);
            let resource = new ApiResource({
                url: '/api/v1/backlinks-project-chart-data',
                method: 'get',
                params: {
                    from: formatDateToYYYYMMDD(dateFrom),
                    to: formatDateToYYYYMMDD(dateToForServer),
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                let groups = groupBy(resource.data, 'project_id');
                Object.entries(groups).forEach(([key,data]) => {
                    let project = this.findProjectById(parseInt(key));
                    if(project){
                        useLinkBuildProjectOrders(project.uniquekey).init(data);
                    }
                });
                return [true];
            }else {
                return [false, resource.errorMessage];
            }
        },
        getFilterSettings() {
            return (window.preloadData["/api/v1/backlinks-project"]?.data?.filterSettings ?? []);
        },
    }
});

export const useLinkBuildProject = (uniquekey = null) => defineStore('lb-project-'+uniquekey, {
    state: () => {
        return {
            uniquekey: uniquekey,
            id: null,
            url:null,
            name:null,
            is_active:null,
            owner_comments: null,
            keyword: null,
            errors: storeErrors.getDefaultStateErrors(['create']),
            /**
             * наличие get параметра после запуска функции updateTestAnalytics
             */
            hasTestAnalytics: false,
            category_id: null,
            next_renewal_days: null,
            autoRenewalInfo: {
                next_renewal_days: null,
            },
            ordersInfo: {
                new: null,
                in_progress: null,
                delivered: null,
                canceled:null,
            },
            is_deleted: null,
            max_weight: null,
            optimization_rating: null,
            recommendation_status: null,
            createdAt: null,

            /**
             * Demo vars
             */
            is_demo:null,
            /**
             * @type {'writing'|'publishing'|'delivered'|'expired'}
             */
            demo_status:null,
            avg_time:null,
            max_time:null,
            total_avg_time:null,
            total_max_time:null,
            max_time_writing:null,
            max_time_publishing:null,
            recommendations_danger_count: null,
            recommendations_succss_count: null,
            recommendations_warning_count: null,
            _insufficientBalance:false,
            /**
             * @type {'start'|'create-order'|'finalize-order'}
             */
            order_creation_status:'finalize-order',
        }
    },
    getters: {
        shortUrl: (state) => {
          return new UrlManipulator(state.url).withOutWww().withOutProtocol().removeTrailingSlashes().getUrl();
        },
        hasAnalytics: (state) => {
            return useRecsStore(state.uniquekey).countRecs > 0 || state.hasTestAnalytics;
        },
        category: (state) => {
            if(state.category_id === null){
                return null;
            }else{
                let response =  useStaticData().backlinksCategories.find((category) => category.id === state.category_id);
                if(response){
                    return response;
                }else{
                    return null;
                }
            }
        },
        categoryForSelect: (state) => {
            if(state.category_id === null){
                return null;
            }else{
                let response =  useStaticData().backlinksCategoriesForSelect.find((category) => category.value === state.category_id);
                if(response){
                    return response;
                }else{
                    return null;
                }
            }
        },
        countNewOrders: (state) => {
            return (state.ordersInfo.new!==null) ? state.ordersInfo.new : 0;
        },
        countInProgressOrders: (state) => {
            return (state.ordersInfo.in_progress!==null) ? state.ordersInfo.in_progress : 0;
        },
        countDeliveredOrders: (state) => {
            return (state.ordersInfo.delivered!==null) ? state.ordersInfo.delivered : 0;
        },
        countCanceledOrders: (state) => {
            return (state.ordersInfo.canceled!==null) ? state.ordersInfo.canceled : 0;
        },
        countAllOrders(state){
          return this.countNewOrders + this.countInProgressOrders + this.countDeliveredOrders + this.countCanceledOrders;
        },
        enableAutorenewals: (state) => {
            return state.autoRenewalInfo.next_renewal_days !== null;
        },
        numberDaysNearAutorenewals: (state) => {
            return state.enableAutorenewals ? state.autoRenewalInfo.next_renewal_days : 0;
        },
        link: (state) => {
            return useRoutes().url('backlinks-project.get', {uniquekey: state.uniquekey})
        },
        domain(state) {
            if(state.url===null) return null;
            return this.extractDomainFromUrl(state.url);
        },
        correctUrlLink: (state) => {
            if(!state.url)return '';
            if (!state.url.startsWith('http://') && !state.url.startsWith('https://')) {
                return 'http://' + state.url;
            }
            return state.url;
        },
        optimizationRatingPercent: (state) => {
            return useRecsStoreRating().getOptimizationRatingPercent(
                state.recommendation_status,
                state.optimization_rating,
                state.max_weight,
            );
        },
        optimizationRatingType(){
            return useRecsStoreRating().getOptimizationRatingType(
                this.optimizationRatingPercent
            );
        },
        createdAtDate: (state) => {
            let date = new Date(state.createdAt);
            return isValidDate(date) ? date : null;
        },
        createdDateStringISO(){
            return this.createdAtDate ? this.createdAtDate.toISOString().split('T')[0] : null;
        },
        isDisabled: (state) => {
            return state.is_deleted || !state.is_active;
        },

        /** DEMO **/

        demoOrder: (state) => {
            if(!state.is_demo) return null;

            return useLinkBuildProjectOrders(state.uniquekey).ordersManager().ordersSortedByCreatedAt.at(0) ?? null;
        },
        demoStatusName: (state) => {
            if(!state.is_demo) return null;

            return capitalize(state.demo_status);
        },

        demoCreatedAgo(state){
            if(!state.is_demo) return null;
            if(useServerTime().realTimeServer === null) return null

            return useServerTime().getDiffInSeconds(useServerTime().realTimeServer, this.demoOrder?.created_at);
        },
        demoWrittenAgo(state){
            if(!state.is_demo) return null;
            if(useServerTime().realTimeServer === null) return null;
            if(this.demoOrder?.demo_status_update_time === null) return null;

            return useServerTime().getDiffInSeconds(useServerTime().realTimeServer, this.demoOrder?.demo_status_update_time);
        },

        demoLeftTimeSeconds(state){
            if(!state.is_demo) return null;
            if(state.demo_status === 'writing' && this.demoCreatedAgo===null) return state.total_max_time;
            if(state.demo_status === 'publishing' && state.demoWrittenAgo===null) return state.max_time_publishing*60;

            switch(state.demo_status){
                case 'writing' : return Math.max(state.total_max_time*60 - this.demoCreatedAgo, state.max_time_publishing*60 + 1);
                case 'publishing' : return Math.max(state.max_time_publishing*60 - this.demoWrittenAgo, 1);
                default: return null;
            }
        },
        demoLeftTimeMinutes(state){
            if(!state.is_demo) return null;
            if(this.demoLeftTimeSeconds === null) return null;
            return Math.ceil(this.demoLeftTimeSeconds / 60);
        },
        demoLeftTimeCurrentStageSeconds(state){
            if(!state.is_demo) return null;
            if(this.demoLeftTimeSeconds === null) return null;

            switch(state.demo_status){
                case 'writing' : return Math.min(this.demoLeftTimeSeconds - state.max_time_publishing*60 + 1, state.max_time_writing*60);
                case 'publishing' : return Math.min(this.demoLeftTimeSeconds + 1, state.max_time_publishing*60);
                default: return 0;
            }
        },
        demoLeftTimeCurrentStageMinutes(state){
            if(!state.is_demo) return null;
            if(this.demoLeftTimeSeconds === null) return null;
            return Math.ceil(this.demoLeftTimeCurrentStageSeconds / 60);
        },
        demoReadinessPercent(state){
            if(!state.is_demo) return null;
            if(state.demo_status === 'delivered') return 100;
            if(this.demoLeftTimeSeconds === null) return null;

            return (state.total_max_time*60 - this.demoLeftTimeSeconds) / (state.total_max_time*60) * 100;
        },

        demoOrderHasLink(state){
            return !!this.demoOrder?.result_url;
        },
        demoOrderResultUrl(state){
            return this.demoOrder?.result_url
        },
        demoOrderResultUrlShort(state){
            return this.demoOrder?.resultUrlShort
        },

        insufficientBalance: (state) => {
            return state._insufficientBalance;
        },

        orderCreationStatusIsStart: (state) => state.order_creation_status === 'start',
        orderCreationStatusIsCreateOrder: (state) => state.order_creation_status === 'create-order',
        orderCreationStatusIsFinalizeOrder: (state) => state.order_creation_status === 'finalize-order',

    },
    actions: {

        updateTestAnalytics(){
            const urlParams = new URLSearchParams(window.location.search);
            this.$patch({hasTestAnalytics: urlParams.get('recom') === 'true'});
        },

        validateKeyword(keyword){
            if(typeof keyword === 'string'){
                if(/[,\n]/.test(keyword)) {
                    return 'Please use only one keyword per project';
                }
                if(keyword.length > 0 && keyword.length > 32){
                    return 'Keyword should not exceed 32 characters';
                }
            }
            return null;
        },

        async create(url, keyword, categoryId, comment, is_demo = false){

            let params = {
                url: url,
                keyword: keyword,
                category_id: categoryId,
                is_demo: is_demo ? 1 : 0,
            }
            if(comment?.length > 0) {
                params.owner_comments = comment
            }

            let validateKeyword = this.validateKeyword(params.keyword);

            if(validateKeyword !== null){
                storeErrors.setErrorField(this,'create','keyword',validateKeyword);
                storeErrors.setErrorMessage(this,'create',validateKeyword);
                return [false];
            }else{
                storeErrors.removeErrorField(this,'create','keyword');
                storeErrors.removeErrorMessage(this,'create');
            }

            let resource = new ApiResource({
                url: '/api/v1/backlinks-project',
                method: 'post',
                params: params
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                storeErrors.clean(this,'create');
                return [true,resource.data];
            }else{
                if(resource.errorAccessDeniedForDemoUser){openAuthPopup();}
                storeErrors.resourceErrors(this,'create', resource);
                return [false];
            }

        },

        async downloadProject(){
            let resource = new ApiResource({url: '/api/v1/backlinks-project/'+this.uniquekey});
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.init(resource.data);
                return [true, resource.data];
            }else {
                return [false, resource.errorMessage];
            }
        },

        init(data){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(key === 'auto_renewal_info'){
                        state.autoRenewalInfo.next_renewal_days = data?.auto_renewal_info?.next_renewal_days
                    }else if(key === 'ordersInfo'){

                        if('new' in data.ordersInfo) state.ordersInfo.new = data.ordersInfo['new'];
                        else state.ordersInfo.new = 0;

                        if('in progress' in data.ordersInfo) state.ordersInfo.in_progress = data.ordersInfo['in progress'];
                        else state.ordersInfo.in_progress = 0;

                        if('delivered' in data.ordersInfo) state.ordersInfo.new = data.ordersInfo['delivered'];
                        else state.ordersInfo.delivered = 0;

                        if('canceled' in data.ordersInfo) state.ordersInfo.canceled = data.ordersInfo['canceled'];
                        else state.ordersInfo.delivered = 0;

                    }else if(key === 'created_at'){
                        state.createdAt = value;
                    }else if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                });
            });

        },

        async saveInfoProject(params, projectUniquekey = null){

            let resource = new ApiResource({
                url:'/api/v1/backlinks-project/'+ (projectUniquekey ?? this.uniquekey),
                method:'put',
                data: params
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    Object.keys(params).forEach((key) => {
                        if(key === 'comment'){
                            state['owner_comments'] = params['comment']
                        }else if(key === 'categoryId'){
                            state['category_id'] = params['categoryId']
                        }else{
                            state[key] = params[key]
                        }
                    });
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },

        async saveUrl(url, projectUniquekey = null){
            return await this.saveInfoProject({url:url},projectUniquekey);
        },

        async saveKeyword(keyword, projectUniquekey = null){
            let validate = this.validateKeyword(keyword);
            if(validate!== null){
                return [false, validate];
            }
            return await this.saveInfoProject({keyword:keyword},projectUniquekey);
        },

        async saveComment(comment, projectUniquekey = null){
            if(comment?.length === 0){
                comment = null;
            }
            return await this.saveInfoProject({owner_comments:comment},projectUniquekey);
        },

        async saveName(name, projectUniquekey = null){
            return await this.saveInfoProject({name:name},projectUniquekey);
        },

        async saveIsActive(is_active, projectUniquekey = null){
            return await this.saveInfoProject({is_active:is_active},projectUniquekey);
        },

        async saveCategory(category_id, projectUniquekey = null){
            return await this.saveInfoProject({categoryId:category_id},projectUniquekey);
        },

        async saveIsDeleted(isDeleted, projectUniquekey = null){
            return await this.saveInfoProject({is_deleted:isDeleted},projectUniquekey);
        },

        async retryDemo(projectUniquekey = null){
            let resource = new ApiResource({
                url:'/api/v1/backlinks-project/'+ (projectUniquekey ?? this.uniquekey) + '/demo-order',
                method:'post',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },

        extractDomainFromUrl(url) {

            const domainPattern = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;

            const match = url.match(domainPattern);
            if (match && match[1]) {
                return match[1];
            }

        },

        async convertDemoToReal(){
            let resource = new ApiResource({
                url: `/api/v1/backlinks-project/${this.uniquekey}/convert-demo`,
                method: 'PUT',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                return [true];
            }else{
                return [false, resource.errorMessage];
            }
        },

        orderCreationStatusSetStart(){
            this.$patch({order_creation_status: 'start'});
            return this.saveInfoProject({order_creation_status:'start'});
        },

        orderCreationStatusSetCreateOrder(){
            this.$patch({order_creation_status: 'create-order'});
            this.saveInfoProject({order_creation_status:'create-order'});
        },

        orderCreationStatusSetFinalizeOrder(){
            this.$patch({order_creation_status: 'finalize-order'});
            this.saveInfoProject({order_creation_status:'finalize-order'});
        },

    }
})()

export const useLinkBuildCart = (uniquekey) => defineStore(`lb-project-cart-${uniquekey}`,{
    state: () => {
        return {
            uniquekey: uniquekey,
            items: [],
            errors: {
                check: {
                    message: null,
                },
                finalize: {
                    message: null,
                }
            },
            packageQuantity: null,
        }
    },
    getters: {
        credits: (state) => {
            return state.items.reduce((sum,item) => sum+=item.credits,0);
        },
        planOption: (state) => {
            return useCreditTypes().creditTypesBacklinkV2?.firstPlanOptions
        },
        priceForPackageQuantity: function(state){
            return this.planOption?.priceForQuantity(state.packageQuantity);
        },
        discountPrice: function(state){
            let price = this.priceForPackageQuantity;
            if (price > 0 && this.vatRate > 0) {
                price = price + (price / this.vatRate);
            }

            return price / 100 * useCouponDiscount().discount;
        },
        finalAmount: function(state){
            let totalAmount = this.priceForPackageQuantity;

            if (totalAmount > 0 && this.vatRate > 0) {
                totalAmount = totalAmount + (totalAmount * (this.vatRate / 100));
            }

            totalAmount = totalAmount - this.discountPrice;

            return totalAmount;
        },
        countWithServerId: function(state){
            return this.items.reduce((sum, item) => sum + (item.hasServerId ? 1 : 0), 0);
        },
        vatRate: function(state){
            return useBillingCart().vatRate;
        },
    },
    actions: {
        /**
         * Загружаем с сервера информацию о корзине для проекта
         * Если корзина пустая, то добавляем один пустой элемент
         * локальный items не пустой, то значения в items будут обновлены в соотвествии с серверным id
         */
        async update(data = null){

            let resource = new ApiResource({
                'url': `/api/v1/backlinks-project/${uniquekey}/cart`,
                'method': 'get',
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                let downloadedItems = resource.data.items;

                //удалить локальные cartItem с id!=null, которых нет в downloadedItems
                this.$patch((state) => {
                    forEachRight(this.items, (item, index) => {
                        if(item.id !== null){
                            if(!downloadedItems.find((downloadedItem) => downloadedItem.id == item.id)){
                                state.items.splice(index,1);
                            }
                        }
                    });
                });

                if(downloadedItems.length > 0){
                    this.$patch((state) => {
                        downloadedItems.forEach((downloadedItem) => {
                            let cartItem = this.getCartItemById(downloadedItem.id);
                            if(cartItem === false){
                                state.items.push(useLinkBuildCartItem(state.uniquekey, getRandomString(10)).init(downloadedItem));
                            }else{
                                cartItem.init(downloadedItem);
                            }
                        });
                    });
                }
            }

        },
        getCartItemById(id){
            return this.items.find((item) =>  item.id == id) ?? false;
        },
        addItemEmpty(){
            this.$patch((state) => {
                state.items.push(useLinkBuildCartItem(state.uniquekey, getRandomString(10)).init());
            });
        },
        async addItemWithDefault(){
            await this.addItem(useLinkBuildProject(this.uniquekey).url, useLinkBuildProject(this.uniquekey).keyword);
        },
        async addItem(website, keyword){
            let item = useLinkBuildCartItem(this.uniquekey, getRandomString(10)).init();
            this.$patch((state) => {
                item.$patch({
                    website: website,
                    keyword: keyword,
                });
                state.items.push(item);
            });
            await item.save();
        },
        deleteCartItemById(id){
            let index = this.items.findIndex((item) => item.id == id);
            if(index !== -1) this.items[index].delete();
            this.$patch((state) => {
                state.items.splice(index,1);
            });
        },
        async saveChanges(){
            let requests = [];
            this.items.forEach((item) => {
                if(item.hasChanges){
                    requests.push(item.save())
                }
            });
            await Promise.all(requests);
        },
        check(){
            let errors = false;
            this.items.forEach((item) => {
                if(!item.check()){
                    errors = true;
                }
            });
            if(errors){
                this.$patch((state) => state.errors.check.message = 'Please correct the errors.');
            }else{
                this.$patch((state) => state.errors.check.message = null);
            }
            return !errors;
        },
        async finalize(){
            let resource = new ApiResource({
                url: `/api/v1/backlinks-project/${this.uniquekey}/cart/pay`,
                method:'post',
            });
            let [status,response] = await resource.downloadAsync();
            if(!status){
                this.$patch((state) => {
                    state.errors.finalize.message = resource.errorMessage;
                });
            }else{
                this.$patch((state) => {
                    state.errors.finalize.message = null;
                });
            }
            return [status,response];
        },
        async deleteAll(){
            let batchSize = 5;
            for(let i = 0; i < this.items.length; i += batchSize){
                const batch = this.items.slice(i, i + batchSize);
                await Promise.all(batch.map(item => item.delete()));
            }
            this.$patch((state) => {
                state.items.splice(0,state.items.length);
            });
        },
        deleteEmptyItems: function(){
            for(let i = this.items.length - 1; i >= 0; i--){
                let item = this.items[i];
                if(!item.hasServerId && !item.website?.trim() && !item.keyword?.trim()){
                    this.$patch((state) => {
                        state.items.splice(i,1);
                    });
                }
            }
        },

        async goToEditingCart(){
            useLinkBuildProject(this.uniquekey).orderCreationStatusSetCreateOrder();
            if(this.countWithServerId === 0){
                this.deleteEmptyItems();
                await this.addItemWithDefault();
            }
        },

        async cleanAndGoToEditing(){
            await this.deleteAll();
            this.goToEditingCart();
        },

        async saveCartAndNExtFinalize(){
            await this.saveChanges();
            await this.update();
            if(this.check()){
                useLinkBuildProject(this.uniquekey).orderCreationStatusSetFinalizeOrder();
            }
        },

        async finalizeAndUpdateCart(){

            let [status] = await this.finalize();
            if(status){
                await Promise.all([
                    useUserBalance().download(),
                    useLinkBuildProjectOrders(this.uniquekey).download(),
                    this.update()
                ]);
                useLinkBuildProject(this.uniquekey).orderCreationStatusSetStart();
            }

        },

    },
})();

export const useLinkBuildCartItem = (projectUniquekey, itemLocalKey) => defineStore(`lb-project-cart-${projectUniquekey}-item-${itemLocalKey}`, {
    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            itemLocalKey: itemLocalKey,
            id: null,
            website: null,
            keyword: null,
            option_id: null, //берется из /api/v1/backlinks-project-options
            originalData: {
                id:null,
                website:null,
                keyword: null,
                option_id: null,
            },
            errors: {
                website: null,
                keyword: null,
            }
        }
    },
    getters: {
        hasChanges: (state) => {
            return Object.keys(state.originalData).some((key) => {
                return state.originalData[key] !== state[key]
            });
        },
        credits: (state) => {
            return useBacklinkOptions().getById(state.option_id).credits;
        },
        hasServerId: (state) => {
          return state.id !== null;
        },
    },
    actions: {
        init(data = {}){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(state.hasOwnProperty(key)){
                        state[key] = value;
                        state.originalData[key] = value;
                    }
                });
                if(state.option_id === null && useBacklinkOptions().options[0]?.id){
                    state.option_id = useBacklinkOptions().options[0].id;
                    state.originalData.option_id = useBacklinkOptions().options[0].id;
                }
            });
            return this;
        },
        async save(){
            let params = {};
            if(!this.hasServerId){
                params.url = `/api/v1/backlinks-project/${this.projectUniquekey}/cart`;
                params.method = 'post';
            }else{
                params.url = `/api/v1/backlinks-project/${this.projectUniquekey}/cart/${this.id}`;
                params.method = 'put';
            }
            params.data = {
                website: this.website,
                keyword: this.keyword,
                option_id: this.option_id,
            }
            let resource = new ApiResource(params);
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state.originalData.website = params.data.website;
                    state.originalData.keyword = params.data.keyword;
                    state.originalData.option_id = params.data.option_id;
                    if(params.method === 'post'){
                        state.originalData.id = resource.data.id;
                        state.id = resource.data.id;
                    }
                });
                return [true];
            }else{
                return [false, resource.errorMessage];
            }
        },
        async delete() {
            if(this.id !== null){
                let resource = new ApiResource({
                    url: `/api/v1/backlinks-project/${this.projectUniquekey}/cart/${this.id}`,
                    method: 'delete',
                });
                await resource.downloadAsync();
                if(resource.isSuccess()){
                    return [true];
                }else{
                    return [false, resource.errorMessage];
                }
            }
            return [true];
        },
        check() {
            let errors = false;

            const checkEmpty = (fieldName, errorMessage) => {
                if (this[fieldName]?.trim()) {
                    this.$patch((state) => state.errors[fieldName] = null);
                    return true;
                } else {
                    this.$patch((state) => state.errors[fieldName] = errorMessage);
                    errors = true
                    return false;
                }
            };

            checkEmpty('website', 'The url field is required.');
            if(checkEmpty('keyword', 'The keyword field is required.')){
                let validateKeyword = useLinkBuildProject().validateKeyword(this.keyword);
                if(validateKeyword !== null){
                    this.$patch((state) => state.errors.keyword = validateKeyword);
                    errors = true
                }
            }
            return !errors;
        }
    },
})();

export const useAutoRenewals = (uniquekey) => defineStore('lb-auto-renewals'+uniquekey, {
    state: () => {
        return {
            uniquekey: uniquekey,
            list: [],
        };
    },
    getters: {
      hasUserChanges: (state) => {
          return state.list.find((item) => {return item.count!==item.countInSite});
      },
      count: (state) => {
          return state.list.length;
      }
    },
    actions: {
        async download(){
            let resource = new ApiResource({url: '/api/v1/backlinks-project/'+this.uniquekey+'/auto-renewal/'});
            await resource.downloadAsync();
            if(resource.isSuccess()){
                resource.data.forEach((item) => {
                    let autoRenewalStore = useAutoRenewal(this.uniquekey, item.id);
                    autoRenewalStore.init(item);
                    if(!this.list.find((listItem) => item.id === listItem.id)){
                        this.list.push(autoRenewalStore);
                    }
                });
            }else {
                console.log('error');
            }
        },
        async deleteAutoRenewalById(id,projectUniquekey = null){
            let resource = new ApiResource({
                url:'/api/v1/backlinks-project/'+(projectUniquekey ?? this.uniquekey)+'/auto-renewal/'+id,
                method:'delete'
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    let index = state.list.findIndex((item) => item.id === id);
                    if(index !== -1){
                        state.list.splice(index,1);
                    }
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },

        resetCounts(){
            this.list.forEach((item) => {
                item.resetCounts();
            });
        },

        async saveUserCountChanges(){
            for (const item of this.list) {
                if(item.hasCountChanges){
                    await item.updateCount(item.countInSite);
                }
            }
            return [true];
        }

    }
})();

export const useAutoRenewal = (projectUniquekey,id) => defineStore('lb-auto-renewals-'+projectUniquekey+'-'+id, {
    state: () => {
        return {
            id:id,
            projectUniquekey: projectUniquekey,
            backlink_option: {
                name: null,
                value: null,
                typeConstant: null,
            },
            countInSite: null,
            count: null,
            updated_at: null,
            next_renewal_days: null
        }
    },
    getters: {
        hasCountChanges: (state) => {
            return state.count !== state.countInSite;
        }
    },
    actions: {
        init(data){
            this.$patch({
                backlink_option: {
                    name: data.backlink_option?.name,
                    value: data.backlink_option?.value,
                    typeConstant: data.backlink_option?.value?.replace(/_/g, '')
                },
                count: data.count,
                countInSite: data.count,
                updated_at: data.updated_at,
                next_renewal_days: data.next_renewal_days,
            })
        },
        resetCounts(){
            this.$patch({countInSite: this.count});
        },

        async updateCount(count){
            let resource = new ApiResource({
                url:'/api/v1/backlinks-project/'+this.projectUniquekey+'/auto-renewal/'+this.id,
                method:'put',
                params: {
                    count: count,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({
                    countInSite: count,
                    count: count,
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        }
    }
})();

const useLinkBuildOrders = (name) => defineStore('lb-orders-'+name, {
    state: () => {
        return {
            name: name,
            list: [],
        }
    },
    getters: {
        activityHistory: (state) => {
            let response = [];
            state.list.forEach((order) => {
                order.activityHistory.forEach((activityHistoryItem) => {
                    response.push({
                        order: order,
                        message: activityHistoryItem.message,
                        createdAt: activityHistoryItem.created_at,
                        createdAtFormatted: order.formatDate(activityHistoryItem.created_at),
                    });
                })
            });
            return response.sort((a,b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
        },
        ordersSortedByCreatedAt: (state) => {
            return state.list.sort((a,b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        },
        countOrders: (state) => {
            return state.list.length;
        },
        hasNewOrders: (state) => {
            return state.list.some((item) => item.is_new);
        },
    },
    actions: {
        markOrdersAsSeen(){
            this.list.forEach((item) => item.maskAsSeen());
        },
        addToList(projectKey,orderData){
            this.addManyToList([{projectKey: projectKey, orderData:orderData}]);
        },
        addManyToList(orders){
            let listForInsert = [];
            orders.forEach((order) => {
                let linkBuildOrderStore = useLinkBuildOrder(order.projectKey, order.orderData.id);
                linkBuildOrderStore.init(order.orderData);
                if(!this.list.find((listItem) => order.orderData.id === listItem.id)){
                    listForInsert.push(linkBuildOrderStore);
                }
            });
            this.$patch((state) => {
                state.list.push(...listForInsert);
            });
        },
        clearList(){
            this.$patch((state) => {
                state.list.splice(0,state.list.length);
            });
        }
    },
})();

export const useLinkBuildProjectOrders = (projectUniquekey) => defineStore('lb-project-orders-'+projectUniquekey,{
    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            errors: {
                addOrders: {
                    message: null,
                    errors: null,
                }
            }
        }
    },
    getters: {
        countOrdersByMonth(){
            return this.ordersManager().list.reduce((acc, item) => {
                const date = new Date(item.created_at);
                const month = date.getMonth();
                const year = date.getFullYear();

                const key = `${year}-${month}`;

                if (!acc[key]) {
                    acc[key] = { count: 0, month, year };
                }

                acc[key].count++;

                return acc;
            }, {});
        }
    },
    actions: {
        ordersManager(){
            return useLinkBuildOrders('project-'+this.projectUniquekey);
        },
        async download(){
            let resource = new ApiResource({url: '/api/v1/backlinks-project/'+this.projectUniquekey+'/orders'});
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.init(resource.data);
                return [true,resource.data];
            }else {
                return [false, resource.errorMessage];
            }
        },
        async addOrders(backlinksList, auto_renewal = false){

            let backlinkOptions = [];
            backlinksList.forEach((item) => backlinkOptions.push({option_id: item.id, count: item.count}));

            let params = {
                backlinks: backlinkOptions,
                auto_renewal: auto_renewal,
            }

            let resource = new ApiResource({
                url: '/api/v1/backlinks-project/'+this.projectUniquekey+'/orders',
                method: 'post',
                data: params
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                this.clearErrors('addOrders');
                return [true,{
                    ordersId: resource.data?.orders?.map(item => item.id),
                    autoRenewalsId: resource.data?.autorenewals?.map(item => item.id),
                }];
            }else{
                this.$patch((state) => {
                    state.errors.addOrders.message = this.filterErrorMessage(resource.errorMessage);
                    state.errors.addOrders.errors = resource.errorErrors;
                });
                return [false];
            }
        },
        filterErrorMessage(message){
            if(message === 'USER_NO_HAVE_CREDITS'){
                return 'Not enough credits. <a href="'+useRoutes().url('backlinks-pay.get')+'" target="_blank">Buy More Now</a>';
            }else{
                return message;
            }
        },
        clearErrors(groupName){
            this.$patch((state) => {
                state.errors[groupName].message = null;
                state.errors[groupName].errors = null;
            });
        },
        init(data){
            data.forEach((item) => {
                this.ordersManager().addToList(this.projectUniquekey, item);
            });
        },
    }
})();

export const useLinkBuildChartOrders = defineStore('lb-chart-orders',{
    state: () => {
        return {
            dateFrom: null,
            dateTo: null,
        }
    },
    getters: {
        labels: (state) => {
            const response = [];
            state.getListDates(state.dateFrom, state.dateTo).forEach((date) => {
                response.push(state.formatDateToShortMonth(date));
            });
            return response;
        },

        /**
         * Response:
         * [
         *     {
         *         new: [id1, id2, id3, ...],
         *         in_progress: [id4, id5, id6, ...]
         *         delivered: [id7, id8, id9, ...]
         *         canceled: [id10, id11, id12, ...]
         *     },
         *     ...
         * ]
         *
         */
        dataForChart(state){

            /**
             * Объект в виде
             * {
             *     YYYY-mm-dd: index,
             *     ...
             * }
             */
            let reverseLabels = state.getReverseLabels(state.dateFrom, state.dateTo);
            let response = [];
            Object.keys(reverseLabels).forEach((date) => {
                response.push({new: [], in_progress: [], delivered: [], canceled: [],})
            });
            this.ordersManager().ordersSortedByCreatedAt.forEach((order) => {
                let index = reverseLabels[order.newDateYYYYMMDD];
                if(index !== undefined && response[index].hasOwnProperty(order.statusConstant)){
                    response[index][order.statusConstant].push(order.id)
                }
            });
            return response;
        },
        newData(){
            return this.dataForChart.map((item) => item.new.length);
        },
        inProgressData(){
            return this.dataForChart.map((item) => item.in_progress.length);
        },
        deliveredData(){
            return this.dataForChart.map((item) => item.delivered.length);
        },
        canceledData(){
            return this.dataForChart.map((item) => item.canceled.length);
        }
    },
    actions: {
        ordersManager: () => {
            return useLinkBuildOrders('all-orders-for-chart');
        },
        getListDates(dateFrom,dateTo){
            const response = [];
            let currentDate = new Date(dateFrom);
            while (currentDate <= dateTo) {
                response.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return response;
        },
        getReverseLabels(dateFrom, dateTo){
            let dates = this.getListDates(dateFrom, dateTo);
            const result = {};
            dates.forEach((date, index) => {
                const formattedDate = this.formatDateToYYYYMMDD(date);
                result[formattedDate] = index;
            });
            return result;
        },
        setDateFrom(dateFrom){
            this.$patch({dateFrom: dateFrom});
        },
        setDateTo(dateTo){
            this.$patch({dateTo: dateTo});
        },
        formatDateToYYYYMMDD(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        formatDateToShortMonth(date){
            const day = String(date.getDate()).padStart(2, '0');
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const month = monthNames[date.getMonth()];
            return `${day} ${month}`;
        },
        async downloadBetween(dateFrom = null, dateTo = null){
            dateFrom = dateFrom ?? this.dateFrom;
            dateTo = dateTo ?? this.dateTo;
            let dateToForServer = new Date(dateTo);
            dateToForServer.setDate(dateToForServer.getDate() + 1);

            let resource = new ApiResource({
                url: '/api/v1/backlinks-project-chart-data',
                method: 'get',
                params: {
                    from: this.formatDateToYYYYMMDD(dateFrom),
                    to: this.formatDateToYYYYMMDD(dateToForServer),
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.setDateFrom(dateFrom);
                this.setDateTo(dateTo);
                let orders = [];
                resource.data.forEach((orderData) => {
                    orders.push({projectKey: orderData.project_id, orderData: orderData});
                });
                this.ordersManager().clearList();
                this.ordersManager().addManyToList(orders);
                return [true];
            }else {
                return [false, resource.errorMessage];
            }
        },
    }
});

export const useLinkBuildingType = defineStore('link-building-type', {
    actions: {
        getConstant(typeName){
            const lowerCaseType = typeName?.toLowerCase();

            if (/seo\s*clicks|clicks|credits_search|seo\s*traffic/.test(lowerCaseType)) {
                return "seo_credit";
            } else if (/d(a|r)\s{0,3}10\+|blogger-outreach-d(a|r)10|d(a|r)_10/.test(lowerCaseType)) {
                return "da10";
            } else if (/d(a|r)\s{0,3}20\+|blogger-outreach-d(a|r)20|d(a|r)_20/.test(lowerCaseType)) {
                return "da20";
            } else if (/d(a|r)\s{0,3}30\+|blogger-outreach-d(a|r)30|d(a|r)_30/.test(lowerCaseType)) {
                return "da30";
            } else if (/d(a|r)\s{0,3}40\+|blogger-outreach-d(a|r)40|d(a|r)_40/.test(lowerCaseType)) {
                return "da40";
            } else if (/d(a|r)\s{0,3}50\+|blogger-outreach-d(a|r)50|d(a|r)_50/.test(lowerCaseType)) {
                return "da50";
            } else if(lowerCaseType === 'blogger-outreach' || lowerCaseType === 'link-building' || lowerCaseType === 'backlinks' || lowerCaseType === 'link buildings') {
                return CREDIT_TYPE_BACKLINK_V2;
            } else if (/credits_/.test(lowerCaseType)) {
                return lowerCaseType;
            } else {
                return null;
            }
        },
        getTypeCredit(typeConstant){
            switch(typeConstant){
                case 'da10' :
                case 'da20' :
                case 'da30' :
                case 'da40' :
                case 'da50' : return CREDIT_TYPE_BACKLINK;
                case 'seo_credit' : return CREDIT_TYPE_SEO;
                case CREDIT_TYPE_BACKLINK_V2 : return CREDIT_TYPE_BACKLINK_V2;
                default: return null;
            }
        },
        getTitleShort(typeConstant){
            switch(typeConstant){
                case 'da10' : return 'DR10+';
                case 'da20' : return 'DR20+';
                case 'da30' : return 'DR30+';
                case 'da40' : return 'DR40+';
                case 'da50' : return 'DR50+';
                case 'seo_credit' : return 'SEO Traffic';
                case CREDIT_TYPE_BACKLINK_V2 : return 'Backlinks';
                default: return '';
            }
        },
        getTitleUsual(typeConstant, nearNumber = null){
            let nearNumberLocal = nearNumber === null ? 0 : nearNumber;
            switch(typeConstant){
                case 'da10' : return 'DR 10+';
                case 'da20' : return 'DR 20+';
                case 'da30' : return 'DR 30+';
                case 'da40' : return 'DR 40+';
                case 'da50' : return 'DR 50+';
                case 'seo_credit' : return `SEO Traffic ${pluralize('Credit', nearNumberLocal)}`;
                case CREDIT_TYPE_BACKLINK_V2 : return `Backlinks ${pluralize('Credit', nearNumberLocal)}`;
                default: return '';
            }
        },
        getColor(typeConstant){
            switch(typeConstant){
                case 'da10' : return '#CC99EB';
                case 'da20' : return '#B26EDC';
                case 'da30' : return '#9A59C2';
                case 'da40' : return '#783B9E';
                case 'da50' : return '#612B83';
                case 'seo_credit' : return '#224CB9';
                case CREDIT_TYPE_BACKLINK_V2 : return '#612B83';
                default: return '';
            }
        },
        isBacklinkTypeConstant(typeConstant){
            return ['da10', 'da20', 'da30', 'da40', 'da50'].includes(typeConstant);
        },
    }
});

export const useLinkBuildingStatus = defineStore('link-building-status', {
    actions: {
        getConstant(status){
            switch(status){
                case 'new' : return 'in_progress';
                case 'in progress' : return 'in_progress';
                case 'delivered' : return 'delivered';
                case 'canceled' : return 'canceled';
                default: null;
            }
        },
        getTitle(statusConstant){
            switch(statusConstant){
                case 'new' : return 'New';
                case 'in_progress' : return 'In progress';
                case 'delivered' : return 'Delivered';
                case 'canceled' : return 'Cancelled';
                default: return '';
            }
        },
    }
});

export const useLinkBuildOrder = (projectUniquekey, id) => defineStore('lb-order-'+projectUniquekey+'-'+id,{
    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            id: id,
            keyword: null,
            project_id: null,
            provider: null,
            provider_finished_working_at: null,
            provider_started_working_at: null,
            result_url: null,
            status: null,
            type: null,
            url: null,
            reviews: [],
            created_at: null,
            errors: {
                reviews: {
                    message: null,
                    error: null,
                },
                reportProblem: {
                    message: null,
                    error: null,
                },
            },
            activityHistory: [],
            demo_status_update_time: null,
            has_report_problem: null,
            is_new: null,
        }
    },
    getters: {
        statusConstant: (state) => {
            return useLinkBuildingStatus().getConstant(state.status);
        },
        statusTitle(){
            return useLinkBuildingStatus().getTitle(this.statusConstant);
        },
        typeConstant: (state) => {
            return useLinkBuildingType().getConstant(state.type);
        },
        typeTitleUsual(){
            return useLinkBuildingType().getTitleUsual(this.typeConstant);
        },
        typeTitleShort(){
            return useLinkBuildingType().getTitleShort(this.typeConstant);
        },
        lastUpdated: (state) => {
            switch(state.statusConstant){
                case 'new' : return new Date(state.created_at);
                case 'in_progress' : return new Date(state.provider_started_working_at);
                case 'delivered' : return new Date(state.provider_finished_working_at);
                case 'canceled' : return new Date(state.provider_started_working_at);
                default: return null;
            }
        },
        createdAtFormatted: (state) => {
            if(state.created_at){
                return state.formatDate(state.created_at);
            }else{
                return '';
            }
        },
        newDateYYYYMMDD: (state) => {
            if(state.created_at !== null && state.created_at.length >= 10){
                return state.created_at.slice(0,10);
            }
            return null;
        },
        inProgressDateYYYYMMDD: (state) => {
            if(state.provider_started_working_at !== null && state.provider_started_working_at.length >= 10){
                if(state.statusConstant === 'in_progress' || state.statusConstant === 'delivered'){
                    return state.provider_started_working_at.slice(0,10);
                }
            }
            return null;
        },
        deliveredDateYYYYMMDD: (state) => {
            if(state.provider_finished_working_at !== null && state.provider_finished_working_at.length >= 10){
                if(state.statusConstant === 'delivered'){
                    return state.provider_finished_working_at.slice(0,10);
                }
            }
            return null;
        },
        canceledDateYYYYMMDD: (state) => {
            if(state.provider_started_working_at !== null && state.provider_started_working_at.length >= 10){
                if(state.statusConstant === 'canceled'){
                    return state.provider_started_working_at.slice(0,10);
                }
            }
            return null;
        },
        latestReview: (state) => {
            if(state.reviews && state.reviews?.length > 0){
                let review = state.reviews.reduce((latest, current) => {
                    return new Date(latest.created_at) > new Date(current.created_at) ? latest : current;
                }, state.reviews[0]);
                return {
                    score: review.score,
                    comment: review.comment,
                }
            }else{
                return null;
            }
        },
        hasReview: (state) => {
            return state.reviews.length > 0;
        },
        reviewScore(){
            return this.latestReview?.score ?? 0;
        },
        reviewComment(){
            return this.latestReview?.comment ?? '';
        },
        hasReviewComment(){
            return this.latestReview?.comment && this.latestReview?.comment.length > 0;
        },
        reviewCountComments(state){
            return state.reviews.reduce((sum,review) => (review?.comment && review.comment.length > 0) ? sum+1 : sum,0);
        },
        correctUrl: (state) => {
            if(!state.url)return '';
            if (!state.url.startsWith('http://') && !state.url.startsWith('https://')) {
                return 'http://' + state.url;
            }
            return state.url;
        },
        resultUrlShort: (state) => {
            return new UrlManipulator(state.result_url).withOutWww().withOutProtocol().removeTrailingSlashes().getUrl();
        },
        urlShort: (state) => {
            return new UrlManipulator(state.url).withOutWww().withOutProtocol().removeTrailingSlashes().getUrl();
        },
    },
    actions: {
        maskAsSeen(){
          this.$patch({is_new: false,});
        },
        formatDate(date){
            if(!date){return '';}
            const dateObj = new Date(date);
            const day = String(dateObj.getUTCDate()).padStart(2, '0');
            const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
            const year = dateObj.getUTCFullYear();
            return `${day}.${month}.${year}`;
        },
        formatDateToYYYYMMDD(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        init(data){

            initStore(this, data, {
                reviews: (state, value) => {
                    state.reviews = value ?? [];
                },
                activity_history: (state,value) => {
                    state.activityHistory = value ?? [];
                },
                demo_status_update_time: (state,value) => {
                    state.demo_status_update_time = value ?? null;
                }
            })

        },
        async saveReview(score, comment = ''){

            if(score < 1 || score > 5){
                this.errors.reviews.message = 'Specify a rating from 1 to 5 stars';
                return;
            }else{
                this.errors.reviews.message = null;
            }

            let params = {
                score: parseInt(score),
                comment: comment,
            }

            let resource = new ApiResource({
                url: '/api/v1/backlinks-project/'+this.projectUniquekey+'/orders/'+this.id+'/review',
                method: 'post',
                data: params
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                this.errors.reviews.message = null;
                this.errors.reviews.errors = null;
                this.$patch((state) => {
                    state.reviews.push(resource.data.review);
                });
                return [true,resource.data];
            }else{
                this.errors.reviews.message = resource.errorMessage;
                this.errors.reviews.errors = resource.errorErrors;
                return [false];
            }
        },
        async sendReportProblem(message){

            let resource = new ApiResource({
                url:    `/api/v1/backlinks-project/${this.projectUniquekey}/orders/report-problem`,
                method: 'post',
                data: {text: message,order_id: id,}
            });

            await resource.downloadAsync();

            if(resource.isSuccess()){
                this.errors.reportProblem.message = null;
                this.errors.reportProblem.errors = null;
                this.$patch({has_report_problem: true});
                return [true];
            }else{
                this.errors.reportProblem.message = resource.errorMessage;
                this.errors.reportProblem.errors = resource.errorErrors;
                return [false];
            }


        }
    }
})();

export const useKeywordDifficultySettings = defineStore('keyword-difficulty-settings', {
    state: () => {
        return {
            data: [
                {
                    color: '#459a63',
                    percent: 0,
                    label: 'Easy',
                },
                {
                    color: '#4ea15b',
                    percent: 10,
                    label: 'Easy',
                },
                {
                    color: '#9db945',
                    percent: 30,
                    label: 'Medium',
                },
                {
                    color: '#f3b23e',
                    percent: 70,
                    label: 'Hard',
                },
                {
                    color: '#ed6b2c',
                    percent: 100,
                    label: 'Super hard',
                },
            ],
        }
    },
    getters: {

        percents: (state) => {
            return state.data.map((value) => value.percent);
        },

        colors: (state) => {
            return state.data.map((value) => value.color);
        },

        lengths: (state) => {
            return state.data.slice(1).map((item,index) => item.percent - state.data[index].percent);
        },

    },
    actions: {

        getPairItemsByPercent(percent){
            let response = [null, null];
            this.data.every((item, index) => {
                let prevItem = this.data[index - 1] ?? null;
                if(percent <= item.percent){
                    response[0] = prevItem;
                    response[1] = item;
                    return false;
                }else{
                    return true;
                }
            });
            return response;
        },

        getLabel(percent){
            return this.getPairItemsByPercent(percent)[1]?.label ?? null;
        },

        getColorForPercent(percent){
            let [itemPrev, item] = this.getPairItemsByPercent(percent);
            if(item && itemPrev) {
                return getGradientColor([[itemPrev.color, itemPrev.percent], [item.color, item.percent]], percent);
            }else if(item){
                return item.color;
            }else{
                return null;
            }
        }

    }
});

export const useBacklinksRecommendations = (projectUniquekey) => defineStore('backlinks-recommendations-'+projectUniquekey,{
    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            _keyword_difficulty: null,
            _count_domains: null,
            /**
             * Процентное соотношение покупки ссылок
             */
            recommendations: {},
            _downloadAfterRecommendationCalculated: false,
        }
    },
    getters: {
        /**
         * Предлагаемое количество ссылок плюс-минус
         */
        count_domains: (state) => {
            return state._count_domains ? state._count_domains : 1;
        },
        keyword_difficulty: (state) => {
            let kwd = parseFloat(state._keyword_difficulty);

            if(!kwd || kwd < 0)             return 0;
            else if(kwd >= 0 && kwd <= 100) return Math.round(kwd);
            else if(kwd > 100)              return 100;
            else                            return null;
        },
        /**
         *  Сумма ссылок сгенерированная на основе таблицы recommendationsCount
         */
        totalCountBacklinks(){
            return Object.values(this.recommendationsCount).reduce((sum,val) => sum+val,0);
        },
        /**
         * Название текущей сложности keyword_difficulty
         */
        keywordDifficultyLabel(state) {
            return useKeywordDifficultySettings().getLabel(this.keyword_difficulty);
        },
        /**
         * Цвет текущей сложности keyword_difficulty
         */
        color(){
            return useKeywordDifficultySettings().getColorForPercent(this.keyword_difficulty);
        },
        /**
         * Конкретное количество предлагаемых ссылок для каждого типа на основе recommendations
         */
        recommendationsCount(state){
            let response = {}
            Object.entries(state.recommendations).forEach(([typeConstant, percent]) => {
                response[typeConstant] = (percent && this.count_domains) ? Math.round(this.count_domains / 100 * percent) : 0;
            });
            if(Object.values(response).reduce((s,i) => s+i,0) === 0){
                response[this.backlinkWithMaxPercent] = this.count_domains;
            }

            let sumLinks = Object.values(response).reduce((s,i) => s+i,0);

            while(sumLinks !== this.count_domains){

                let item = Object.entries(response).sort((a,b) => b[1] - a[1])[0];

                if(sumLinks > this.count_domains){
                    response[item[0]]--;
                }else{
                    response[item[0]]++;
                }

                sumLinks = Object.values(response).reduce((s,i) => s+i,0);

            }


            return response;
        },
        recommendationsCalculated: (state) => {
            return useRecsStore(state.projectUniquekey).statusShow === 'recoms' && !useRecsStore(state.projectUniquekey).loading;
        },
        /**
         * Можно ли показывать рекомендации по ссылкам для текущего проекта?
         */
        canBeShown: (state) => {
            return state._downloadAfterRecommendationCalculated;
        },
        /**
         * Тип ссылок с наибольшим процентом рекомендации
         */
        backlinkWithMaxPercent: (state) => {
            let maxKey = null;
            let maxValue = -Infinity;

            for (const key in state.recommendations) {
                if (state.recommendations[key] > maxValue) {
                    maxValue = state.recommendations[key];
                    maxKey = key;
                }
            }
            return maxKey;
        },
    },
    actions: {
        async download(params = {}){

            params = Object.assign({
                url: '/api/v1/backlinks-project/'+this.projectUniquekey+'/recommend-backlinks',
                method: 'get'
            }, params);

            let resource = new ApiResource(params);
            await resource.downloadAsync();
            if(resource.isSuccess()){

                this.$patch((state) => {
                    state._downloadAfterRecommendationCalculated = this.recommendationsCalculated

                    state._keyword_difficulty = resource.data.keyword_difficulty;
                    state._count_domains = resource.data.count_domains;
                    state.recommendations = resource.data.recommendations;
                });
                return [true];
            }else{
                return [false];
            }

        }
    }
})();