<template>
  <div class="v-form-checkbox vfc" :class="showedValue ? 'checked' : ''">
    <v-icon-svg name="check-mark" class="vfc__icon-check"/>
    <div class="vfc__icon-minus" v-if="false">
      <v-icon-svg name="check-minus"/>
    </div>
    <input v-if="!onlyShow" type="checkbox" ref="checkbox" v-model="checkboxModel">
  </div>
</template>

<script setup>
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed, ref} from "vue";

const props = defineProps({
  onlyShow: {type:Boolean, default:false},
  value: {default:false},
})

const checkbox = ref();

const checkboxModel = defineModel();
if(checkboxModel.value === undefined){
  checkboxModel.value = false;
}

let showedValue = computed(() => {
  if(props.onlyShow){
    return props.value;
  }else{
    return checkboxModel.value;
  }
});


</script>

<style scoped lang="scss">
.v-form-checkbox.vfc{
  background-color: #fff;
  width:16px;
  height:16px;
  border-radius: 4px;
  border:1px solid #D6E3EF;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink:0;
  
  .vfc__icon-check{
    display: block;
    opacity:0;
  }
  .vfc__icon-minus{}
  input{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    cursor: pointer;
  }

  &.checked{
    background: #1C7EE0;
    border-color:#1C7EE0;
    .vfc__icon-check{
      opacity:1
    }
  }

}
</style>