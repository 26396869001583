import {defineStore} from "pinia";
import {boolType, chartTypes, colors, iconTitle} from "@app-vue/constants/recoConstants";

export const useRecoStore = (projectUniquekey, recoName) => defineStore('project/'+projectUniquekey+'/recommendation/'+recoName, {
    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            avg_value: null,
            group_description: null,
            group_name: null,
            is_boolean: null,
            is_error: null,
            label: null,
            max_value: null,
            median_value: null,
            metric: null,
            metric_name: null,
            min_value: null,
            not_applicable: null,
            recommendation: null,
            settings: null,
            top_values: null,
            value: null,
            value_name: null,
            weight: null,
            sort_order: null,
        }
    },
    getters: {
        currentTitleIconClass(){
            switch(this.currentColor){
                case colors.superDanger:
                case colors.danger: return iconTitle.triangle;
                case colors.warning: return iconTitle.square;
                case colors.success: return iconTitle.circle;
                default: return iconTitle.square;
            }
        },
        currentColor(){
            switch(this.chartScaleType){
                case chartTypes.default : return this.getCurrentColorDefault();
                case chartTypes.error : return this.getCurrentColorError();
                case chartTypes.boolean : return this.getCurrentColorBoolean();
            }
        },
        chartScaleIndicators(){
            switch(this.chartScaleType){
                case chartTypes.default : return this.getChartScaleIndicatorsDefault();
                case chartTypes.error : return this.getChartScaleIndicatorsError();
                case chartTypes.boolean : return this.getChartScaleIndicatorsBoolean();
            }
        },
        chartScaleStripes(){
            switch(this.chartScaleType){
                case chartTypes.default : return this.getChartScaleStripesDefault();
                case chartTypes.error : return this.getChartScaleStripesError();
                case chartTypes.boolean : return this.getChartScaleStripesBoolean();
            }
        },
        chartScaleTexts(){
            switch(this.chartScaleType){
                case chartTypes.default : return this.getChartScaleTextsDefault();
                case chartTypes.error : return this.getChartScaleTextsError();
                case chartTypes.boolean : return this.getChartScaleTextsBoolean();
            }
        },
        chartScaleType(){
            if(this.is_error === 1){
                return chartTypes.error;
            }else if(this.is_boolean === 1){
                return chartTypes.boolean;
            }else{
                return chartTypes.default;
            }
        },
        countTopValues(){
            if(this.top_values && this.top_values.constructor === Array){
                return this.top_values.length;
            }else{
                return 0;
            }
        }
    },
    actions: {

        getMinMax(val,min,max){
            return Math.max(Math.min(val,max),min);
        },

        getColorForValue(value){
            switch(this.chartScaleType){
                case chartTypes.default : return this.getColorDefault(value);
                case chartTypes.error : return this.getColorError(value);
                case chartTypes.boolean : return this.getColorBoolean(value);
            }
        },

        getTopValueSortedAsc(){
            return this.top_values.sort(function(a,b){
                return parseFloat(a.position) - parseFloat(b.position);
            });
        },

        getColorDefault(value){
            let color;
            if(value === -1){
                return colors.error;
            }
            let sections = this.getChartScaleStripesDefault()
            sections.every((section)=>{
                if(value <= section.value){
                    color = section.color;
                    return false;
                }
                return true;
            });
            if(color === undefined){ //есть случаи, кога value больше maxValue
                if(sections.length > 0){
                    if(value > sections[sections.length-1].value){
                        color = sections[sections.length-1].color;
                    }
                }
            }
            return color;
        },

        getCurrentColorDefault(){
            if(this.not_applicable === 0){
                return this.getColorDefault(this.value);
            }else{
                return colors.error;
            }
        },

        getChartScaleIndicatorsDefault(){
            if(this.not_applicable === 0){
                if(this.min_value === 0 && this.max_value === 0){
                    return [{
                        position: 0
                    }];
                }else{
                    return [{
                        position: this.value / this.max_value * 100
                    }];
                }
            }else{
                return [];
            }
        },

        getChartScaleStripesDefault(){
            let sections = [];

            if(this.min_value === 0 && this.max_value === 0){

                sections.push({
                    width: 100,
                    value: 0,
                    color: colors.superDanger,
                });

            }else{

                sections.push({
                    width: this.min_value/this.max_value*100,
                    value: this.min_value,
                    color: colors.superDanger,
                });

                if(this.median_value > this.avg_value){
                    sections.push({
                        width: (this.avg_value - this.min_value)/this.max_value*100,
                        value: this.avg_value,
                        color: colors.danger,
                    });
                    sections.push({
                        width: (this.median_value - this.avg_value)/this.max_value*100,
                        value: this.median_value,
                        color: colors.warning,
                    });
                    sections.push({
                        width: (this.max_value-this.median_value)/this.max_value*100,
                        value: this.max_value,
                        color: colors.success
                    });
                }else{
                    sections.push({
                        width: (this.median_value - this.min_value)/this.max_value*100,
                        value: this.median_value,
                        color: colors.danger,
                    });
                    sections.push({
                        width: (this.avg_value - this.median_value)/this.max_value*100,
                        value: this.avg_value,
                        color: colors.warning,
                    });
                    sections.push({
                        width: (this.max_value-this.avg_value)/this.max_value*100,
                        value: this.max_value,
                        color: colors.success
                    });
                }

            }

            return sections;
        },

        getChartScaleTextsDefault(){
            if(this.not_applicable === 0){
                if(this.max_value === 0 && this.min_value === 0){
                    return [
                        {
                            value: this.value,
                            valueName: this.value_name,
                            position: 0,
                            color: this.currentColor
                        }
                    ];
                }else{
                    return [
                        {
                            value: this.value,
                            valueName: this.value_name,
                            position: this.value / this.max_value * 100,
                            color: this.currentColor
                        }
                    ];
                }
            }else{
                return [
                    {
                        value: 'You have: N/A',
                        position: 50,
                        color: this.currentColor,
                        fontWeight: 600
                    }
                ];
            }
        },

        getMinMaxAvgBoolean(){
          return this.getMinMax(this.avg_value,0.1,0.9);
        },

        getTypeOfBoolChartForValue(value){
            if(value === 0 && this.avg_value === 0){
                return boolType.bool000;
            }else if(value === 0 && this.avg_value === 1){
                return boolType.bool001;
            }else if(value === 1 && this.avg_value === 0){
                return boolType.bool010;
            }else if(value === 1 && this.avg_value === 1){
                return boolType.bool011;
            }else if(this.avg_value < 1 && this.avg_value > 0){
                if(value === 1){
                    return boolType.bool111;
                }else{
                    return boolType.bool101;
                }
            }
        },

        getTypeOfBoolChart(){
            return this.getTypeOfBoolChartForValue(this.value);
        },

        getColorBoolean(value){
            switch(this.getTypeOfBoolChartForValue(value)){

                case boolType.bool000 : return colors.warning;

                case boolType.bool101 :
                case boolType.bool001 : return colors.danger;

                case boolType.bool010 :
                case boolType.bool111 :
                case boolType.bool011 : return colors.success

            }
        },

        getCurrentColorBoolean(){
            if(this.not_applicable === 0){
                return this.getColorBoolean(this.value);
            }else{
                return colors.error;
            }
        },

        getChartScaleIndicatorsBoolean(){
            if(this.not_applicable === 0){
                switch(this.getTypeOfBoolChart()){
                    case boolType.bool000 :
                    case boolType.bool011 :
                        return [{position: 50}];

                    case boolType.bool010 :
                        return [{position: 95}];

                    case boolType.bool001 :
                        return [{position: 5}];

                    case boolType.bool101 :
                        return [{
                            position: (1 - this.getMinMaxAvgBoolean())*50,
                        }];

                    case boolType.bool111 :
                        return [{
                            position:
                                (1 - this.getMinMaxAvgBoolean())*100
                                + this.getMinMaxAvgBoolean()*50,
                        }];
                }
            }else{
                return [];
            }

        },

        getChartScaleStripesBoolean(){
            switch(this.getTypeOfBoolChart()){
                case boolType.bool000 :
                    return [{
                        width: 100,
                        color: colors.warning,
                    }];

                case boolType.bool001 :
                    return [{
                        width: 10,
                        color: colors.danger,
                    },{
                        width: 90,
                        color: colors.success,
                    }];

                case boolType.bool010 :
                    return [{
                        width: 90,
                        color: colors.danger,
                    },{
                        width: 10,
                        color: colors.success,
                    }];

                case boolType.bool011 :
                    return [{
                        width: 100,
                        color: colors.success,
                    }];

                case boolType.bool101 :
                case boolType.bool111 :
                    return [{
                        width: (1 - this.getMinMaxAvgBoolean())*100,
                        color: colors.danger
                    },{
                        width: this.getMinMaxAvgBoolean()*100,
                        color: colors.success
                    }];

            }
        },

        getChartScaleTextsBoolean(){
            if(this.not_applicable === 0){
                switch(this.getTypeOfBoolChart()){
                    case boolType.bool000 :
                        return [{
                            value: 'You don\'t have',
                            position: 50,
                            color: colors.warning,
                            fontWeight: 600
                        }];

                    case boolType.bool101 :
                        return [{
                            value: 'You don\'t have',
                            position: (1 - this.getMinMaxAvgBoolean())*50,
                            color: colors.danger,
                            fontWeight: 600
                        },{
                            value: 'Others have',
                            position: this.getMinMaxAvgBoolean()*50 + (1 - this.getMinMaxAvgBoolean())*100,
                            color: colors.success,
                            fontWeight: 600
                        }];

                    case boolType.bool001 :
                        return [{
                            value: 'You don\'t have',
                            position: 5,
                            color: colors.danger,
                            fontWeight: 600
                        },{
                            value: 'Others have',
                            position: 50,
                            color: colors.success,
                            fontWeight: 600
                        }];

                    case boolType.bool010 :
                        return [{
                            value: 'Others don\'t have',
                            position: 45,
                            color: colors.danger,
                            fontWeight: 600
                        },{
                            value: 'You have',
                            position: 95,
                            color: colors.success,
                            fontWeight: 600
                        }];

                    case boolType.bool111 :
                        return [{
                            value: 'Others don\'t have',
                            position: (1 - this.getMinMaxAvgBoolean())*50,
                            color: colors.danger,
                            fontWeight: 600
                        },{
                            value: 'You have',
                            position: this.getMinMaxAvgBoolean()*50 + (1 - this.getMinMaxAvgBoolean())*100,
                            color: colors.success,
                            fontWeight: 600
                        }];

                    case boolType.bool011 :
                        return [{
                            value: 'You have',
                            position: 50,
                            color: colors.success,
                            fontWeight: 600
                        }];

                }
            }else{
                return [{
                    value: 'You have: N/A',
                    position: 50,
                    color: this.currentColor,
                    fontWeight: 600
                }];
            }

        },


        getColorError(value){
            return colors.error;
        },

        getCurrentColorError(){
            return colors.error;
        },

        getChartScaleIndicatorsError(){
            return [];
        },

        getChartScaleStripesError(){
            return [{
                width: 100,
                color: colors.error,
            }];
        },

        getChartScaleTextsError(){
            return [{
                value: 'Data not available',
                position: 0,
                color: this.currentColor,
                fontWeight: 600
            }];
        },

    }
})();