<template>
  <div class="v-wic-tooltip-container">
    <slot/>
    <v-icon-svg as-img as-block name="close-white" class="close" @click="tooltipHide()"/>
  </div>
</template>

<script setup>
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {inject} from "vue";

const tooltipHide = inject('tooltipHide');

</script>

<style lang="scss" scoped>
.v-wic-tooltip-container{
  width:550px;
  border-radius:8px;
  box-shadow:$box-shadow-tooltip;
  background:#fff;
  @media(max-width:767px){
    width:280px;
  }
  .close{
    position:absolute;
    cursor:pointer;
    top:12px;
    right:12px;
    opacity:0.8;
    &:hover{
      opacity:1;
    }
    @media(max-width:767px){
      top:11px;
      right:11px;
    }
  }
}
</style>