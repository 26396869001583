<template>
  <component :is="type" class="v-head-title vht" :class="mainClass">
    <slot/>
  </component>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  /**
   * @type {'h1'|'h2'}
   */
  type: {default: 'h1',},
  isEndsEllipsis: {default:false,type:Boolean},
});

const mainClass = computed(() => {
  let response = ['vht--'+props.type];
  if(props.isEndsEllipsis) response.push('vht--is-ends-ellipsis');
  return response;
});

</script>

<style scoped lang="scss">
.vht{
  font-weight:600;
  color:#0F1F3A;
  margin:0px;
  max-width: 100%;

  &.vht--h1{
    font-size:32px;
    @media(max-width:$mobile-width){
      font-size: 24px;
    }
  }

  &.vht--h2{
    font-size:20px;
  }

  &.vht--is-ends-ellipsis{
    @include ellipsis();
  }

}
</style>