<template>
  <div class="v-review-stars vrs"
       :class="mainClass"
       @mouseleave="selectedRating=0"
       :style="styleContainer"
       @click="chooseStar"
  >
    <div class="vrs__item" v-for="number in [1,2,3,4,5]" :style="styleItem">
      <template v-if="['default','white-on-blue'].includes(color)">
        <v-icon-svg name="star" class="vrs__item-icon" @mouseenter="selectedRating=number" :style="styleItem"/>
      </template>
      <template v-else-if="color === 'bordered-gray-or-blue'">
        <v-icon-svg :name="viewRating >= number ? 'star-v2' : 'star-bordered-v2'"
                    class="vrs__item-icon"
                    @mouseenter="selectedRating=number"
                    as-img
                    :style="styleItem"/>
      </template>
    </div>
  </div>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed, ref} from "vue";

const props = defineProps({
  /**
   * Выводимый рейтинг
   */
  value: {},
  /**
   * Размер звездочки в px
   */
  size: {default: 16},
  noSelect: {type:Boolean},
  /**
   * Цвет звездочек
   * @type {'default'|'white-on-blue'|'bordered-gray-or-blue'}
   */
  color: {default: 'default',}
});

const emit = defineEmits(['chooseRating'])

const viewRating = computed(() => {
  if(props.noSelect) return props.value;

  let response = selectedRating.value > 0 ? selectedRating.value : props.value;

  return response >= 1 || response <=5 ? response : 0

});

const selectedRating = ref(0);

const mainClass = computed(() => {
  let response = [];
  response.push('vrs--select-'+viewRating.value);
  response.push('vrs--color-'+props.color);
  return response;
});

const styleContainer = computed(() => {
  //gap 4px для 16px ширины звездочки пропорционально увеличиваем к указанной ширине props.size
  return {gap: Math.round(props.size / 16 * 4 * 100)/100 + 'px'}
});

const styleItem = computed(() => {
  return {width: props.size + 'px', height: props.size + 'px',}
});

const chooseStar = () => {
  if(!props.noSelect){
    emit('chooseRating', selectedRating.value);
  }
}


</script>

<style lang="scss" scoped>

$start-no-select-color-default: $optional-orange-5;
$start-select-color-default: #EFA734;

$start-no-select-color-white-on-blue: $optional-orange-5;
$start-select-color-white-on-blue: #EFA734;

.v-review-stars.vrs{
  display:flex;
  align-items: center;
  cursor: pointer;

  .vrs__item{
    flex-shrink:0;
    .vrs__item-icon{
      display:block;
    }
  }

  &.vrs--color-default{
    .vrs__item-icon{fill:$start-no-select-color-default;}
    &.vrs--select-1 .vrs__item:nth-child(-n+1) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-2 .vrs__item:nth-child(-n+2) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-3 .vrs__item:nth-child(-n+3) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-4 .vrs__item:nth-child(-n+4) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-5 .vrs__item:nth-child(-n+5) .vrs__item-icon{fill: $start-select-color-default}
  }

  &.vrs--color-white-on-blue{
    .vrs__item-icon{fill:$start-no-select-color-white-on-blue;opacity:0.5;}
    &.vrs--select-1 .vrs__item:nth-child(-n+1) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-2 .vrs__item:nth-child(-n+2) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-3 .vrs__item:nth-child(-n+3) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-4 .vrs__item:nth-child(-n+4) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-5 .vrs__item:nth-child(-n+5) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
  }

}
</style>