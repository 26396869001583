<template>
  <div class="v-pulsating-bar" :class="mainClass">

    <div class="indicator" :style="{width:indicatorWidth}">
      <div v-if="typeAnimation === 'pulse'" class="pulsating-bar" :style="{width:`${barInnerWidth}%`}">
        <div class="pulsating-bar-inner"></div>
      </div>
    </div>
    <div class="snake-bar" :style="{left:snakeBarLeft}"></div>

    <div class="content">
      <slot/>
    </div>

  </div>
</template>

<script setup>

import {computed, getCurrentInstance} from "vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  value: {type: Number, default:0,},
  /**
   * @type {'pulse'|'snake'|null}
   */
  typeAnimation: {type:String,default:null,},
  nearlyReady: {type: Boolean, default:false,},
});

const barInnerWidth = computed(() => {
  if(props.value === 0){
    return 0;
  }else{
    return 100 + (100 - props.value) / props.value * 100
  }
});

const mainClass = computed(() => {
  let response = [];
  if(props.typeAnimation){
    response.push('is-animation-'+props.typeAnimation);
  }
  return response;
});

const indicatorWidth = computed(() => {
  if(props.typeAnimation === 'snake'){
    return snakeBarLeft.value;
  }else{
    return `${props.value}%`;
  }
});

const snakeBarLeft = computed(() => {
  let value = props.value;
  if(props.nearlyReady){
    value = 100;
  }
  if(isMobile){
    return `min(${value}%, calc(100% - 12px))`;
  }else{
    return `min(${value}%, calc(100% - 15px))`;
  }
});

</script>

<style lang="scss" scoped>
.v-pulsating-bar{
  position:relative;
  width:100%;
  border-radius:4px;
  background:#FFFFFF1A;
  overflow: hidden;

  .indicator{
    position:absolute;
    height:100%;
    top:0px;
    left:0px;
    width:0%;
    background:#1C7EE0;
    transition-duration:0.2s;
  }

  .content{
    position:relative;
  }

  &.expired {
    .indicator{
      background:#FFFFFF1A;
    }
  }

  &.is-animation-pulse {
    .indicator {
      overflow:hidden;
      .pulsating-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .pulsating-bar-inner {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: -100%;
          top: 0;
          background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
          animation-name: pulsate;
          animation-iteration-count: infinite;
          animation-duration: 1.5s;
        }
      }
    }
  }

  &.is-animation-snake{
    .indicator {
      overflow:initial;
    }
    .snake-bar{
      position:absolute;
      height:100%;
      top:0px;
      left:0%;
      width:5px;
      background:#4F9FF0;
      animation-name: snake;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      transform-origin:0 0;
      transition-duration:0.2s;
      @media(max-width:767px){
        width:4px;
      }
    }
  }

}
@keyframes pulsate {
  0%   {left: -100%;}
  70%  {left: 100%;}
  100% {left: 100%;}
}
@keyframes snake {
  0%   {transform:scaleX(100%);}
  30%  {transform:scaleX(300%);}
  50%  {transform:scaleX(300%);}
  80%  {transform:scaleX(100%);}
  100% {transform:scaleX(100%);}
}
</style>