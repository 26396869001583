<template>
    <transition-group name="list"
                      tag="div"
                      class="v-selected-tags"
                      :class="{'is-vertical': isVertical,'is-disabled':disabled}">
      <div
          v-for="item in tags"
          class="v-selected-tags__item"
          :class="item?.isError ? 'v-selected-tags__item--error' : ''"
          :key="getKey(item)"
      >
        <div class="v-selected-tags__item-icons" v-if="!!item?.countryCode && item.countryCode!=='no_geo'">
          <v-icon-flag :code="item.countryCode" :state-code="item?.stateCode"/>
        </div>
        <span class="v-selected-tags__item-text" v-if="!noTitle">{{item?.text}}</span>
        <span
            class="v-selected-tags__item-close"
            @click="emit('removeTag',item)"
        ></span>
      </div>
    </transition-group>
</template>

<script setup>

import VIconFlag from "@app-vue/components/Base/VIconFlag.vue";

const props = defineProps({
  tags: {},
  isVertical: {type:Boolean,default:false,},
  noTitle:{type:Boolean,default:false,},
  disabled:{type:Boolean,default:false,},
});

const emit = defineEmits(['removeTag']);

function getKey(item){
  return `${item?.countryCode}`
      +`||${item?.stateCode}`
      +`||${item?.text}`;
}

</script>

<style lang="scss" scoped>
.v-selected-tags{
  @include flex(row,start,start,6px);
  flex-wrap: wrap;

  &.is-vertical{
    @include flex(column,start,start,6px);
    flex-wrap:initial;
  }

  .v-selected-tags__item{
    display:flex;
    align-items:center;
    padding:5px 9px;
    border-radius: 4px;
    background-color: #BCC7D926;
    color:$dark-80;
    cursor: default;
    min-height: 28px;
    position: relative;
    gap:6px;

    &:hover{
      background-color:rgba(28, 126, 224, 0.1);
    }

    &.list-enter-from{
      opacity:0;
    }
    &.list-enter-to{
      opacity: 1;
      transition-duration: 0.2s;
    }
    &.list-leave-from{
      opacity:1;
    }
    &.list-leave-to{
      opacity: 0;
      transition-duration: 0.2s;
    }

  }

  &.is-vertical{
    .v-selected-tags__item{
      &.list-enter-from{
        opacity:0;
        transform: translateX(30px);
      }
      &.list-enter-to{
        opacity: 1;
        transform: translateX(0px);
        transition-duration: 0.2s;
      }
      &.list-leave-from{
        transform: translateX(0px);
        opacity:1;
      }
      &.list-leave-to{
        opacity: 0;
        transform: translateX(30px);
        transition-duration: 0.2s;
      }
    }
  }

  .v-selected-tags__item-icons{
    @include flex(row,start,center,4px);
    position:relative;
  }
  .v-selected-tags__item:before{
    content:'';
    position: absolute;
    width:100%;
    height:100%;
    background:#ddecf9;
    top:0px;
    left:0px;
    border-radius: 4px;
    transform:scaleX(1.0) scaleY(1.0);
    opacity:0;
    transition-duration: 0.3s;
    transition-property: opacity, transform;
  }
  .v-selected-tags__item:hover:before{
  }
  .v-selected-tags__item-text{
    position: relative;
  }
  .v-selected-tags__item-close{
    background-image: url(/img/icons/close-icon-blue.svg);
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    background-color: #CBE3F600;
    border-radius: 50%;
    transition-duration: 0.3s;
    transition-property: background-color;
  }
  .v-selected-tags__item-close:hover{
    background-color: #cbe3f6;
  }

  .v-selected-tags__item--error,.v-selected-tags__item--error:before{
    background:#f8d8d8;
  }

  &.is-disabled{
    @include state-disabled;
  }

}
</style>