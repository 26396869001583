<template>
  <div class="v-project-bar-danger" :class="mainClass">
    <div class="text">{{text}}</div>
    <v-button v-if="buttonProps"
              class="button"
              v-bind="buttonProps"/>
  </div>
</template>

<script setup>

import VButton from "@app-vue/components/Base/VButton.vue";
import {computed} from "vue";

const props = defineProps({
  text: {default:null,},
  buttonProps: {default:null,},
  /**
   * @type {'for-preview'|null}
   */
  size:{type:String,default:null},
});

const mainClass = computed(() => {
  if(props.size){
    return 'is-size-'+props.size;
  }
})

</script>

<style lang="scss" scoped>
.v-project-bar-danger{
  @include flex(row,start,center,20px);
  height:60px;
  background:$optional-red_120;
  color:$white-100;
  padding:0px 28px;
  border-radius:8px;
  font:$font-14-semi;

  .button{
    gap:8px;
    font:$font-14-semi;
    height:40px;
  }

  &.is-size-for-preview{
    height:48px;
    @media(max-width:767px) {
      height: 44px;
      padding: 0px 18px;
    }
    .button{
      height:36px;
      @media(max-width: 767px) {
        height:32px;
      }
    }
  }

}
</style>