<template>
  <div class="v-icon-flag-tooltip">
    <div data-popper-arrow class="tooltip-flag-arrow"></div>
    <div class="names_regions">
      <div v-for="tooltipText in tooltipTexts">{{tooltipText}}</div>
    </div>
  </div>
</template>

<script setup>

import {computed} from "vue";
import {useStaticData} from "@app-vue/stores/useStaticData";
import {isArray, isString} from "lodash";

const props = defineProps({
  regions: {type:Array, default:[],},
  code: {},
  stateCode: {default:false,},
});

function getName(code,stateCode){
  if(isString(stateCode)){
    return useStaticData().getRegionByCode(code)?.name + ' (' + useStaticData().getStateByCode(code, stateCode)?.name + ')';
  }else{
    return useStaticData().getRegionByCode(code)?.name;
  }
}

const tooltipTexts = computed(() => {
  if(isArray(props.regions) && props.regions.length > 0){
    return props.regions.map(region => getName(region?.code, region?.stateCode));
  }else{
    return [getName(props.code, props.stateCode)];
  }
});

</script>

<style>

</style>

<style lang="scss" scoped>
.v-icon-flag-tooltip{
  color:$dark-80;
  padding:8px 10px;
  background: #fff;
  box-shadow: $box-shadow-tooltip;
  border-radius: 4px;

  .tooltip-flag-arrow{
    z-index:1!important;
    &:before{
      background:#fff!important;
    }
    &:after{
      content:''!important;
    }
  }

  .names_regions{
    @include flex(column,start,start,5px);
  }

}
</style>