<template>
  <div class="v-form-select-backlink-type">
    <div class="selected-item" ref="refSelectedItem" @click="refTooltipDropdown.toggle()">
      <v-form-select-backlink-type-item v-if="showSelectedCredits" :id="optionIdSelected"/>
      <v-form-input-inline :placeholder="placeholder"
                           class="selected-item__input"
                           lock-change
                           v-else
                           :value="useBacklinkOptions().getById(optionIdSelected)?.titleShort"/>
      <v-icon-svg name="select-dropdown-arrow-inline"
                  class="dropdown-icon"
                  as-img
                  :class="refTooltipDropdown?.dataShow ? 'is-to-close' : 'is-to-show'"/>
    </div>
    <v-tooltip :get-linked-block="() => refSelectedItem"
               placement="bottom"
               hide-click-outside
               reset-tooltip
               btn-close-off
               :offset="[0,0]"
               ref="refTooltipDropdown">
      <template #content>
        <v-form-select-backlink-type-dropdown :style="{width:`${widthDropdown}px`}" @select="selectItem"/>
      </template>
    </v-tooltip>
  </div>
</template>

<script setup>
import {ref, watch} from "vue";
import VFormSelectBacklinkTypeItem from "@app-vue/components/VForm/VFormSelectBacklinkTypeItem.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import VFormSelectBacklinkTypeDropdown from "@app-vue/components/VForm/VFormSelectBacklinkTypeDropdown.vue";
import {useBacklinkOptions} from "@app-vue/stores/useStaticData";
import VFormInputInline from "@app-vue/components/VForm/VFormInputInline.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";

const props = defineProps({
  showSelectedCredits: {type:Boolean,default:false,},
  placeholder: {type:String, default: null,},
});

const refSelectedItem = ref();
const refTooltipDropdown = ref();

const widthDropdown = ref();
watch(() => refTooltipDropdown.value?.dataShow, () => {
  widthDropdown.value = refSelectedItem.value.offsetWidth;
});

const optionIdSelected = defineModel({default:null});

watch(() => useBacklinkOptions().options.length, (length) => {
  if(length > 0 && optionIdSelected.value === null){
    optionIdSelected.value = useBacklinkOptions().options[0].id;
  }
});

function selectItem(optionId){
  optionIdSelected.value = optionId;
  refTooltipDropdown.value.hide();
}

</script>

<style lang="scss" scoped>
.v-form-select-backlink-type{
  .selected-item{
    @include flex(row,between,center);
    border: 1px solid #D6E3EF;
    border-radius: 4px;
    background: #fff;
    height:48px;
    padding-right:10px;
    cursor:pointer;

    .selected-item__input{
      border:none;
      cursor:pointer;
    }

    .dropdown-icon{
      transition-duration: 0.2s;
      &.is-to-close{
        transform:rotate(180deg);
      }
    }

  }

}
</style>