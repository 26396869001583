<template>
  <div class="v-filter-simple">

    <v-filter-button ref="refButton"
                     :skin="skin"
                     :has-title="hasButtonTitle"
                     :title="buttonTitle === null ? 'Filters' : buttonTitle"
                     :has-filters="hasSelectedFilter"
                     @click="refTooltip.toggle()"
                     :size="size"
                     :active="refTooltip?.tooltipOpened"/>

    <template v-if="hasSelected" v-for="(option,key) in model">
      <v-filter-simple-selected-item
          :option="model[key]"
          @remove="removeOption(key)"
          :skin="skin"
      />
    </template>

    <v-tooltip-select
        ref="refTooltip"
        v-model="model"
        :get-linked-block="() => refButton"
        :options="options"
        :multi-select="true"
        :style="{maxWidth: tooltipMaxWidth}"
        :closeAfterSelection="true"
        :display-as-input-checked="tooltipDisplayAsInputChecked"
        :tooltip-title="tooltipTitle"
        :search-enable="searchEnable"
        :search-title="'Search ' + ((buttonTitle === null) ? '' : buttonTitle)"
    />

  </div>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import VTooltipSelect from "@app-vue/components/Base/VTooltipSelect.vue";
import VFilterSimpleSelectedItem from "@app-vue/components/Base/VFilterSimpleSelectedItem.vue";
import {isArray} from "lodash";
import VFilterButton from "@app-vue/components/Base/VFilterButton.vue";

const refButton = ref();
const refTooltip = ref();

const props = defineProps({
  options: {type: Array, default: []},
  hasSelected:{type:Boolean,default:false},
  /**
   * @type {'on-white-bg'|'on-gray-bg'}
   */
  skin: {default: 'on-white-bg'},
  tooltipDisplayAsInputChecked: {type:Boolean,default:false,},
  tooltipMaxWidth: {default:'200px'},
  tooltipTitle: {default:null,},
  buttonTitle: {default:null,},
  hasButtonTitle: {type: Boolean, default:true,},
  searchEnable: {type:Boolean, default:false,},
  size:{type:String,default:'normal'},
});

const model = defineModel();

const removeOption = (key) => {
  model.value.splice(key,1);
}

const hasSelectedFilter = computed(() => {
  return isArray(model.value) && model.value.length > 0;
});

const handleEscKey = (event) => {
  if (event.key === 'Escape' || event.key === 'Esc') {
    if(refTooltip.value?.tooltipOpened){
      refTooltip.value.hide();
    }
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleEscKey);
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleEscKey);
});

</script>

<style scoped lang="scss">
.v-filter-simple{
  display: flex;
  flex-wrap:wrap;
  gap: 10px;
}
</style>