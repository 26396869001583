<template>
  <div class="v-circle-state vcs"
       :class="mainClass"
       :style="{width:`${sizeNumber}px`,height:`${sizeNumber}px`}"
       v-text="showValue"/>
</template>

<script setup>

import {computed} from "vue";
import {isNumber} from "lodash";

const props = defineProps({
  /**
   * @type {number|null}
   */
  value: {},
  /**
   * @type {'bad'|'good'|'normal'}
   */
  color: {},
  /**
   * @type {'big'|'small'|'middle'|Number}
   */
  size: {default:'big'},
});

const sizeNumber = computed(() => {
  if(isNumber(props.size)) return props.size;
  switch(props.size){
    case 'big' : return 90;
    case 'middle' : return 60;
    case 'small' : return 42;
  }
});

const sizeStep = computed(() => {
  if(sizeNumber.value >= 90){
    return 'big-1';
  }else if(sizeNumber.value >= 66){
    return 'middle-2';
  }else if(sizeNumber.value >= 60){
    return 'middle-1';
  }else if(sizeNumber.value >= 42){
    return 'small-3';
  }else if(sizeNumber.value >= 34){
    return 'small-2';
  }else if(sizeNumber.value >= 24){
    return 'small-1';
  }
});

const isNotAvailable = computed(() => {
  return !props.value && props.value !== 0;
});

const showValue = computed(() => {
  if(isNotAvailable.value || props.value === null){
    return 'N/A';
  }else{
    return props.value;
  }
});

const mainClass = computed(() => {
  let response = [];
  response.push('vcs--'+sizeStep.value);
  if(!isNotAvailable.value) response.push('vcs--'+props.color);
  if(isNotAvailable.value) response.push('vcs--is-not-available');
  return response;
})

</script>

<style lang="scss" scoped>
.v-circle-state.vcs{
  @include flex(row,center,center);
  border-style:solid;
  border-radius: 50%;
  font-weight: 600;

  &.vcs--is-not-available{
    background-color:$dark-5;
    border-color: #f1f2f7;
    color: #939CAC;
  }

  &.vcs--small-1{
    font-size:12px;
    border-width: 2px;
    letter-spacing: -0.04em;
    line-height:12px;
    &.vcs--is-not-available{font-size:8px;font-weight: 600;}
  }
  &.vcs--small-2{
    font-size:14px;
    border-width: 2px;
    &.vcs--is-not-available{font-size:10px;}
  }
  &.vcs--small-3{
    font-size:18px;
    border-width: 2.5px;
    &.vcs--is-not-available{font-size:10px;}
  }
  &.vcs--middle-1{
    font-size:22px;
    border-width: 4px;
    &.vcs--is-not-available{font-size:14px;}
  }
  &.vcs--middle-2{
    font-size:24px;
    border-width: 4px;
    &.vcs--is-not-available{font-size:16px;}
  }
  &.vcs--big-1{
    font-size: 32px;
    border-width: 6px;
    &.vcs--is-not-available{font-size:24px;}
  }

  &.vcs--good{
    background-color:#E5F2E5;
    border-color: #0000000D;
    color: #219E26;
  }
  &.vcs--normal{
    background-color: #FDF4EB;
    border-color: #0000000D;
    color: #EF8E34;
  }
  &.vcs--bad{
    background-color: #FFEBED;
    border-color: #0000000D;
    color:#F2001D;
  }
}
</style>