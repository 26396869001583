<template>
  <div class="vts__tooltip" :class="mainClasses">
    <div class="vts__item vts__tooltip-title" v-if="tooltipTitle!==null">
      {{tooltipTitle}}
    </div>
    <div class="vts__item vts__tooltip-search" v-if="searchEnable">
      <v-search-bar
          :placeholder="searchTitle"
          v-model="searchOptions"
          skin="built-in"
      />
    </div>
    <template v-for="option in optionsFiltered">
      <div
          class="vts__item"
          @click="toggleOptionToModel(option)"
          :class="optionIsSelected(option) ? 'vts__item--selected' : ''"
      >
        <v-form-checkbox
            v-if="displayAsInputChecked"
            :value="optionIsSelected(option)"
            only-show
        />

        <div class="vts__item-content">
          <v-icon-svg-wrapped v-if="option.iconData" v-bind="option.iconData"/>
          <span class="vts__item-name">
              {{option.name}}
              <span v-if="option.label" class="vts__item-label">
                {{option.label}}
              </span>
            </span>
        </div>

        <v-icon-svg
            v-if="!displayAsInputChecked"
            name="select-checked"
            class="vts__item-svg"
        />

      </div>
    </template>
  </div>
</template>

<script setup>
import VSearchBar from "@app-vue/components/Base/VSearchBar.vue";
import VFormCheckbox from "@app-vue/components/VForm/VFormCheckbox.vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed, ref} from "vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";

const props = defineProps({
  options: {},
  multiSelect: {type:Boolean,default:false},
  displayAsInputChecked: {type:Boolean, default:false},
  tooltipTitle: {default:null,},
  searchEnable: {default:false,},
  searchTitle: {default:null,},
});

const model = defineModel();
const emit = defineEmits(['change']);

const searchOptions = ref('');

const mainClasses = computed(() => {
  return props.displayAsInputChecked ? 'vts__tooltip--as-input-checked' : '';
});

const optionsFiltered = computed(() => {
  if(searchOptions.value!== null && searchOptions.value.trim().length > 0){
    return props.options.filter((option) => {
      return option.name.toLowerCase().includes(searchOptions.value.trim().toLowerCase());
    });
  }else{
    return props.options;
  }
});

const toggleOptionToModel = (option) => {

  if(props.multiSelect){
    let index = modelIndexOf(option);
    if(index === -1){
      model.value.push(option);
    }else{
      model.value.splice(index,1);
    }
  }else{
    if(model.value?.value !== option.value){
      model.value = option;
    }
  }

  emit('change', option);

}

const modelIndexOf = (option) => {
  let findKey = -1;
  model.value.some((item,key) => {
    if(item.value === option.value){
      findKey = key;
      return true;
    }
  });
  return findKey;
}

const optionIsSelected = (option) => {
  if(props.multiSelect){
    return modelIndexOf(option) !== -1;
  }else{
    return model.value?.value === option.value
  }
}

</script>

<style lang="scss" scoped>
.vts__tooltip{
  min-width: 150px;
  .vts__item{
    padding:0px 10px;
    height:40px;
    transition-property: background-color;
    transition-duration: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .vts__item-name{
      display: flex;
      align-items: center;

      .vts__item-label{
        font-size:12px;
        color:#596C8F;
        margin-left:4px;
      }
    }

    .vts__item-content{
      @include flex(row,start,center,9px);
    }

    &:hover{
      background-color: #F4F9FD;
    }

    .vts__item-svg{
      width:20px;
      height:20px;
      opacity:0;
    }

    &.vts__item--selected{
      .vts__item-svg{
        opacity:1;
      }
    }

  }

  &.vts__tooltip--as-input-checked{
    .vts__item{
      padding:0px 16px;
      min-height:44px;
      height:initial;
      justify-content:flex-start;
      gap:16px;
      .vts__item-name{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color:#384967;
      }
      &.vts__tooltip-title{
        font-size: 12px;
        font-weight: 400;
        color:#798CAE;
        min-height:initial;
        height:initial;
        padding-top:8px;
        padding-bottom:8px;
        &:hover{
          background-color:#fff;
          cursor:initial;
        }
      }
      &.vts__tooltip-search{
        padding:0px;
        border-bottom: 1px solid #D6E3EF80;
        &:hover{
          background-color:#fff;
          cursor:initial;
        }
      }
    }
  }
}
</style>