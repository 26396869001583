import {defineStore} from "pinia";
import {formatDateDDMMYYYY, getObjectDateFromDDMMYYY} from "@app-vue/utils/utils";
import {ApiResource} from "@app-vue/plugins/ApiExtends";
import {isArray} from "lodash";
import {MD5} from "@app-vue/utils/md5";
import {useBillingCart, useCreditTypes} from "@app-vue/stores/useBilling";
import {useRoutes} from "@app-vue/stores/useRoutes";

/**
 * @typedef {Object} CreditData
 * @property {number} amount
 * @property {string} type
 * @property {number} credit_type_id.
 */

/**
 * @typedef {Object} BonusItem
 * @property {number} amount
 * @property {string} type
 */

export const useBillingHistory = defineStore('billing-history', {
    state: () => {
        return {
            itemsIds: {},
            groups: [],
            filters: {
                dateSort: 'desc',
                searchString: '',
                onlyPayment: false,
            }
        }
    },
    getters: {
        sortedGroups: (state) => {
            if(state.filters.dateSort === 'desc'){
                return state.groups.slice().sort((groupA,groupB) => groupB.dateObject - groupA.dateObject);
            }else{
                return state.groups.slice().sort((groupA,groupB) => groupA.dateObject - groupB.dateObject);
            }
        },
    },
    actions: {
        async download(){

            let resource = new ApiResource({
                url:    `/api/get-billing-history`,
                method: 'get',
            });

            let [status,data] = await resource.downloadAsync();
            //let [status,data] = [true,billingHistoryExample];

            if(status && isArray(data)){
                this.$patch((state) => {
                    data.forEach((item) => {
                        if(item.type === 'spending'){
                            useBillingHistorySpending(item.id).init(item);
                            state.itemsIds[item.id] = item.type;
                        }else if(item.type === 'payment'){
                            useBillingHistoryPayment(item.id).init(item);
                            state.itemsIds[item.id] = item.type;
                        }else if(item.type === 'trial'){
                            useBillingHistoryTrial(item.id).init(item);
                            state.itemsIds[item.id] = item.type;
                        }
                    });
                });
                this.createGroups();
                return [true];
            }else{
                return [false];
            }

        },
        addPayment(item){
            //todo: тут нужно вынести создание группы в отдельную функцию
            useBillingHistoryPayment(item.id).init(item);
            this.$patch((state) => state.itemsIds[item.id] = item.type);

            let simpleObject = {
                id: item.id,
                type: item.type,
                dateObject: this.getObjectItem(item.id,item.type).dateObject,
            }

            let itemDateFormatted = formatDateDDMMYYYY(simpleObject.dateObject);

            let group = {
                type: simpleObject.type,
                dateFormatted: itemDateFormatted,
                ids: [simpleObject.id],
            };

            let uniqueKey = MD5(group.ids.slice().sort((a, b) => a - b).join('|'));

            this.$patch((state) => {
                state.groups.push(useBillingHistoryGroup(uniqueKey).init(group));
            });

        },
        createGroups(){
            let simpleObjects = [];

            this.$patch({groupsIds: [],})

            Object.keys(this.itemsIds).forEach((id) => {
                simpleObjects.push({
                    id: id,
                    type: this.itemsIds[id],
                    dateObject: this.getObjectItem(id,this.itemsIds[id]).dateObject,
                });
            });

            simpleObjects.sort((a,b) => b.dateObject - a.dateObject);

            // группируем spending, у которых одна дата, и они идут подряд
            let groupsSimpleObjects = simpleObjects.reduce((groups, item) => {
                let itemDateFormatted = formatDateDDMMYYYY(item.dateObject);
                if(
                    groups.length === 0
                    || item.type === 'payment'
                    || item.type === 'trial'
                    || groups.at(-1).type !== item.type
                    || groups.at(-1).dateFormatted !== itemDateFormatted
                ){
                    groups.push({
                        type: item.type,
                        dateFormatted: itemDateFormatted,
                        ids: [],
                    });
                }
                groups[groups.length - 1].ids.push(item.id);
                return groups;
            }, []);

            this.$patch((state) => {
                groupsSimpleObjects.forEach((item) => {
                    let uniqueKey = MD5(item.ids.slice().sort((a, b) => a - b).join('|'));
                    state.groups.push(useBillingHistoryGroup(uniqueKey).init(item));
                });
            });

        },
        changeSort(){
            this.$patch((state) => state.filters.dateSort = state.filters.dateSort === 'desc' ? 'asc' : 'desc');
        },
        getObjectItem(id,type){
            switch(type){
                case 'spending': return useBillingHistorySpending(id);
                case 'payment': return useBillingHistoryPayment(id);
                case 'trial': return useBillingHistoryTrial(id);
            }
        },
        async getHistoryByOrderId(orderId){
            let resource = new ApiResource({
                'url': '/api/v1/billing/get-history-by-order',
                'params': {
                    orderId: orderId,
                }
            });

            let [status,data] = await resource.downloadAsync();

            if(status && Array.isArray(data) && data.length > 0 && typeof data[0] === 'object' && data[0] !== null){
                return data[0]; //object order
            }else{
                return null;
            }
        }
    },
});

export const useBillingHistoryGroup = (uniqueKey) => defineStore("billing-history-group-"+uniqueKey, {
    state: () => {
        return {
            uniqueKey: uniqueKey,
            dateFormatted: null,
            type: null,
            ids: [],
            projectAndCreditsExpanded: false,
        }
    },
    getters: {
        dateObject: (state) => getObjectDateFromDDMMYYY(state.dateFormatted),

        isPayment: (state) => state.type === 'payment',
        isSpending: (state) => state.type === 'spending',
        isTrial: (state) => state.type === 'trial',

        isShow: function(state){
            if(useBillingHistory().filters.onlyPayment && !this.isPayment && !this.isTrial) return false;

            if(typeof useBillingHistory().filters.searchString === 'string') {
                let searchString = useBillingHistory().filters.searchString.trim();

                if(searchString.length > 0){

                    if(searchString === state.dateFormatted) return true;

                    if(this.isSpending){
                        return this.spendingsProjects.some((project) => project.title == searchString);

                    }else if(this.isPayment){
                        return this.payment.dataPayment.orderId == searchString;

                    }else{
                        return false;
                    }

                }
            }

            return true;
        },

        paymentId: function(state){
            return this.isPayment ? state.ids[0] : null;
        },

        payment: function(state) {
            return this.paymentId ? useBillingHistoryPayment(this.paymentId) : null;
        },
        spendings: function(state){
            return this.isSpending ? state.ids.map((id) => useBillingHistorySpending(id)) : null;
        },
        trials: function(state){
            return this.isTrial ? state.ids.map((id) => useBillingHistoryTrial(id)) : null;
        },

        spendingsProjects: function(state){
            return this.spendings.map((spending) => {
                return {
                    link: spending.projectLink,
                    title: spending.projectUniquekey,
                }
            });
        },
        spendingsProjectsAndCredits: function(state){
            return this.spendingsProjects.map((project, index) => {
                return {project: project, credit: this.spendingListCredits[index]}
            });
        },

        listCredits: function(state){
            if(this.isPayment) return this.paymentListCredits;
            else if(this.isSpending) return this.spendingListCredits;
            else if(this.isTrial) return this.trialListCredits;
            else return null;
        },
        spendingListCredits: function(state){
            return this.isSpending ? this.spendings.map((spending) => {
                return [spending.credit.amount,spending.credit.type,]
            }) : null;
        },
        paymentListCredits: function(state){
            return this.isPayment ? this.payment.credits.map((credit) => {
                return [credit.amount, credit.type];
            }) : null;
        },
        trialListCredits: function(state){
            return this.isTrial ? this.trials.map((trial) => {
                return [trial.credit.amount,trial.credit.type,]
            }) : null;
        },
    },
    actions: {
        init(data){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                });
            });
            return this;
        },
    }
})();

export const useBillingHistorySpending = (id) => defineStore('billing-history-spending-'+id, {
    state: () => {
        return {
            id: id,
            type: null,
            date: null,
            credit: {
                amount: null,
                type: null,
            },
            projectLink: null,
            projectId: null,
            projectUniquekey: null,
        }
    },
    getters: {
        dateObject: (state) => new Date(state.date),
    },
    actions: {
        init(data){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                });
            });
            return this;
        }
    },
})();

export const useBillingHistoryPayment = (id) => defineStore('billing-history-payment-'+id, {
    state: () => {
        return {
            id:id,
            type:null,
            paymentStatus: null,
            date: null,
            couponApplied: null,
            isAjaxRetryable: null,
            /**
             * @type {CreditData[]}
             */
            credits: [],
            linkForRenew: null,
            dataPayment:{
                type: null,
                orderId: null,
                amount: null,
                linkInvoice: null,
            },
            recoverUrl: null,
            subscription: {
                id: null,
                external_id: null,
                status:null,
                /**
                 * @type {BonusItem[]}
                 */
                bonuses: [],
            },
        }
    },
    getters: {
        dateObject: (state) => new Date(state.date),
        couponAppliedCorrect: (state) => {
            if (!state.coupon) return null;
            if(
                (typeof state.coupon === 'string' && state.coupon.trim().length > 0)
                || typeof state.coupon === 'number'
            ){
                return state.coupon;
            }
            return null;
        },
        isDeclined: (state) => state.paymentStatus === 'declined',
        isPending: (state) => state.paymentStatus === 'pending',
        subscriptionStatusOk: (state) => state.subscription.status === 'ok',
        subscriptionStatusCancelled: (state) => state.subscription.status === 'cancelled' || state.subscription.status === 'canceled',
        subscriptionStatusHaveNo: (state) => state.subscription.status === 'haveno',
        subscriptionLink: (state) => state.subscription.external_id ? useRoutes().url('subscription', {id: state.subscription.external_id}) : '',
        linkInvoiceCorrect: (state) => {
            if(!state.dataPayment.linkInvoice || state.dataPayment.linkInvoice === '' || state.dataPayment.linkInvoice === '#'){
                return null;
            }else{
                return state.dataPayment.linkInvoice;
            }
        }
    },
    actions: {
        init(data){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                });
            });
            return this;
        },
        async collectShoppingCart(){
            await useBillingCart().deleteAllItems();

            let queries = [];
            this.credits.forEach((credit) => {
                let planOptions = useCreditTypes().getPlanOptionsByCreditTypeId(credit.credit_type_id);
                if(!planOptions || !credit.amount) return;
                queries.push(useBillingCart().add(planOptions.id, credit.amount, false));
            });
            let countAdded = queries.length;

            if(this.couponAppliedCorrect){
                queries.push(useBillingCart().saveCoupon(this.couponAppliedCorrect));
            }

            await Promise.all(queries);

            useBillingCart().updateMenuTopCartCounter(countAdded);

            return [true];
        },
        async collectShoppingCartAndRedirect(){
            if(this.linkForRenew === null){
                await this.collectShoppingCart();
                useRoutes().redirect('buy-credits',{},{scrollToCart: 1})
            }else{
                window.location.href = this.linkForRenew;
            }
        },
        async retryPayment(){
            let resource =  new ApiResource({
                url:    `/api/v1/orders/${this.dataPayment.orderId}/retry-payment`,
                method: 'post'
            });

            let [status,data] = await resource.downloadAsync();

            if(status){
                return data.orderId;
            }else{
                return null;
            }

        },
    },
})();

export const useBillingHistoryTrial = (id) => defineStore('billing-history-trial-'+id,{
    state: () => {
        return {
            id:id,
            type:null,
            date: null,
            credit: {
                amount: null,
                type: null,
                credit_type_id: null,
            },
        }
    },
    getters: {
        dateObject: (state) => new Date(state.date),
    },
    actions: {
        init(data){
            this.$patch((state) => {
                Object.entries(data).forEach(([key,value]) => {
                    if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                });
            });
            return this;
        }
    },
})();
