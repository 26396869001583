<template>
  <div class="v-search-bar-v2"
       :class="mainClasses"
       @mousedown="handleWrapMousedown">

    <v-icon-svg name="magnifier-v2"
                class="icon-magnifier"
                as-block
                as-img/>

    <input class="search-bar-input"
           :placeholder="placeholder"
           @focusin="inputIsFocus = true;"
           @focusout="inputIsFocus = false"
           v-model="model"
           ref="refInput"/>

    <v-icon-svg-wrapped name="close-icon-blue2"
                        class="icon-close"
                        :class="{'opacity-0': modelLength === 0, 'opacity-100': modelLength > 0}"
                        as-img
                        @click.prevent="model = ''; refInput.blur()"
                        :outer-size="16"
                        :inner-size="10"/>

  </div>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed, ref} from "vue";
import VIconSvgWrapped from "@app-vue/components/Base/VIconSvgWrapped.vue";

const model = defineModel();

const props = defineProps({
  placeholder: {type: String},
});

const refInput = ref();
const inputIsFocus = ref(false);
const modelLength = computed(() => {
  if (typeof model.value !== 'string') {
    return 0;
  } else {
    return model.value.length
  }
});

const mainClasses = computed(() => {
  let classes = [];
  if(inputIsFocus.value) classes.push('is-focus');
  return classes;
})

function handleWrapMousedown(e){
  if(e.target !== refInput.value){
    if (document.activeElement === refInput.value) {
      e.preventDefault();
    }else{
      setTimeout(() => {
        refInput.value.focus();
      },10);
    }
  }
}

</script>

<style scoped lang="scss">
.v-search-bar-v2{
  @include flex(row,between,center,6px);
  padding:0px 12px;
  background:#fff;
  border-radius: 50px;
  border:1px solid transparent;
  transition: border 0.2s;

  &.is-focus{
    border-color:$secondary-blue-100
  }

  .search-bar-input{
    border:none;
    outline:0px;
    flex-grow:2;
    &::placeholder{
      color:$dark-30;
    }
  }

  .icon-magnifier{
  }

  .icon-close{
    transition: opacity 0.2s;
    cursor:pointer;
  }

}
</style>