<template>
  <div class="v-faq-list-item vfli">
    <div class="vfl__question" @click="trigger">
      <div class="vfl__question-icon">
        <v-icon-svg name="faq-blue-plus" v-show="!opened"/>
        <v-icon-svg name="faq-blue-minus" v-show="opened"/>
      </div>
      <div class="vfl__question-text" v-html="question"/>
    </div>
    <v-transition-slide>
      <div class="vfli__response" v-show="opened">
        <span v-html="answer"/>
      </div>
    </v-transition-slide>
  </div>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {ref} from "vue";
import VTransitionSlide from "@app-vue/components/Base/VTransitionSlide.vue";

const props = defineProps({
  question: {},
  answer: {},
});

const opened = ref(false);

const emit = defineEmits(['userTrigger']);



const trigger = () => {
  opened.value = !opened.value;
  emit('userTrigger', opened);
}

defineExpose({opened});

</script>

<style lang="scss" scoped>
.v-faq-list-item.vfli{
  border-bottom: 1px solid #D6E3EF;

  .vfl__question{
    cursor: pointer;
    display: flex;
    gap:10px;
    padding:16px 16px 16px 0px;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #1C7EE0;

    .vfl__question-icon{
      width:18px;
      height:18px;
      flex-shrink: 0;

    }
    &:hover{
      color:#186CBF;
    }
  }
  .vfli__response{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #384967;
    overflow:hidden;
    span{
      display:block;
      padding-bottom:20px;
    }
  }
}
</style>