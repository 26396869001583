<template>
  <div class="v-form-select-inline vfsi" :class="mainClass" ref="refWrap">
    <div class="vfsi__input-wrap">
      <v-form-input-inline
          :placeholder="placeholder"
          :required="required"
          v-model="inputModel"
          ref="refInput"
          class="vfsi__input"
          :disabled="disabled"
          :lock-change="!enableSearch"
          @click="!enableSearch ? opened = !opened : ''"
          :icon-value="iconValue"
      />
    </div>
    <div class="vfsi__icon-arrow-wrap" @click="opened = !opened">
      <v-icon-svg name="select-dropdown-arrow-inline" class="vfsi__icon-arrow"/>
    </div>
    <v-form-select-dropdown
        ref="refDropdown"
        :get-linked-block="() => refWrap"
        :options="filteredOptions"
        v-model="selectModel"
        :width="inputWidth"
        @change="change"
        :option-min-height="optionMinHeight"
    />
  </div>
</template>

<script setup>

import VFormInputInline from "@app-vue/components/VForm/VFormInputInline.vue";
import {computed, onMounted, ref, watch} from "vue";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import VFormSelectDropdown from "@app-vue/components/VForm/VFormSelectDropdown.vue";

const inputModel = ref('');

const emit = defineEmits(['change']);

const props = defineProps({
  placeholder: {},
  required: {type: Boolean, default: false},
  hasEmptyOption: {type: Boolean, default: true},
  options: {},
  isError: {default: false, type: Boolean},
  disabled: {type: Boolean, default: false},
  enableSearch: {type: Boolean, default: true},
  iconValue: {default:null,},
  nameNotSelected: {type:String, default: 'Not selected',},
  optionMinHeight: {type:Number,default:null,},
});

const selectModel = defineModel();

const refWrap = ref();
const refDropdown = ref();
const refInput = ref();
const opened = ref(false);
const inputWidth = ref();
const filteredInput = ref('');

const mainClass = computed(() => {
  let classes = [];
  if (opened.value) classes.push('vfsi--opened');
  if (props.isError) classes.push('vfsi--is-error');
  if (props.disabled) classes.push('vfsi--disabled');
  return classes;
});

/**
 * Отслеживаем фокус в переменную opened
 */
watch(() => refInput?.value?.isFocus, (value, oldValue, onCleanup) => {
  if (value === true) {
    opened.value = true;
  }
});

const updateDropdownWidth = () => {
  inputWidth.value = refWrap.value.offsetWidth;
}

onMounted(() => {
  updateDropdownWidth();
});

function checkOutsideClick(event){
  if (!refWrap.value.contains(event.target) && !refDropdown.value.$el.contains(event.target)) {
    opened.value = false;
  }
}

watch(() => opened.value, (value, oldValue, onCleanup) => {

  updateDropdownWidth();

  if (value && !oldValue) {

    document.addEventListener('click', checkOutsideClick);

    if(props.enableSearch){
      inputModel.value = '';
      filteredInput.value = '';
    }

    refDropdown.value.show();

  } else if (!value && oldValue) {

    inputModel.value = selectModel.value?.name ?? '';
    refDropdown.value.hide();

    document.removeEventListener('click', checkOutsideClick);

  }
});

watch(() => [selectModel.value, props.options], ([value,options], oldValue, onCleanup) => {

  if (value?.value === null && !props.required) {
    selectModel.value = null;
    inputModel.value = ''
  } else if (value?.name) {
    inputModel.value = value.name;
  } else if (!value?.name) {
    let option = options.find((option) => option?.value === value?.value);
    if(option){
      inputModel.value = option.name;
    }else{
      inputModel.value = '';
    }
  } else {
    inputModel.value = ''
  }
}, {deep: true, immediate: true});


const filteredOptions = computed(() => {
  let filtered = [];
  if (props.hasEmptyOption && !props.required) {
    filtered.push({
      value: null,
      name: props.nameNotSelected,
    })
  }
  if(props.enableSearch){
    let filteredOptions = props.options.filter((item) => {
      return item.name.toLowerCase().includes(filteredInput.value.toLowerCase())
    });
    return [...filtered, ...filteredOptions];
  }else{
    return [...filtered, ...props.options];
  }

});

watch(() => inputModel.value, (value, oldValue, onCleanup) => {
  if (opened.value) {
    filteredInput.value = value;
  }
});

const change = (option) => {
  opened.value = false;
  emit('change', option);
}

</script>

<style scoped lang="scss">
.v-form-select-inline.vfsi {
  background: #fff;
  display: flex;
  width: 100%;
  align-items: center;

  border: 1px solid #D6E3EF;
  border-radius: 4px;

  .vfsi__input-wrap {
    width: 100%;

    .vfsi__input {
      border: none;
    }
  }

  .vfsi__icon-arrow-wrap {
    flex-shrink: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .vfsi__icon-arrow {
      display: block;
      width: 16px;
      height: 16px;
      cursor: pointer;
      transform: rotateZ(0deg);
      transition-duration: 0.2s;
    }
  }

  &.vfsi--opened {
    .vfsi__icon-arrow-wrap {
      .vfsi__icon-arrow {
        transform: rotateZ(180deg);
      }
    }
  }

  &.vfsi--is-error {
    border-color: #F2001D;
  }

  &.vfsi--disabled {
    position: relative;
    border: 1px solid #D6E3EF;
    cursor: initial;
    background-color: #F9FAFA;

    .vfsi__icon-arrow-wrap {
      opacity: 0.5
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      display: block;
      z-index: 99;
    }
  }

}
</style>