<template>
  <div class="v-wic-button" ref="refButton" @click="refWhatIsCreditTooltip.toggle()">

    <v-icon-question-tooltip/>

    <span>
      <slot name="text">What is 1 credit</slot>
    </span>

    <v-tooltip ref="refWhatIsCreditTooltip"
               :get-linked-block="() => refButton"
               v-bind="propsTooltip">
      <template #content>
        <slot name="tooltip"/>
      </template>
    </v-tooltip>

  </div>
</template>

<script setup>
import VIconQuestionTooltip from "@app-vue/components/Base/VIconQuestionTooltip.vue";
import VTooltip from "@app-vue/components/Base/VTooltip.vue";
import {computed, ref} from "vue";

const props = defineProps({
  tooltip:{type:Object,},
});

const refButton = ref();
const refWhatIsCreditTooltip = ref();

const propsTooltip = computed(() => {
  return Object.assign({
    placement: 'bottom',
    btnCloseOff: true,
    hideClickInnerTooltip: false,
    resetTooltip: true,
  }, props.tooltip);
})

</script>

<style lang="scss" scoped>
.v-wic-button{
  @include flex(row,center,center,10px);
  padding:15px 10px;
  background:#F6FBFF;
  border-radius:4px;
  cursor:pointer;
  color:$secondary-blue-100;
}
</style>