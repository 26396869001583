<template>
  <div class="v-bh-payment-type" :class="'is-'+type" v-text="showTitle"/>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  /**
   * @type {'spending'|'payment'|'trial'}
   */
  type: {type:String},
});

const showTitle = computed(() => {
  switch(props.type){
    case 'spending' : return '- SPENDING';
    case 'payment' : return '+ PAYMENT';
    case 'trial' : return '+ PAYMENT';
    default: props.type;
  }
})

</script>

<style lang="scss" scoped>
.v-bh-payment-type{
  @include flex(row,center,center);
  border-radius:4px;
  font-size: 11px;
  font-weight: 500;
  width:73px;
  height:23px;
  &.is-spending{
    background: $secondary-blue-5;
    color:$secondary-blue-100;
  }
  &.is-payment, &.is-trial{
    background: $primary-green-5;
    color: $primary-green-100;
  }
}
</style>