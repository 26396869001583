<template>
  <div class="v-filter-button" :class="mainClasses">
    <div class="filter-icon" :class="{'filter-icon--active': hasFilters}">
      <v-icon-svg v-if="isCircle" name="filter-v4" as-img as-block/>
      <v-icon-svg v-else name="filter-v4" as-img as-block/>
    </div>
    <div class="title" v-if="hasTitle && !isCircle" v-text="title"/>
  </div>
</template>

<script setup>
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed} from "vue";

const props = defineProps({
  /**
   * @type {'on-white-bg'|'on-gray-bg'}
   */
  skin: {default: 'on-white-bg'},
  hasFilters: {default:false,type:Boolean},
  hasTitle: {default:true,type:Boolean},
  title: {default: 'Filters',},
  active: {default:false,type:Boolean},
  isCircle: {default:false, type:Boolean},
  /**
   * @type {'normal'|'big'}
   */
  size: {default:'normal',type:String},
});

const mainClasses = computed(() => {
  let classes = [];
  classes.push(`v-filter-button--skin-${props.skin}`);
  classes.push(`v-filter-button--size-${props.size}`);
  if(props.active) classes.push('v-filter-button--is-active');
  if(props.isCircle) classes.push('v-filter-button--is-circle');
  if(props.hasFilters) classes.push('v-filter-button--has-filters');
  return classes;
});

</script>

<style lang="scss" scoped>
.v-filter-button{
  @include flex(row,center,center,6px);
  padding:0px 10px;
  height:28px;
  background: #F4F9FD;
  border-radius: 50px;
  cursor: pointer;
  min-width:40px;

  &.v-filter-button--size-big{
    padding:0px 12px;
    height:40px;
  }

  .filter-icon{
    position: relative;
    &.filter-icon--active:after{
      content:'';
      display:block;
      position:absolute;
      top:-2.5px;
      right:-4px;
      width:8px;
      height:8px;
      border-radius:50%;
      background:$secondary-blue-80;
      border:1px solid $white-100;
    }
  }

  .title{
    color:$dark-50;
    font-size:14px;
    line-height:18px;
  }

  &.v-filter-button--skin-on-gray-bg{
    background:#E0EAF2;
    transition-duration: 0.2s;
    &:hover{
      background:#D6E3EF;
    }
    &.v-filter-button--is-active{
      background:#D6E3EF;
    }
  }

  &.v-filter-button--is-circle{
    position:relative;
    border-radius:50%;
    width:32px;
    height:32px;
    padding:0px;
    min-width:32px;
    &.v-filter-button--size-big{
      width:40px;
      height:40px;
      min-width:40px;
    }
  }
}
</style>