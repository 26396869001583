<template>
  <div class="v-search-bar vsb" :class="mainClasses">
    <input
        class="vsb__input"
        :placeholder="placeholder"
        v-model="model"
        @focusin="inputFocused=true"
        @focusout="inputFocused=false"
        ref="refInput"
    />
    <div class="vsb__icon">
      <v-icon-svg
          name="magnifier"
          class="vsb__icon-svg"
          :class="!inputFocused && modelLength === 0 ? 'vsb__icon-svg--show' : ''"
          @click="refInput.focus()"
      />
      <v-icon-svg
          name="icon-close-search"
          class="vsb__icon-svg vsb__icon-svg--close"
          :class="inputFocused || modelLength > 0 ? 'vsb__icon-svg--show' : ''"
          @click="model=''"
      />
    </div>
  </div>
</template>

<script setup>

import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";
import {computed, ref} from "vue";

const model = defineModel();

const props = defineProps({
  placeholder: {type: String},
  /**
   * @type {'gray-on-white'|'white-section'|'built-in'|'gray-on-gray'}
   */
  skin: {default: 'gray-on-white'}
});

const refInput = ref();
const inputFocused = ref(false);
const modelLength = computed(() => {
  if (typeof model.value !== 'string') {
    return 0;
  } else {
    return model.value.length
  }
});

const mainClasses = computed(() => {
  let classes = [];
  if(inputFocused.value){
    classes.push('vsb--focus');
  }
  classes.push('vsb--skin-'+props.skin);
  return classes;
})

</script>

<style scoped lang="scss">
.v-search-bar.vsb{
  display: flex;
  align-items: center;
  position:relative;
  padding:13px 12px;
  background-color: #F9FBFD;
  gap:10px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition-property: border;
  transition-duration: 0.2s;

  &.vsb--focus{
    border: 1px solid #1C7EE0;
  }

  .vsb__icon{
    flex-shrink: 0;
    position: relative;
    width:20px;
    height:20px;

    .vsb__icon-svg{
      position:absolute;
      fill:#0D4E8F;
      opacity:0;
      transition-duration:0.2s;
      z-index:1;
      &.vsb__icon-svg--show{
        opacity:0.5;
        z-index:2;
      }
      &.vsb__icon-svg--close.vsb__icon-svg--show{
        cursor: pointer;
      }
    }
  }

  .vsb__input{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    padding-left: calc(32px + 10px);
    background: none;
    border:none;
    &:focus{
      outline:0;
    }
  }

  &.vsb--skin-white-section{
    background-color: #fff;
    box-shadow: $box-shadow-mini;
  }

  &.vsb--skin-built-in{
    width:100%;
    background:transparent;
    border:none;
    &.vsb--focus{
      border: none;
    }
  }

  &.vsb--skin-gray-on-gray{
    background:#E0EAF2;
    .vsb__input::placeholder{
      font:$font-14-regu;
      color:$dark-30;
    }
    .vsb__icon-svg{
      fill:$dark-30;
      &.vsb__icon-svg--show{
        opacity:1;
      }
      &.vsb__icon-svg--show.vsb__icon-svg--close{
        opacity:0.6;
      }
    }
  }

}
</style>