<template>
  <component :is="tag"
             class="v-button"
             :class="mainClass"
             ref="refButton"
             @click="buttonClick"
             :data-allow="allowForDemoUser ? 'demo-user' : ''">
    <slot v-if="!loadingIndicator">
      <v-icon-svg v-if="icon" :name="icon" as-img/>
      {{text}}
    </slot>
    <v-loader-ship
        v-if="loadingIndicator"
        class="v-button__loader"
        :style="{width:loaderSize+'px', height: loaderSize+'px'}"
        :line-thickness="loaderThickness"
        :color-line-one="loadingColorOne"
        :color-line-two="loadingColorTwo"
    />
  </component>
</template>

<script setup>
import VLoaderShip from "@app-vue/components/Base/VLoaderShip.vue";
import {computed, ref, watch} from "vue";
import {processFunction} from "@app-vue/utils/utils";
import {isArray, isFunction} from "lodash";
import VIconSvg from "@app-vue/components/Base/VIconSvg.vue";

const refButton = ref();
const emit = defineEmits(['click']);

const props = defineProps({
  tag: {type:String, default:'button'},
  /**
   * @type {'blue'|'green'|'link'|'link2'|'blue2'|'blue2-stroke'|'red'|'white-stroke'|'green-stroke'|'green-cancel'|'white-stroke-50'|'green2'|'red-delete'}
   */
  color: {type:String, default: 'blue'},
  isBold: {type:Boolean,default:false},

  loading:{type:Boolean,default:false},
  disabled: {type:Boolean,default:false},

  /**
   * @type {Function | [Function, ...*]} Функция, которую нужно выполнить после нажатия кнопки
   */
  clickAction: {default:null,},
  /**
   * @type {Function} Функция вызывается, когда clickAction будет выполнен
   */
  afterAction: {default:null,},
  beforeAction:{default:null,},

  text: {default:null},
  icon: {default:null},
  allowForDemoUser: {type:Boolean, default:false,}
});

const loadingLocal = ref(false);
const loadingIndicator = computed(() => loadingLocal.value || props.loading);

const loaderSize = ref(24);
const loaderThickness = ref(3);

const buttonClick = (e) => {
  if(props.disabled) return false;
  if(props.loadingIndicator) return false;

  if(props.clickAction !== null){

    if(isFunction(props.beforeAction)){
      props.beforeAction();
    }

    let func,params;

    if(isFunction(props.clickAction)){
      func = props.clickAction
      params = [];
    }else if(isArray(props.clickAction) && props.clickAction.length > 0){
      func = props.clickAction[0];
      params = props.clickAction.slice(1);
    }

    if(isFunction(func)){
      loadingLocal.value = true;
      processFunction(func, params).then((response) => {
        loadingLocal.value = false;
        if(isFunction(props.afterAction)){
          props.afterAction(response);
        }
      });
    }

  }
  return emit('click',e);
}

const mainClass = computed(() => {
  let arr = [];

  arr.push(`v-button--${props.color}`);
  if(props.loading || loadingLocal.value) arr.push('v-button--loading');
  if(props.isBold) arr.push('v-button--bold');
  if(props.disabled) arr.push('v-button--disabled');

  return arr;
});

let oldHeight = ref(null);
let oldWidth = ref(null);

watch(() => loadingIndicator.value, (value, oldValue) => {
  if(value && !oldValue){
    oldHeight.value = refButton.value.style.height;
    oldWidth.value = refButton.value.style.width;
    refButton.value.style.height = refButton.value.offsetHeight+'px';
    refButton.value.style.width = refButton.value.offsetWidth+'px';
    loaderSize.value = refButton.value.offsetHeight / 1.8;
    if(refButton.value.offsetHeight / 2 < 24){
      loaderThickness.value = 2;
    }else{
      loaderThickness.value = 3;
    }
  }else if(!value && oldValue){
    refButton.value.style.height = oldHeight.value;
    refButton.value.style.width = oldWidth.value;
  }
});

const loadingColorOne = computed(() => {
  switch(props.color){
    case 'green-cancel' : return 'rgba(33, 158, 38, 0.3)';
    case 'red-delete' : return '#FDECEC';
    case 'link2' : return '#1C7EE080';
    default: return '#ffffff33';
  }
});

const loadingColorTwo = computed(() => {
  switch(props.color){
    case 'green-cancel' : return '#219E26';
    case 'red-delete' : return '#C70B0B';
    case 'link2' : return '#1C7EE0';
    default: return '#fff';
  }
});

defineExpose({isLoading:loadingIndicator,});

</script>

<style lang="scss" scoped>

$bg__green: #219E26;
$bg__green-hover: #36a83c;

$bg__green2: #00b608;
$bg__green2-hover: #00C20A;

$bg__blue: #1D94FF;
$bg__blue-hover: #058aff;

$bg__blue2: #1C7EE0;
$bg__blue2-hover: #058aff;

$bg__blue2-stroke: #1C7EE0;
$bg__blue2-stroke-hover: #058aff;

$bg__red: #F2001D;
$bg__red-hover: #e0001a;

$bg__link: #1C7EE0;
$bg__link-hover: #186CBF;

.v-button{
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items:center;
  padding:12px 20px 12px 20px;
  border-radius: 4px;
  font-size:14px;
  font-weight: 400;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-property: background-color,border,opacity;
  position: relative;

  &.v-button--disabled{
    opacity:0.5;
    cursor:initial;
  }

  &.v-button--loading{
    cursor: initial;
    z-index:9999;
  }

  &.v-button--green{
    background-color: $bg__green;
    border:1px solid $bg__green;
    color: #fff;
  }
  &.v-button--green:hover:not(.v-button--disabled){
    background-color: $bg__green-hover;
    border:1px solid $bg__green-hover;
  }

  &.v-button--green2{
    background-color: $bg__green2;
    border:1px solid $bg__green2;
    color: #fff;
  }
  &.v-button--green2:hover:not(.v-button--disabled){
    background-color: $bg__green2-hover;
    border:1px solid $bg__green2-hover;
  }

  &.v-button--green-stroke{
    background-color: transparent;
    border:1px solid $bg__green;
    color: $bg__green;
  }
  &.v-button--green-stroke:hover:not(.v-button--disabled){
    background-color: rgba(33, 158, 38, 0.08);
  }

  &.v-button--green-cancel{
    background-color: transparent;
    border:1px solid transparent;
    color: #79A67D;
  }
  &.v-button--green-cancel:hover:not(.v-button--disabled){}

  &.v-button--blue{
    background-color: $bg__blue;
    color: #fff;
  }
  &.v-button--blue:hover:not(.v-button--disabled){
    background-color: $bg__blue-hover;
  }

  &.v-button--blue-white{
    background-color: transparent;
    color: #1C7EE0;
    border:1px solid #1C7EE0;
  }
  &.v-button--blue-white:hover:not(.v-button--disabled){
    color:#FFFFFF;
    background-color:#186CBF;
    border-color:#186CBF;
  }

  &.v-button--blue2{
    background-color: $bg__blue2;
    color: #fff;
  }
  &.v-button--blue2:hover:not(.v-button--disabled){
    background-color: $bg__blue2-hover;
  }

  &.v-button--blue2-stroke{
    background-color: #fff;
    color: $bg__blue2-stroke;
    border-color:$bg__blue2-stroke;
    border:1px solid $bg__blue2-stroke;
  }
  &.v-button--blue2-stroke:hover:not(.v-button--disabled), &.v-button--blue2-stroke.v-button--loading{
    background-color: #E4EEF7;
    border-color: #1C7EE080;
  }

  &.v-button--red{
    background-color: $bg__red;
    color:#fff;
    border:1px solid $bg__red;
  }
  &.v-button--red:hover:not(.v-button--disabled){
    background-color: $bg__red-hover;
    border-color: $bg__red-hover;
    color: #fff;
  }

  &.v-button--link{
    background-color: transparent;
    color:$bg__link;
    border:1px solid transparent;
  }
  &.v-button--link:hover:not(.v-button--disabled){
    background-color: #fafafa;
    border-color: #fafafa;
    color: $bg__link-hover;
  }

  &.v-button--link2{
    background-color: transparent;
    color:$color-link;
    border:1px solid transparent;
  }
  &.v-button--link2:hover:not(.v-button--disabled){
    color: $color-link-hover;
  }

  &.v-button--bold{
    font-weight:600;
  }

  &.v-button--white-stroke{
    background:transparent;
    border:1px solid #fff;
    color:#fff;
    &:hover:not(.v-button--disabled){
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  &.v-button--white-stroke-50{
    background:transparent;
    border:1px solid $white-50;
    color:#fff;
    &:hover:not(.v-button--disabled){
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  &.v-button--red-delete{
    color:$optional-red_120;
  }

}
</style>