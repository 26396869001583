<template>
    <vue-date-picker
        class="v-form-datepicker vfd"
        :enable-time-picker="false"
        :format="format"
        placeholder="dd.mm.yyyy"
        :hide-input-icon="true"
        auto-apply
        :partial-flow="true"
        :clearable="false"
        menu-class-name="vfd__calendar-menu"
    >
      <template #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
        <v-form-input class="vfd__input" :placeholder="placeholder" :value="value" inputmode="none" autocomplete="off" readonly="readonly"/>
      </template>
    </vue-date-picker>
  <!--
  -->
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VFormInput from "@app-vue/components/VForm/VFormInput.vue";

const props = defineProps({
  placeholder: {default:'dd.mm.yyyy'}
});

const format = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  day = day < 10 ? '0' + day : day;
  month = (month < 10) ? '0' + month : month;

  return `${day}.${month}.${year}`;
}
</script>

<style scoped lang="scss">
.v-form-datepicker.vfd{
  .vfd__input{
    cursor: pointer;
    height:auto;
  }
  :deep(.vfd__calendar-menu){
    background-color: #fff;
    border:none;
    box-shadow: $box-shadow-default;
    font-family: Inter, sans-serif;

    .dp__arrow_bottom{
      border:none;
      box-shadow: $box-shadow-default;
    }

    .dp__month_year_select{
      font-size: 14px;
      font-weight: 700;
      color:#384967;
    }

    .dp__calendar_header_item{
      font-size: 14px;
      font-weight: 400;
      color: #AEB7C7;
      text-transform: lowercase;
      line-height: 18px;
      height:33px;
    }

    .dp__calendar_header_separator{
      background-color: #E5ECF9;
    }

    .dp__calendar_item{
      font-size: 14px;
      color:#384967;
    }

    .dp__cell_offset{
      color: #AEB7C7;
    }

    .dp__range_end, .dp__range_start, .dp__active_date{
      background-color: #1D94FF;
      color:#fff;
    }

    .dp__overlay_cell_active{
      background-color: #1D94FF;
    }
    .dp__overlay_cell:hover{
      background-color: #e9f3fb;
    }
    .dp__button:hover{
      background-color: #e9f3fb;
    }

    .dp__date_hover_end:hover, .dp__date_hover_start:hover, .dp__date_hover:hover{
      background-color: #e9f3fb;
    }
    .dp--year-select:hover, .dp__month_year_select:hover{
      background-color: #e9f3fb;
    }

    .dp__inner_nav svg{
      fill:#1D94FF;
      stroke:#1D94FF;
    }

    .dp__inner_nav:hover{
      background-color: #e9f3fb;
    }

    &:after{
      content:'';
      position: absolute;
      bottom:0;
      left:0;
      width:100%;
      display: block;
      height:11px;
      background-color: #fff;
    }

  }
}
</style>